import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
  name: null,
  email: null,
  company: null,
  isReceiver: false,
  hasFreigabeRole: false,
  isUserMenuOpen: false,
  isServiceMenuOpen: false,
  isJobsMenuOpen: false,
  registerSuccess: false,
  appLanguage: "de",
  isMediumHintOptedOut: false,
  isJobCopyAsEmailOptedIn: null,
  certificationQualified: false,
  showReleaseBanner: true,
  istAGBAktuell: true,
  istDSEAktuell: true
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSucess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    error: false,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    ...initialState
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.authRedirectPath
  });
};

const resetAuth = (state, action) => {
  return updateObject(state, {
    error: false,
    loading: false
  });
};

const setUserData = (state, action) => {
  return updateObject(state, {
    userId: action.userId,
    name: action.name,
    email: action.email,
    company: action.company,
    companyId: action.companyId,
    isReceiver: action.isReceiver,
    isCompanyAdmin: action.isCompanyAdmin,
    hasFreigabeRole: action.hasFreigabeRole,
    appLanguage: action.appLanguage,
    isMediumHintOptedOut: action.isMediumHintOptedOut,
    isJobCopyAsEmailOptedIn:
      action.isJobCopyAsEmailOptedIn === "true" ? true : false,
    certificationQualified: action.certificationQualified,
    showReleaseBanner: action.showReleaseBanner,
    istAGBAktuell: action.istAGBAktuell,
    istDSEAktuell: action.istDSEAktuell
  });
};

const registerUser = (state, action) => {
  return updateObject(state, {
    registeringUser: false,
    registerSuccess: null,
    registerError: null,
    registeredUserFirstName: null,
    registeredUserSurname: null
  });
};

const registeringUser = (state, action) => {
  return updateObject(state, {
    registeringUser: true,
    registerSuccess: null,
    registerError: null,
    registeredUserFirstName: null,
    registeredUserSurname: null
  });
};

const setRegisterUserSuccess = (state, action) => {
  return updateObject(state, {
    registeringUser: false,
    registerSuccess: true,
    registeredUserFirstName: action.firstName,
    registeredUserSurname: action.surname
  });
};

const setRegisterUserFail = (state, action) => {
  return updateObject(state, {
    registeringUser: false,
    registerError: action.error
  });
};

const setConfirmUserRegistrationSuccess = (state, action) => {
  return updateObject(state, {
    registrationConfirmationSuccess: true
  });
};

const setConfirmUserRegistrationFail = (state, action) => {
  return updateObject(state, {
    registrationConfirmationError: action.error
  });
};

const setPasswordResetLinkRequestSuccess = (state, action) => {
  return updateObject(state, {
    passwordResetLinkRequestSuccess: true
  });
};

const setPasswordResetLinkRequestFail = (state, action) => {
  return updateObject(state, {
    passwordResetLinkRequestError: true
  });
};

const setNewPassword = (state, action) => {
  return updateObject(state, {
    setNewPasswordSuccess: false,
    setNewPasswordLoading: true,
    setNewPasswordError: null
  });
};

const setNewPasswordSuccess = (state, action) => {
  return updateObject(state, {
    setNewPasswordSuccess: true,
    setNewPasswordLoading: false,
    setNewPasswordError: null
  });
};

const setNewPasswordFail = (state, action) => {
  return updateObject(state, {
    setNewPasswordError: action.error,
    setNewPasswordSuccess: false,
    setNewPasswordLoading: false
  });
};

const fetchUserProfile = (state, action) => {
  return updateObject(state, {
    userProfileLoading: true,
    userProfileSuccess: false,
    userProfileError: false,
    saveUserLanguageSuccess: false,
    saveUserLanguageError: false
  });
};

const setUserProfile = (state, action) => {
  return updateObject(state, {
    userProfileLoading: false,
    user: action.user,
    // isCompanyAdmin: action.user.istFirmenAdmin,
    name: action.user.vorname + " " + action.user.nachname, // TODO this must be the preferred name used in the PrivateAPI user data method
    company: action.user.firma,
    companyInfo: {
      organisationName: action.user.organisationName,
      organisationStrasseHNr: action.user.organisationStrasseHNr,
      organisationPLZ: action.user.organisationPLZ,
      organisationOrt: action.user.organisationOrt,
      organisationLand: action.user.organisationLand
    },
    userInfo: {
      eMail: action.user.eMail,
      telefon: action.user.telefon,
      anrede: action.user.anrede
    }
  });
};

const fetchUserProfileFail = (state, action) => {
  return updateObject(state, {
    userProfileError: ["Benutzer Profil kann nicht geladen werden."],
    userProfileLoading: false
  });
};

const savingUserProfile = (state, action) => {
  return updateObject(state, {
    userProfileLoading: true
  });
};

const savingUserNewsletter = (state, action) => {
  let modifiedProperties = null;
  if (action.newsletterType === "general") {
    modifiedProperties = {
      userGeneralNewsletterLoading: true,
      userGeneralNewsletterSuccess: false,
      userGeneralNewsletterError: null
    };
  } else if (action.newsletterType === "technical") {
    modifiedProperties = {
      userTechnicalNewsletterLoading: true,
      userTechnicalNewsletterSuccess: false,
      userTechnicalNewsletterError: null
    };
  }

  return updateObject(state, modifiedProperties);
};

const saveUserProfileSuccess = (state, action) => {
  return updateObject(state, {
    userProfileSuccess: true,
    userProfileLoading: false
  });
};

const saveUserProfileFail = (state, action) => {
  return updateObject(state, {
    userProfileError: action.error,
    userProfileLoading: false
  });
};

const saveUserNewsletterSuccess = (state, action) => {
  let modifiedProperties = null;
  if (action.newsletterType === "general") {
    modifiedProperties = {
      userGeneralNewsletterSuccess: true,
      userGeneralNewsletterLoading: false
    };
  } else if (action.newsletterType === "technical") {
    modifiedProperties = {
      userTechnicalNewsletterSuccess: true,
      userTechnicalNewsletterLoading: false
    };
  }
  return updateObject(state, modifiedProperties);
};

const saveUserNewsletterFail = (state, action) => {
  let modifiedProperties = null;
  if (action.newsletterType === "general") {
    modifiedProperties = {
      userGeneralNewsletterError: action.error,
      userGeneralNewsletterLoading: false
    };
  } else if (action.newsletterType === "technical") {
    modifiedProperties = {
      userTechnicalNewsletterError: action.error,
      userTechnicalNewsletterLoading: false
    };
  }
  return updateObject(state, modifiedProperties);
};

const saveUserLanguageSuccess = (state, action) => {
  return updateObject(state, {
    saveUserLanguageSuccess: true,
    appLanguage: action.language
  });
};

const saveUserLanguageFail = (state, action) => {
  return updateObject(state, {
    saveUserLanguageSuccess: false,
    saveUserLanguageError: true
  });
};

const hideReleaseBannerSuccess = (state, action) => {
  return updateObject(state, {
    hideReleaseBannerSuccess: true,
    showReleaseBanner: false
  });
};

const hideReleaseBannerFail = (state, action) => {
  return updateObject(state, {
    hideReleaseBannerSuccess: false
  });
};

const fetchCompanyInfo = (state, action) => {
  return updateObject(state, {
    companyTicket: action.ticket
  });
};

const setCompanyInfo = (state, action) => {
  return updateObject(state, {
    companyInfo: action.companyInfo,
    companyChangeInviter: action.companyAdmin,
    companyChangeInvitee: action.invitee
  });
};

const fetchCompanyInfoFail = (state, action) => {
  return updateObject(state, {
    fetchCompanyInfoError: true
  });
};

const sendEmailConfirmationSuccess = (state, action) => {
  return updateObject(state, {
    sendEmailConfirmationSuccess: true
  });
};

const sendEmailConfirmationFail = (state, action) => {
  return updateObject(state, {
    sendEmailConfirmationError: false
  });
};

const setCompanyUsers = (state, action) => {
  return updateObject(state, {
    companyUsers: action.users
  });
};

const fetchCompanyUsersFail = (state, action) => {
  return updateObject(state, {
    fetchCompanyUsersError: action.error
  });
};

const sendCompanyInvitation = (state, action) => {
  return updateObject(state, {
    sendCompanyInvitationLoading: true,
    sendCompanyInvitationSuccess: null,
    sendCompanyInvitationError: null
  });
};

const sendCompanyInvitationSuccess = (state, action) => {
  return updateObject(state, {
    sendCompanyInvitationLoading: false,
    sendCompanyInvitationSuccess: action.successMessage
  });
};

const sendCompanyInvitationFail = (state, action) => {
  return updateObject(state, {
    sendCompanyInvitationLoading: false,
    sendCompanyInvitationError: action.error
  });
};

const setCompanyChangeSuccess = (state, action) => {
  return updateObject(state, {
    companyChangeSuccess: true
  });
};

const setCompanyChangeFail = (state, action) => {
  return updateObject(state, {
    companyChangeError: action.error
  });
};

const fetchAssignedMedia = (state, action) => {
  return updateObject(state, {
    fetchAssignedMediaLoading: true,
    fetchAssignedMediaError: false
  });
};

const setAssignedMedia = (state, action) => {
  return updateObject(state, {
    assignedMedia: action.media,
    fetchAssignedMediaLoading: false
  });
};

const updateAssignedMedia = (state, action) => {
  const {
    mediumId,
    duEmpfang,
    duEmpfangsBenachrichtigung,
    infoMailEmpfang,
    medienFreigabe
  } = action.permissions;
  const users = state.assignedMedia
    ? state.assignedMedia.map(item => {
        if (item.mediumId === mediumId) {
          item.duEmpfang = duEmpfang;
          item.duEmpfangsBenachrichtigung = duEmpfangsBenachrichtigung;
          item.infoMailEmpfang = infoMailEmpfang;
          item.medienFreigabe = medienFreigabe;
        }
        return item;
      })
    : [];

  return updateObject(state, {
    assignedMedia: users
  });
};

const updateUserWithAssignedMediaCount = (state, action) => {
  if (!state.assignedMedia || !state.companyUsers) {
    return updateObject(state, {});
  }

  let count = state.assignedMedia.filter(media => {
    return (
      media.duEmpfang ||
      media.duEmpfangsBenachrichtigung ||
      media.infoMailEmpfang ||
      media.medienFreigabe
    );
  }).length;

  const users = state.companyUsers
    ? state.companyUsers.map(item => {
        if (item.id === action.userId) {
          item.anzahlZugewieseneMedien = count;
        }
        return item;
      })
    : [];

  return updateObject(state, {
    companyUsers: users
  });
};

const fetchAssignedMediaFail = (state, action) => {
  return updateObject(state, {
    fetchAssignedMediaError: true,
    fetchAssignedMediaLoading: false
  });
};

const setAssignedUsersForMedia = (state, action) => {
  return updateObject(state, {
    assignedUsersWithPermissions: action.users
  });
};

const updateAssignedUserForMedia = (state, action) => {
  const {
    mediumId,
    duEmpfang,
    duEmpfangsBenachrichtigung,
    infoMailEmpfang,
    medienFreigabe
  } = action.permissions;
  const users = state.assignedUsersWithPermissions
    ? state.assignedUsersWithPermissions.map(item => {
        if (item.mediumId === mediumId && item.benutzerId === action.userId) {
          item.duEmpfang = duEmpfang;
          item.duEmpfangsBenachrichtigung = duEmpfangsBenachrichtigung;
          item.infoMailEmpfang = infoMailEmpfang;
          item.medienFreigabe = medienFreigabe;
        }
        return item;
      })
    : [];

  return updateObject(state, {
    assignedUsersWithPermissions: users
  });
};

const fetchAssignedUsersForMediaFail = (state, action) => {
  return updateObject(state, {
    fetchAssignedUsersForMediaFail: true
  });
};

const changeLanguageSetting = (state, action) => {
  return updateObject(state, {
    appLanguage: action.language
  });
};

const saveCompanyAdminFail = (state, action) => {
  return updateObject(state, {
    saveCompanyAdminError: true
  });
};

const updateCompanyAdmin = (state, action) => {
  const users = state.companyUsers
    ? state.companyUsers.map(item => {
        if (item.id === action.userId) {
          item.istFirmenAdmin = action.isCompanyAdmin;
        }
        return item;
      })
    : [];

  return updateObject(state, {
    companyUsers: users
  });
};

const saveAssignedMediaSettingFail = (state, action) => {
  return updateObject(state, {
    saveAssignedMediaSettingError: true
  });
};

const setCurrentVersion = (state, action) => {
  return updateObject(state, {
    appVersion: action.appVersion
  });
};

const setPortalStatus = (state, action) => {
  return updateObject(state, {
    portalStatus: action.portalStatus
  });
};

const setPortalMessage = (state, action) => {
  return updateObject(state, {
    portalMessage: action.portalMessage
  });
};

const setPortalMessageSpecial = (state, action) => {
  return updateObject(state, {
    portalMessageSpecial: action.portalMessageSpecial
  });
};

const setUserMediaHintOptOutSuccess = (state, action) => {
  return updateObject(state, {
    isMediumHintOptedOut: action.isMediumHintOptedOut
  });
};

const setUserSendJobCopyAsEmailOptIn = (state, action) => {
  return updateObject(state, {
    setSendJobCopyAsEmailSuccess: false,
    setSendJobCopyAsEmailError: false,
    setSendJobCopyAsEmailLoading: true
  });
};

const setUserSendJobCopyAsEmailOptInSuccess = (state, action) => {
  return updateObject(state, {
    isJobCopyAsEmailOptedIn: action.isOptedIn,
    setSendJobCopyAsEmailSuccess: true,
    setSendJobCopyAsEmailError: false,
    setSendJobCopyAsEmailLoading: false
  });
};

const setUserSendJobCopyAsEmailOptInFail = (state, action) => {
  return updateObject(state, {
    setSendJobCopyAsEmailSuccess: false,
    setSendJobCopyAsEmailError: action.error,
    setSendJobCopyAsEmailLoading: false
  });
};

const leaveSettings = (state, action) => {
  return updateObject(state, {
    setSendJobCopyAsEmailError: null,
    setSendJobCopyAsEmailSuccess: null,
    setSendJobCopyAsEmailLoading: null,
    userGeneralNewsletterError: null,
    userGeneralNewsletterLoading: null,
    userGeneralNewsletterSuccess: null,
    userTechnicalNewsletterSuccess: null,
    userTechnicalNewsletterError: null,
    userTechnicalNewsletterLoading: null
  });
};

const postponeCertificationOneMonthSuccess = (state, action) => {
  return updateObject(state, {
    certificationQualified: action.newDatenAlsJson
  });
};

const updateUserAGBValidity = (state, action) => {
  return updateObject(state, {
    istAGBAktuell: true,
    istDSEAktuell: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSucess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.RESET_AUTH:
      return resetAuth(state, action);
    case actionTypes.SET_USER_DATA:
      return setUserData(state, action);
    case actionTypes.REGISTER_USER:
      return registerUser(state, action);
    case actionTypes.REGISTERING_USER:
      return registeringUser(state, action);
    case actionTypes.REGISTER_USER_SUCCESS:
      return setRegisterUserSuccess(state, action);
    case actionTypes.REGISTER_USER_FAIL:
      return setRegisterUserFail(state, action);
    case actionTypes.USER_REGISTRATION_CONFIRMATION_SUCCESS:
      return setConfirmUserRegistrationSuccess(state, action);
    case actionTypes.USER_REGISTRATION_CONFIRMATION_FAIL:
      return setConfirmUserRegistrationFail(state, action);
    case actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST_SUCCESS:
      return setPasswordResetLinkRequestSuccess(state, action);
    case actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST_FAIL:
      return setPasswordResetLinkRequestFail(state, action);
    case actionTypes.SET_NEW_PASSWORD:
      return setNewPassword(state, action);
    case actionTypes.SET_NEW_PASSWORD_WITH_CODE:
      return setNewPassword(state, action);
    case actionTypes.SET_NEW_PASSWORD_SUCCESS:
      return setNewPasswordSuccess(state, action);
    case actionTypes.SET_NEW_PASSWORD_FAIL:
      return setNewPasswordFail(state, action);
    case actionTypes.FETCH_USER_PROFILE:
      return fetchUserProfile(state, action);
    case actionTypes.SET_USER_PROFILE:
      return setUserProfile(state, action);
    case actionTypes.FETCH_USER_PROFILE_FAIL:
      return fetchUserProfileFail(state, action);
    case actionTypes.SAVE_USER_PROFILE:
      return savingUserProfile(state, action);
    case actionTypes.SAVE_USER_PROFILE_SUCCESS:
      return saveUserProfileSuccess(state, action);
    case actionTypes.SAVE_USER_PROFILE_FAIL:
      return saveUserProfileFail(state, action);
    case actionTypes.SAVE_USER_LANGUAGE_SUCCESS:
      return saveUserLanguageSuccess(state, action);
    case actionTypes.SAVE_USER_LANGUAGE_FAIL:
      return saveUserLanguageFail(state, action);
    case actionTypes.SEND_EMAIL_CONFIRMATION_SUCCESS:
      return sendEmailConfirmationSuccess(state, action);
    case actionTypes.SEND_EMAIL_CONFIRMATION_FAIL:
      return sendEmailConfirmationFail(state, action);
    case actionTypes.SET_COMPANY_USERS:
      return setCompanyUsers(state, action);
    case actionTypes.FETCH_COMPANY_USERS_FAIL:
      return fetchCompanyUsersFail(state, action);
    case actionTypes.SEND_COMPANY_INVITATION:
      return sendCompanyInvitation(state, action);
    case actionTypes.SEND_COMPANY_INVITATION_SUCCESS:
      return sendCompanyInvitationSuccess(state, action);
    case actionTypes.SEND_COMPANY_INVITATION_FAIL:
      return sendCompanyInvitationFail(state, action);
    case actionTypes.SET_COMPANY_INFO:
      return setCompanyInfo(state, action);
    case actionTypes.FETCH_COMPANY_INFO:
      return fetchCompanyInfo(state, action);
    case actionTypes.FETCH_COMPANY_INFO_FAIL:
      return fetchCompanyInfoFail(state, action);
    case actionTypes.ACCEPT_COMPANY_CHANGE_SUCCESS:
      return setCompanyChangeSuccess(state, action);
    case actionTypes.ACCEPT_COMPANY_CHANGE_FAIL:
      return setCompanyChangeFail(state, action);
    case actionTypes.FETCH_ASSIGNED_MEDIA:
      return fetchAssignedMedia(state, action);
    case actionTypes.SET_ASSIGNED_MEDIA:
      return setAssignedMedia(state, action);
    case actionTypes.UPDATE_ASSIGNED_MEDIA:
      return updateAssignedMedia(state, action);
    case actionTypes.UPDATE_USER_WITH_ASSIGNED_MEDIA_COUNT:
      return updateUserWithAssignedMediaCount(state, action);
    case actionTypes.FETCH_ASSIGNED_MEDIA_FAIL:
      return fetchAssignedMediaFail(state, action);
    case actionTypes.SET_ASSIGNED_USERS_FOR_MEDIA:
      return setAssignedUsersForMedia(state, action);
    case actionTypes.UPDATE_ASSIGNED_USER_FOR_MEDIA:
      return updateAssignedUserForMedia(state, action);
    case actionTypes.FETCH_ASSIGNED_USER_FOR_MEDIA_FAIL:
      return fetchAssignedUsersForMediaFail(state, action);
    case actionTypes.CHANGE_LANGUAGE_SETTING:
      return changeLanguageSetting(state, action);
    case actionTypes.SAVE_COMPANY_ADMIN_FAIL:
      return saveCompanyAdminFail(state, action);
    case actionTypes.UPDATE_COMPANY_ADMIN:
      return updateCompanyAdmin(state, action);
    case actionTypes.SAVE_ASSIGNED_MEDIA_SETTING_FAIL:
      return saveAssignedMediaSettingFail(state, action);
    case actionTypes.SET_CURRENT_VERSION:
      return setCurrentVersion(state, action);
    case actionTypes.SET_PORTAL_STATUS:
      return setPortalStatus(state, action);
    case actionTypes.SET_PORTAL_MESSAGE:
      return setPortalMessage(state, action);
    case actionTypes.SET_PORTAL_MESSAGE_SPECIAL:
      return setPortalMessageSpecial(state, action);
    case actionTypes.SAVE_USER_MEDIA_HINT_OPT_OUT_SUCCESS:
      return setUserMediaHintOptOutSuccess(state, action);
    case actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN:
      return setUserSendJobCopyAsEmailOptIn(state, action);
    case actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_SUCCESS:
      return setUserSendJobCopyAsEmailOptInSuccess(state, action);
    case actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_FAIL:
      return setUserSendJobCopyAsEmailOptInFail(state, action);
    case actionTypes.LEAVE_SETTINGS:
      return leaveSettings(state, action);
    case actionTypes.SAVE_USER_NEWSLETTER_SUCCESS:
      return saveUserNewsletterSuccess(state, action);
    case actionTypes.SAVE_USER_NEWSLETTER_FAIL:
      return saveUserNewsletterFail(state, action);
    case actionTypes.SAVE_USER_NEWSLETTER:
      return savingUserNewsletter(state, action);
    case actionTypes.POSTPONE_CERTIFICATION_ONE_MONTH_SUCCESS:
      return postponeCertificationOneMonthSuccess(state, action);
    case actionTypes.UPDATE_USER_AGB_VALIDITY:
      return updateUserAGBValidity(state, action);
    default:
      return state;
  }
};

export default reducer;
