import * as actionTypes from "./actionTypes";

export const auth = (email, password) => {
  return {
    type: actionTypes.AUTH,
    email: email,
    password: password
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    authRedirectPath: path
  };
};

export const resetAuth = () => {
  return {
    type: actionTypes.RESET_AUTH
  };
};

export const changeLanguageSetting = language => {
  return {
    type: actionTypes.CHANGE_LANGUAGE_SETTING,
    language: language
  };
};

export const fetchUserData = token => {
  return {
    type: actionTypes.FETCH_USER_DATA,
    token: token
  };
};

export const setUserData = data => {
  let appLanguage = "de",
    isMediumHintOptedOut = false,
    isJobCopyAsEmailOptedIn = false,
    certificationQualified = false,
    showReleaseBanner = true,
    isAGBActual = true,
    isDataPrivacyActual = true;

  if (data.daten) {
    const languageJson = data.daten.find(item => {
      return item.schluessel === "ui.language";
    });
    appLanguage = languageJson ? languageJson.datenAlsJson : appLanguage;

    const mediaHintOptOutJson = data.daten.find(item => {
      return item.schluessel === "job.MediaHintOptOut";
    });
    isMediumHintOptedOut = mediaHintOptOutJson
      ? mediaHintOptOutJson.datenAlsJson
      : isMediumHintOptedOut;

    const jobSendCopyEmailJson = data.daten.find(item => {
      return item.schluessel === "job.SendCopyEmail";
    });

    isJobCopyAsEmailOptedIn = jobSendCopyEmailJson
      ? jobSendCopyEmailJson.datenAlsJson
      : isJobCopyAsEmailOptedIn;

    const isCertificationQualifiedJson = data.daten.find(item => {
      return item.schluessel === "general.certification.qualifiedinfo";
    });

    certificationQualified = isCertificationQualifiedJson
      ? JSON.parse(isCertificationQualifiedJson.datenAlsJson)
      : certificationQualified;

    const showReleaseBannerJson = data.daten.find(item => {
      return item.schluessel === "ui.releaseBanner";
    });

    showReleaseBanner = showReleaseBannerJson
      ? JSON.parse(showReleaseBannerJson.datenAlsJson)
      : showReleaseBanner;
  }

  if (data.istAGBAktuell !== undefined) {
    isAGBActual = data.istAGBAktuell;
  }

  if (data.istDSEAktuell !== undefined) {
    isDataPrivacyActual = data.istDSEAktuell;
  }

  return {
    type: actionTypes.SET_USER_DATA,
    userId: data.id,
    name: data.name,
    email: data.eMail,
    company: data.firma,
    companyId: data.firmenId,
    isCompanyAdmin: data.istFirmenAdmin,
    isReceiver:
      data.rollen.filter(item => item.toLowerCase() === "empfang").length > 0,
    hasFreigabeRole:
      data.rollen.filter(item => item.toLowerCase() === "freigabe").length > 0,
    appLanguage: appLanguage,
    isMediumHintOptedOut: isMediumHintOptedOut,
    isJobCopyAsEmailOptedIn: isJobCopyAsEmailOptedIn,
    certificationQualified: certificationQualified,
    showReleaseBanner: showReleaseBanner,
    istAGBAktuell: isAGBActual,
    istDSEAktuell: isDataPrivacyActual
  };
};

export const fetchUserDataFail = () => {
  return {
    type: actionTypes.FETCH_USER_DATA_FAIL
  };
};

export const registerUser = user => {
  return {
    type: actionTypes.REGISTER_USER
  };
};

export const registeringUser = user => {
  return {
    type: actionTypes.REGISTERING_USER,
    userRegistrationData: user
  };
};

export const registeringUserWithCompanyTicket = (user, ticket) => {
  return {
    type: actionTypes.REGISTERING_USER_WITH_COMPANY_TICKET,
    userRegistrationData: user,
    ticket: ticket
  };
};

export const registerUserSuccess = (firstName, surname) => {
  return {
    type: actionTypes.REGISTER_USER_SUCCESS,
    firstName: firstName,
    surname: surname
  };
};

export const registerUserFail = error => {
  return {
    type: actionTypes.REGISTER_USER_FAIL,
    error: error
  };
};

export const confirmUserRegistration = (id, code) => {
  return {
    type: actionTypes.CONFIRM_USER_REGISTRATION,
    id: id,
    code: code
  };
};

export const acceptComanyChangeInvitation = ticket => {
  return {
    type: actionTypes.ACCEPT_COMPANY_CHANGE,
    ticket: ticket
  };
};

export const setCompanyChangeSuccess = () => {
  return {
    type: actionTypes.ACCEPT_COMPANY_CHANGE_SUCCESS
  };
};

export const setCompanyChangeFail = error => {
  return {
    type: actionTypes.ACCEPT_COMPANY_CHANGE_FAIL,
    error: error
  };
};

export const userRegistrationConfirmationSuccess = () => {
  return {
    type: actionTypes.USER_REGISTRATION_CONFIRMATION_SUCCESS
  };
};

export const userRegistrationConfirmationFail = error => {
  return {
    type: actionTypes.USER_REGISTRATION_CONFIRMATION_FAIL,
    error: error
  };
};

export const sendPasswordResetLinkRequest = email => {
  return {
    type: actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST,
    email: email
  };
};

export const sendPasswordResetLinkRequestSuccess = () => {
  return {
    type: actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST_SUCCESS
  };
};

export const sendPasswordResetLinkRequestFail = error => {
  return {
    type: actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST_FAIL,
    error: error
  };
};

export const setNewPasswordWithCode = (email, newPassword, code) => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_WITH_CODE,
    email: email,
    newPassword: newPassword,
    code: code
  };
};

export const setNewPassword = (currentPassword, newPassword) => {
  return {
    type: actionTypes.SET_NEW_PASSWORD,
    currentPassword: currentPassword,
    newPassword: newPassword
  };
};

export const setNewPasswortSuccess = () => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_SUCCESS
  };
};

export const setNewPasswortFail = error => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_FAIL,
    error: error
  };
};

export const fetchUserProfile = () => {
  return {
    type: actionTypes.FETCH_USER_PROFILE
  };
};

export const fetchUserProfileFail = () => {
  return {
    type: actionTypes.FETCH_USER_PROFILE_FAIL
  };
};

export const setUserProfile = user => {
  return {
    type: actionTypes.SET_USER_PROFILE,
    user: user
  };
};

export const saveUserProfile = user => {
  return {
    type: actionTypes.SAVE_USER_PROFILE,
    user: user
  };
};

export const fetchUserNewsletters = () => {
  return {
    type: actionTypes.FETCH_USER_NEWSLETTERS
  };
};

export const saveUserNewsletters = (user, newsletterType) => {
  return {
    type: actionTypes.SAVE_USER_NEWSLETTER,
    user: user,
    newsletterType: newsletterType
  };
};

export const saveUserProfileSuccess = () => {
  return {
    type: actionTypes.SAVE_USER_PROFILE_SUCCESS
  };
};

export const saveUserNewsletterSuccess = newsletterType => {
  return {
    type: actionTypes.SAVE_USER_NEWSLETTER_SUCCESS,
    newsletterType: newsletterType
  };
};

export const saveUserProfileFail = error => {
  return {
    type: actionTypes.SAVE_USER_PROFILE_FAIL,
    error: error
  };
};

export const saveUserNewsletterFail = (error, newsletterType) => {
  return {
    type: actionTypes.SAVE_USER_NEWSLETTER_FAIL,
    error: error,
    newsletterType: newsletterType
  };
};

export const saveUserLanguage = language => {
  return {
    type: actionTypes.SAVE_USER_LANGUAGE,
    language: language
  };
};

export const saveUserLanguageSuccess = language => {
  return {
    type: actionTypes.SAVE_USER_LANGUAGE_SUCCESS,
    language: language
  };
};

export const saveUserLanguageFail = () => {
  return {
    type: actionTypes.SAVE_USER_LANGUAGE_FAIL
  };
};

export const saveUserMediaHintOptOut = isOptedOut => {
  return {
    type: actionTypes.SAVE_USER_MEDIA_HINT_OPT_OUT,
    isOptedOut: isOptedOut
  };
};

export const saveUserMediaHintOptOutSuccess = isOptedOut => {
  return {
    type: actionTypes.SAVE_USER_MEDIA_HINT_OPT_OUT_SUCCESS,
    isMediumHintOptedOut: isOptedOut
  };
};

export const saveUserMediaHintOptOutFail = () => {
  return {
    type: actionTypes.SAVE_USER_MEDIA_HINT_OPT_OUT_FAIL
  };
};

export const saveUserSendJobCopyAsEmailOptIn = isOptedIn => {
  return {
    type: actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN,
    isOptedIn: isOptedIn
  };
};

export const saveUserSendJobCopyAsEmailOptInSuccess = isOptedIn => {
  return {
    type: actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_SUCCESS,
    isOptedIn: isOptedIn
  };
};

export const saveUserSendJobCopyAsEmailOptInFail = error => {
  return {
    type: actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_FAIL,
    error: error
  };
};

export const saveUserProfileAndPreferences = (user, language) => {
  return {
    type: actionTypes.SAVE_USER_PROFILE_AND_PREFERENCES,
    user: user,
    language: language
  };
};

export const sendEmailConfirmation = email => {
  return {
    type: actionTypes.SEND_EMAIL_CONFIRMATION,
    email: email
  };
};

export const sendEmailConfirmationSuccess = () => {
  return {
    type: actionTypes.SEND_EMAIL_CONFIRMATION_SUCCESS
  };
};

export const sendEmailConfirmationFail = () => {
  return {
    type: actionTypes.SEND_EMAIL_CONFIRMATION_FAIL
  };
};

export const fetchCompanyInfo = ticket => {
  return {
    type: actionTypes.FETCH_COMPANY_INFO,
    ticket: ticket
  };
};

export const fetchCompanyInfoFail = () => {
  return {
    type: actionTypes.FETCH_COMPANY_INFO_FAIL
  };
};

export const setCompanyInfo = (companyInfo, companyAdmin, invitee) => {
  return {
    type: actionTypes.SET_COMPANY_INFO,
    companyInfo: companyInfo,
    companyAdmin: companyAdmin,
    invitee: invitee
  };
};

export const fetchCompanyUsers = () => {
  return {
    type: actionTypes.FETCH_COMPANY_USERS
  };
};

export const fetchCompanyUsersFail = error => {
  return {
    type: actionTypes.FETCH_COMPANY_USERS_FAIL,
    companyUsersError: error
  };
};

export const setCompanyUsers = users => {
  return {
    type: actionTypes.SET_COMPANY_USERS,
    users: users
  };
};

export const sendCompanyInvitation = (userEmail, salutoryAddress) => {
  return {
    type: actionTypes.SEND_COMPANY_INVITATION,
    userEmail: userEmail,
    salutoryAddress: salutoryAddress
  };
};

export const sendCompanyInvitationSuccess = successMessage => {
  return {
    type: actionTypes.SEND_COMPANY_INVITATION_SUCCESS,
    successMessage: successMessage
  };
};

export const sendCompanyInvitationFail = error => {
  return {
    type: actionTypes.SEND_COMPANY_INVITATION_FAIL,
    error: error
  };
};

export const fetchAssignedMedia = userId => {
  return {
    type: actionTypes.FETCH_ASSIGNED_MEDIA,
    userId: userId
  };
};

export const setAssignedMedia = media => {
  return {
    type: actionTypes.SET_ASSIGNED_MEDIA,
    media: media
  };
};

export const updateAssignedMedia = (userId, permissions) => {
  return {
    type: actionTypes.UPDATE_ASSIGNED_MEDIA,
    userId: userId,
    permissions: permissions
  };
};

export const updateUserWithAssignedMediaCount = userId => {
  return {
    type: actionTypes.UPDATE_USER_WITH_ASSIGNED_MEDIA_COUNT,
    userId: userId
  };
};

export const fetchAssignedMediaFail = () => {
  return {
    type: actionTypes.FETCH_ASSIGNED_MEDIA_FAIL
  };
};

export const fetchAssignedUsersForMedia = mediaId => {
  return {
    type: actionTypes.FETCH_ASSIGNED_USER_FOR_MEDIA,
    mediaId: mediaId
  };
};

export const setAssignedUsersForMedia = users => {
  return {
    type: actionTypes.SET_ASSIGNED_USERS_FOR_MEDIA,
    users: users
  };
};

export const updateAssignedUserForMedia = (userId, permissions) => {
  return {
    type: actionTypes.UPDATE_ASSIGNED_USER_FOR_MEDIA,
    userId: userId,
    permissions: permissions
  };
};

export const fetchAssignedUsersForMediaFail = () => {
  return {
    type: actionTypes.FETCH_ASSIGNED_USER_FOR_MEDIA_FAIL
  };
};

export const saveCompanyAdmin = (userId, isCompanyAdmin) => {
  return {
    type: actionTypes.SAVE_COMPANY_ADMIN,
    userId: userId,
    isCompanyAdmin: isCompanyAdmin
  };
};

export const updateCompanyAdmin = (userId, isCompanyAdmin) => {
  return {
    type: actionTypes.UPDATE_COMPANY_ADMIN,
    userId: userId,
    isCompanyAdmin: isCompanyAdmin
  };
};

export const saveCompanyAdminFail = () => {
  return {
    type: actionTypes.SAVE_COMPANY_ADMIN_FAIL
  };
};

export const saveAssignedMediaSettingPerMedia = (
  userId,
  mediaId,
  loggedInUserId,
  permissions
) => {
  return {
    type: actionTypes.SAVE_ASSIGNED_MEDIA_SETTING_PER_MEDIA,
    userId: userId,
    mediaId: mediaId,
    loggedInUserId: loggedInUserId,
    permissions: permissions
  };
};

export const saveAssignedMediaSettingPerUser = (
  userId,
  mediaId,
  loggedInUserId,
  permissions
) => {
  return {
    type: actionTypes.SAVE_ASSIGNED_MEDIA_SETTING_PER_USER,
    userId: userId,
    mediaId: mediaId,
    loggedInUserId: loggedInUserId,
    permissions: permissions
  };
};

export const saveAssignedMediaSettingFail = () => {
  return {
    type: actionTypes.SAVE_ASSIGNED_MEDIA_SETTING_FAIL
  };
};

export const downloadFile = (filePath, fileType, fileName) => {
  return {
    type: actionTypes.DOWNLOAD_FILE,
    filePath: filePath,
    fileType: fileType,
    fileName: fileName
  };
};

export const acceptNewAGB = userId => {
  return {
    type: actionTypes.ACCEPT_NEW_AGB,
    userId: userId
  };
};

export const fetchCurrentVersionFromDB = () => {
  return {
    type: actionTypes.FETCH_CURRENT_VERSION
  };
};

export const setCurrentVersionFromDB = versionFromDB => {
  return {
    type: actionTypes.SET_CURRENT_VERSION,
    appVersion: versionFromDB
  };
};

export const fetchPortalStatus = () => {
  return {
    type: actionTypes.FETCH_PORTAL_STATUS
  };
};

export const setPortalStatus = (anzeigen, firmen, medien, verlage) => {
  return {
    type: actionTypes.SET_PORTAL_STATUS,
    portalStatus: {
      anzeigenStatus: anzeigen,
      firmenStatus: firmen,
      medienStatus: medien,
      verlageStatus: verlage
    }
  };
};

export const fetchPortalMessage = () => {
  return {
    type: actionTypes.FETCH_PORTAL_MESSAGE
  };
};

export const setPortalMessage = message => {
  return {
    type: actionTypes.SET_PORTAL_MESSAGE,
    portalMessage: message
  };
};

export const fetchPortalMessageSpecial = () => {
  return {
    type: actionTypes.FETCH_PORTAL_MESSAGE_SPECIAL
  };
};

export const setPortalMessageSpecial = message => {
  return {
    type: actionTypes.SET_PORTAL_MESSAGE_SPECIAL,
    portalMessageSpecial: message
  };
};

export const leaveSettings = () => {
  return {
    type: actionTypes.LEAVE_SETTINGS
  };
};

export const postponeCertificationOneMonth = newDatenAlsJson => {
  return {
    type: actionTypes.POSTPONE_CERTIFICATION_ONE_MONTH,
    newDatenAlsJson: newDatenAlsJson
  };
};

export const postponeCertificationOneMonthSuccess = newDatenAlsJson => {
  return {
    type: actionTypes.POSTPONE_CERTIFICATION_ONE_MONTH_SUCCESS,
    newDatenAlsJson: newDatenAlsJson
  };
};

export const postponeCertificationOneMonthFail = error => {
  return {
    type: actionTypes.POSTPONE_CERTIFICATION_ONE_MONTH_FAIL,
    error: error
  };
};

export const sendCertificationRequest = billingAddress => {
  return {
    type: actionTypes.SEND_CERTIFICATION_REQUEST,
    billingAddress: billingAddress
  };
};

export const hideReleaseBanner = () => {
  return {
    type: actionTypes.HIDE_RELEASE_BANNER
  };
};

export const hideReleaseBannerSuccess = () => {
  return {
    type: actionTypes.HIDE_RELEASE_BANNER_SUCCESS
  };
};

export const hideReleaseBannerFail = () => {
  return {
    type: actionTypes.HIDE_RELEASE_BANNER_FAIL
  };
};

export const updateUserAGBValidity = () => {
  return {
    type: actionTypes.UPDATE_USER_AGB_VALIDITY
  };
};
