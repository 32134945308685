export const deLocale = [
  {
    id: "Home.FirstParagraph.Header",
    text: "DUON-Portal."
  },
  {
    id: "Home.FirstParagraph.SubHeader",
    text: "Check-in & Preflight für Ihre Printanzeigen."
  },
  {
    id: "Home.FirstParagraph.FirstLine",
    text:
      "Das DUON-Portal ist das digitale Druckunterlagen-Tool für Anzeigen in deutschen Printmedien."
  },
  {
    id: "Home.FirstParagraph.SecondLine",
    text:
      "Hier finden Sie alle technischen Informationen sowie die Möglichkeit, Anzeigen hochzuladen,"
  },
  {
    id: "Home.FirstParagraph.ThirdLine",
    text:
      "überprüfen zu lassen und an die Verlage zu versenden. Schnell, unkompliziert und sicher."
  },
  {
    id: "Home.Buttons.Register",
    text: "Jetzt kostenlos registrieren"
  },
  {
    id: "Home.Login.Hint",
    text: "Wenn Sie bereits registriert sind geht es hier weiter zum ..."
  },
  {
    id: "Home.SecondParagraph.Header",
    text: "Effizienter Ad-Workflow für Verlage"
  },
  {
    id: "Home.SecondParagraph.FirstLine",
    text:
      "Das DUON-Portal ist die digitale Druckunterlagenlösung für Printverlage. Das System gibt Verlagen die Möglichkeit, ihre technischen Vorgaben für die "
  },
  {
    id: "Home.SecondParagraph.SecondLine",
    text:
      "Anlieferung von digitalen Druckunterlagen an die Kunden, Media-, Kreativ- und Produktionsagenturen zu kommunizieren. Die erstellten Anzeigenmotive können direkt "
  },
  {
    id: "Home.SecondParagraph.ThirdLine",
    text:
      "im Portal hochgeladen werden. Jede Anzeige wird dabei technische auf Herz und Nieren untersucht, so dass eine bestmögliche Qualität sichergestellt ist."
  },
  {
    id: "Home.SecondParagraph.FourthLine",
    text: ""
  },
  {
    id: "Home.Verlag.Column1.Header.Line1",
    text: "Vollständige"
  },
  {
    id: "Home.Verlag.Column1.Header.Line2",
    text: "technische Informationen"
  },
  {
    id: "Home.Verlag.Column1.Text",
    text:
      "Werbekunde, Mediaagentur, Leadagentur, Kreativagentur, Produktionsagentur ... die Werbeproduktionsstrecke ist lang. Schnell gehen Informationen verloren. Verlorene, falsche oder veralterte Informationen sind Kostentreiber in der Print-Produktion. Durch die Teilnahme am DUON-Portal stellen Verlage sicher, dass alle Marktteilnehmer jederzeit Zugang zu aktuellen, verlässlichen und vollständigen Informationen haben."
  },
  {
    id: "Home.Verlag.Column2.Header.Line1",
    text: "Geprüfte"
  },
  {
    id: "Home.Verlag.Column2.Header.Line2",
    text: "Druckunterlagenqualität"
  },
  {
    id: "Home.Verlag.Column2.Text",
    text:
      "Printwerbung gehört zu den Werbegenres mit vergleichsweise aufwendiger Produktion. Am Ende der Produktionsstrecke muss die Kampagne in engen zeitlichen Grenzen ausgerollt werden. Dabei passieren schnell Fehler. Mittels des DUON-Preflights wird jede Anzeige vor Übermittlung automatisiert in rund 300 Kriterien geprüft und technische Fehler so minimiert. Kunden, Agenturen und Verlage profitieren davon gleichermaßen."
  },
  {
    id: "Home.Verlag.Column3.Header.Line1",
    text: "Optimale"
  },
  {
    id: "Home.Verlag.Column3.Header.Line2",
    text: "Kundenbetreuung"
  },
  {
    id: "Home.Verlag.Column3.Text",
    text:
      "Mit dem DUON-Support steht Ihren Anzeigenkunden ein umfangreicher und professioneller Support zur Verfügung. Die Experten des Support-Teams beraten in allen Fragen der Druckunterlagenherstellung und geben bezogen auf die jeweilige Anzeige ganz konkrete Anleitungen und Tipps für die Bedienung der Grafikprogramme. "
  },
  {
    id: "Home.Verlag.Column4.Header.Line1",
    text: "Flexible"
  },
  {
    id: "Home.Verlag.Column4.Header.Line2",
    text: "Empfangsmöglichkeiten"
  },
  {
    id: "Home.Verlag.Column4.Text",
    text:
      "Wie Sie als Verlag die eingehenden Daten empfangen, entscheiden Sie selbst. Neben dem speziellen Empfangsbereich im Web-Portal, gibt es auch die Möglichkeit, die API in die eigene Anwendung zu integrieren. Auf Wunsch können die Daten auch per FTP ausgeliefert werden. "
  },
  {
    id: "Home.Verlag.Column5.Header.Line1",
    text: "E-Mail-Benachrichtigung bei"
  },
  {
    id: "Home.Verlag.Column5.Header.Line2",
    text: "Druckunterlageneingang"
  },
  {
    id: "Home.Verlag.Column5.Text",
    text:
      "Optional können Ihre MitarbeiterInnen sich bei Eingang von neuen Druckunterlagen per E-Mail informieren lassen. Dabei kann jeweils genau konfiguriert werden, wer für welche Medien zuständig ist und bei Bedarf eine Nachricht erhalten möchte. Auf diesem Wege sind die zuständigen Personen immer schnell informiert."
  },
  {
    id: "Home.Verlag.Column6.Header.Line1",
    text: "Zusammenarbeit mit"
  },
  {
    id: "Home.Verlag.Column6.Header.Line2",
    text: "Vorstufendienstleistern"
  },
  {
    id: "Home.Verlag.Column6.Text",
    text:
      "Die Zugriffsrechte sowie Einstellungen für Empfang und Benachrichtigungen lassen sich für Ihre Mitarbeiter individuell steuern. Ebenso können z.B. Reprounternehmen  magazinbezogen eingebunden werden. Der Workflow und die Kommunikation mit den Dienstleistern kann über das Portal erfolgen."
  },
  {
    id: "Home.Verlag.Column7.Header.Line1",
    text: "Automatisieren mit der"
  },
  {
    id: "Home.Verlag.Column7.Header.Line2",
    text: "DUON-API"
  },
  {
    id: "Home.Verlag.Column7.Text",
    text:
      "Die umfangreiche und gut dokumentierte API (Application Programming Interface) ermöglicht es, das DUON-Portal als Service in die eigene Softwarelösungen einzubinden. Sie können nicht nur Druckunterlagen empfangen, sondern auch Versandaufträge erstellen, Druckunterlagen hochladen, prüfen lassen und senden."
  },
  {
    id: "Home.Verlag.Column8.Header.Line1",
    text: "Skalierbare"
  },
  {
    id: "Home.Verlag.Column8.Header.Line2",
    text: "Cloud-App"
  },
  {
    id: "Home.Verlag.Column8.Text",
    text:
      "Das DUON-Portal läuft vollständig in der Cloud. Die hervorragende Skalierbarkeit sorgt zu jeder Zeit für eine sehr hohe Verfügbarkeit. Sichergestellt wird dies durch die automatische Skalierung des Systems: Sollte die Auslastung zeitweise steigen, werden automatisch Ressourcen hinzugefügt."
  },
  {
    id: "Home.Verlag.Column9.Header.Line1",
    text: "Hohes"
  },
  {
    id: "Home.Verlag.Column9.Header.Line2",
    text: "Sicherheitsniveau"
  },
  {
    id: "Home.Verlag.Column9.Text",
    text:
      "Im Kampagnenvorlauf gelten strenge Sicherheitsanforderungen. Kanäle wie E-Mail oder Download-Link oder der Einsatz von Sharing-Lösungen sind nicht geeignet. Über das DUON-Portal werden die Daten auf sicherem Wege übertragen. Die Übertragung erfolgt verschlüsselt. Nur die erforderlichen Personen erhalten Zugriff. Dies kann jeder Verlag selbst steuern."
  },
  {
    id: "Home.Pricing.Header",
    text: "Preise"
  },
  {
    id: "Home.Pricing.Column1.Header",
    text: "6,- EUR / Job*"
  },
  {
    id: "Home.Pricing.Column1.Text",
    text:
      "Je eingegangene Druckunterlage („Job“) wird die o.g. pauschale Gebühr erhoben. Enthalten sind alle Leistungen des DUON-Portals. Es entstehen keine weiteren Kosten. Eine Kündigung ist jederzeit zum Ende des Quartals möglich."
  },
  {
    id: "Home.Pricing.Column1.SubText",
    text:
      "* Dieses Angebot richtet sich an Verlage mit einem geringen Anzeigenvolumen. Alternativ zu diesem Angebot gibt es ein gesondertes Abrechnungsmodell für Verlage mit einem hohen Anzeigenvolumen. Fragen Sie gerne nach!"
  },
  {
    id: "Home.Verlag.Participation.Header",
    text: "Teilnehmen"
  },
  {
    id: "Home.Verlag.Participation.Text",
    text:
      "Mit Teilnahme am DUON-Portal bieten Sie ihren Kunden ein professionelles Interface für die Druckunterlagenabwicklung und stellen für Ihre Kunden optimale Qualität sicher. Gleichzeitig senken Sie ihre Kosten in der Disposition sowie Druckvorstufe, Repro/Litho. Die Teilnahme ist sehr einfach und die Umstellung auf den DUON-Workflow dauert i.d.R. nur wenige Tage. Sprechen Sie uns einfach an."
  },
  {
    id: "Home.Verlag.Participation.Button",
    text: "Teilnahme anfragen"
  },
  {
    id: "Home.Brochure.Header",
    text: "Mehr erfahren"
  },
  {
    id: "Home.Brochure.Text",
    text:
      "Mehr Informationen zum DUON-Portal finden Sie in unserer PDF-Broschüre, die sie nachfolgend herunterladen können"
  },
  {
    id: "Home.Brochure.Button",
    text: "Broschüre downloaden"
  },
  {
    id: "Home.Search.Label",
    text: "Medienname eingeben ..."
  },
  {
    id: "Site.Search.Label",
    text: "Suchbegriff..."
  },
  {
    id: "Footer.Impressum",
    text: "Impressum"
  },
  {
    id: "Footer.Jobs",
    text: "Jobs"
  },
  {
    id: "Footer.AGB",
    text: "AGB"
  },
  {
    id: "Footer.Datenschutz",
    text: "Datenschutzerklärung"
  },
  {
    id: "Footer.Newsletter",
    text: "Newsletter"
  },
  {
    id: "Footer.SupportTime",
    text: "Mo-Fr 9.00 - 17.00 Uhr"
  },
  {
    id: "Navigation.Media",
    text: "Medien"
  },
  {
    id: "Navigation.Jobs",
    text: "Jobs"
  },
  {
    id: "Navigation.Joblist",
    text: "Jobliste"
  },
  {
    id: "Navigation.CreateJob",
    text: "Job erstellen"
  },
  {
    id: "Navigation.Blog",
    text: "Blog"
  },
  {
    id: "Navigation.API",
    text: "API"
  },
  {
    id: "Navigation.Login",
    text: "Login"
  },
  {
    id: "Navigation.Verlage",
    text: "Verlage"
  },
  {
    id: "Navigation.Certified",
    text: "Certified"
  },
  {
    id: "Navigation.Produkt",
    text: "Produkt"
  },
  {
    id: "Navigation.Empfang",
    text: "Empfang"
  },
  {
    id: "Navigation.Freigabe",
    text: "Freigabe"
  },
  {
    id: "Navigation.Help",
    text: "Hilfe"
  },
  {
    id: "Navigation.Profile",
    text: "Mein Profil"
  },
  {
    id: "Navigation.Settings",
    text: "Settings"
  },
  {
    id: "Navigation.MyMedia",
    text: "Meine Medien"
  },
  {
    id: "Navigation.ManageCompanyUsers",
    text: "Benutzer verwalten"
  },
  {
    id: "Navigation.ChangePassword",
    text: "Passwort ändern"
  },
  {
    id: "Navigation.Logout",
    text: "Logout"
  },
  {
    id: "Headers.Media",
    text: "Medien"
  },
  {
    id: "MediaGrid.Name",
    text: "Name"
  },
  {
    id: "MediaGrid.SelectIssue",
    text: "Ausgabe/Termin wählen"
  },
  {
    id: "MediaGrid.Publisher",
    text: "Verlag"
  },
  {
    id: "MediaGrid.ExistingIssues",
    text_0: "Keine aktuellen Termine",
    text_1: "1 aktueller Termin",
    text: "%{count} aktuelle Termine"
  },
  {
    id: "Issues.Status",
    text: "Status"
  },
  {
    id: "Issues.Heft",
    text: "Heft"
  },
  {
    id: "Issues.Date",
    text: "Date"
  },
  {
    id: "Issues.ET",
    text: "ET"
  },
  {
    id: "Issues.DuSchluss",
    text: "DU-Schluss"
  },
  {
    id: "Issues.MagazineHint",
    text: "Falls Sie ein Heft vermissen, kontaktieren Sie bitte den Support:"
  },
  {
    id: "Issues.NewspaperHint",
    text: "Falls Sie ein Ausgabe vermissen, kontaktieren Sie bitte den Support:"
  },
  {
    id: "Issues.MoreIssues",
    text: "Weitere Termine..."
  },
  {
    id: "TechnicalInfo.Header",
    text: "Technische Vorgaben"
  },
  {
    id: "FilePreview.Header",
    text: "PDF Vorschau"
  },
  {
    id: "FilePreview.Download",
    text: "PDF Herunterladen"
  },
  {
    id: "JobFormular.ChooseMedia",
    text: "Medium wählen"
  },
  {
    id: "JobFormular.DeleteMediaMessage",
    text: "Medium mit alle Anzeigen löschen?"
  },
  {
    id: "JobFormular.DeleteMediaConfirmation",
    text:
      "Möchten Sie alle Anzeigen für das Medium mit Id %{id} und Titel '%{name}' wirklich löschen?"
  },
  {
    id: "JobFormular.Header",
    text: "Versandauftrag"
  },
  {
    id: "JobFormular.ChooseAnotherMedia.Title",
    text: "Weiteres Medium Wählen"
  },
  {
    id: "JobFormular.ChooseAnotherMedia.Description",
    text:
      "Sofern Sie wünschen, können Sie nun innerhalb eines Versanddurchgangs mehrere Druckunterlagen für verschiedene Medien gleichzeitig versenden. Fügen Sie ein weiteres Medium hinzu und wählen sie danach das gebuchte Format bzw. die gebuchten Formate."
  },
  {
    id: "JobFormular.Client",
    text: "Werbender Kunde"
  },
  {
    id: "JobFormular.Client.Tooltip",
    text:
      "Wer wirbt mit dieser/diesen Anzeige/n? Bitte geben Sie zur besseren Zuordnung der Druckunterlagen zu der Buchung den Namen des Werbekunden an, für den die Druckunterlage hochgeladen wird, also z.B. „Mustermann AG“, wenn die Anzeige Produkte der Mustermann AG bewirbt."
  },
  {
    id: "JobFormular.Client.Required",
    text: "Bitte füllen Sie das Feld 'Werbender Kunde' aus."
  },
  {
    id: "JobFormular.Product",
    text: "Beworbenes Produkt"
  },
  {
    id: "JobFormular.Product.Tooltip",
    text:
      "Welches Produkt oder welche Leistung wird beworben? Bitte geben Sie den konkreten Produktnamen (z.B. „Hair & Care“) mindestens die Kategorie („Haarpflegeprodukte“) an. Die Information ist hilfreich, wenn ein Werbekunde mehrere Produkte bewirbt. Wenn es keine Differenzierung gibt, wiederholen Sie einfach den Namen des werbenden Kunden oder geben Sie „Allgemein“ oder „keine Angaben“ ein."
  },
  {
    id: "JobFormular.Product.Required",
    text: "Bitte füllen Sie das Feld 'Beworbenes Produkt' aus."
  },
  {
    id: "JobFormular.Notes",
    text: "Bemerkungen"
  },
  {
    id: "JobFormular.Notes.Tooltip",
    text:
      "Haben Sie eine übergeordnete wichtige Mitteilung an den/die Verlag/e, die in allen Anzeigen-Jobs enthalten sein soll?"
  },
  {
    id: "JobFormular.Notes.MaximumReached",
    text: "Bitte verwenden Sie maximal 1000 Zeichen."
  },
  {
    id: "JobFormular.DeleteJobMessage",
    text: "Möchten Sie diese Anzeige für '%{name}' wirklich löschen?"
  },
  {
    id: "JobFormular.ChooseAnotherFormat.Title",
    text: "Weiteres Format/Heft wählen"
  },
  {
    id: "JobFormular.ChooseAnotherFormat.Description",
    text:
      "Sofern Sie wünschen, können Sie ein weiteres Format für dieses Medium wählen und hinzufügen."
  },
  {
    id: "JobFormular.IssueStatus",
    text: "Druckunterlagenschluss"
  },
  {
    id: "JobFormular.IssueStatus.Tooltip",
    text:
      "Der angezeigte Termin ist die offizielle Eingangsfrist zur Einlieferung der Druckunterlagen für das gewählte Heft. Bei Fragen zum Druckunterlagenschluss kontaktieren Sie bitte den jeweiligen Verlag."
  },
  {
    id: "JobFormular.ET",
    text: "Heft-Nummer - ET"
  },
  {
    id: "JobFormular.Newspaper.ET",
    text: "ET"
  },
  {
    id: "JobFormular.ET.Tooltip",
    text:
      "Bitte wählen Sie eines der Hefte aus der Liste. Die Auswahl bedingt die genauen technischen Spezifikationen. Falls Sie ein Heft oder einen ET nicht finden, kontaktieren Sie den Support."
  },
  {
    id: "JobFormular.Format.Tooltip",
    text:
      "Bitte wählen Sie das gebuchte Anzeigenformat aus der Liste aus. Bitte achten Sie insbesondere auf Belegung (z.B. Umschlag), die Orientierung (z.B. quer) sowie die Art (Satzspiegel oder Anschnitt)."
  },
  {
    id: "JobFormular.Motive.Tooltip",
    text:
      "Was ist in der Anzeige zu sehen? Bitte vergeben Sie zur leichteren Zuordnung der Anzeige einen möglichst sprechenden Namen."
  },
  {
    id: "JobFormular.Motive.Required",
    text: "Bitte füllen Sie das Feld 'Motiv' aus."
  },
  {
    id: "JobFormular.PartialBooking.Tooltip",
    text: "Die Auswahl ist nur erforderlich, sofern Teilbelegung gebucht wurde."
  },
  {
    id: "JobFormular.JobNotes.Tooltip",
    text:
      "Haben Sie eine spezielle wichtige Mitteilung an den Verlag, die sie nur dieser Anzeige beifügen möchten?"
  },
  {
    id: "JobFormular.Steps.Start",
    text: "Anlegen"
  },
  {
    id: "JobFormular.Steps.Proof",
    text: "Prüfen"
  },
  {
    id: "JobFormular.Steps.Send",
    text: "Senden"
  },
  {
    id: "JobFormular.Steps.Finish",
    text: "Fertig"
  },
  {
    id: "FileUpload.Okay",
    text: "OK"
  },
  {
    id: "FileUpload.Warning",
    text: "Warnungen anzeigen"
  },
  {
    id: "FileUpload.PreflightError",
    text: "Fehler anzeigen"
  },
  {
    id: "FileUpload.CancelUpload",
    text: "Hochladen abbrechen"
  },
  {
    id: "FileUpload.UploadNewFile",
    text: "Neue Datei hochladen"
  },
  {
    id: "FileUpload.FirstHint",
    text: "Bewegen Sie eine PDF-Datei auf diese Fläche"
  },
  {
    id: "FileUpload.SecondHint",
    text: "Mit dem Upload wird der Auftrag gespeichert"
  },
  {
    id: "FileUpload.Required",
    text: "Bitte zuerst alle Pflichtfelder füllen"
  },
  {
    id: "FileUpload.Error",
    text:
      "Die Aktion konnte nicht erfolgreich abgeschlossen werden. Möglicherweise ist die hochgeladene Datei beschädigt. Kontaktieren Sie den Support oder versuchen Sie eine andere Datei hochzuladen."
  },
  {
    id: "FileUpload.Timeout",
    text:
      "Die Aktion konnte nicht innerhalb der erwarteten Zeit abgschlossen werden. Bitte versuchen Sie es später noch einmal."
  },
  {
    id: "FileUpload.FileNotAccepted",
    text:
      "Die Dateigröße überschreitet 100 MB oder ist kein PDF-Format. Bitte versuchen Sie, eine andere Datei hochzuladen."
  },
  {
    id: "General.TechnicalInfo",
    text: "Technische Vorgaben"
  },
  {
    id: "General.Page",
    text: "Seite"
  },
  {
    id: "General.Left",
    text: "Links"
  },
  {
    id: "General.Right",
    text: "Rechts"
  },
  {
    id: "General.Upload",
    text: "Upload"
  },
  {
    id: "General.Format",
    text: "Format"
  },
  {
    id: "General.Motive",
    text: "Motiv"
  },
  {
    id: "General.PartialBooking",
    text: "Teilbelegung"
  },
  {
    id: "General.Notes",
    text: "Bemerkungen"
  },
  {
    id: "General.Delete",
    text: "Löschen"
  },
  {
    id: "General.Cancel",
    text: "Abbrechen"
  },
  {
    id: "General.Save",
    text: "Speichern"
  },
  {
    id: "General.Send",
    text: "Versenden"
  },
  {
    id: "General.MaxInput",
    text: "Bitte verwenden Sie maximal 255 Zeichen."
  },
  {
    id: "General.Media",
    text: "Medium"
  },
  {
    id: "General.Ad",
    text: "Anzeige"
  },
  {
    id: "General.Yes",
    text: "Ja"
  },
  {
    id: "General.No",
    text: "Nein"
  },
  {
    id: "General.Action",
    text: "Aktion"
  },
  {
    id: "General.View",
    text: "Anzeigen"
  },
  {
    id: "General.Edit",
    text: "Bearbeiten"
  },
  {
    id: "General.Download",
    text: "Herunterladen"
  },
  {
    id: "General.All",
    text: "Alle"
  },
  {
    id: "General.Mr",
    text: "Herr"
  },
  {
    id: "General.Mrs",
    text: "Frau"
  },
  {
    id: "General.PleaseNote",
    text: "Hinweis"
  },
  {
    id: "General.Grid.NoResults",
    text: "Keine Ergebnisse vorhanden"
  },
  {
    id: "Jobs.Saving.Success",
    text: "Job/s erfolgreich gespeichert!"
  },
  {
    id: "CreateJob.Sending.Success",
    text: "Fertig! Die Daten sind beim Verlag eingegangen. Sie können nun..."
  },
  {
    id: "Joblist.Sending.Success",
    text: "Fertig! Die Daten sind beim Verlag eingegangen."
  },
  {
    id: "Jobs.Sending.Waiting",
    text:
      "Jobs warten auf Versand. Bitte schließen oder aktualisieren Sie die Seite nicht."
  },
  {
    id: "Jobs.Sending.IncompleteContainer",
    text:
      "Es kann derzeit nicht versendet werden, weil es ein oder mehrere ungültige Jobs gibt."
  },
  {
    id: "Support.Days",
    text: "Montag bis Freitag"
  },
  {
    id: "Support.Time",
    text: "9.00 bis 17.00 Uhr"
  },
  {
    id: "Support.Contacts",
    text_0: "keine Kontakte vorhanden",
    text_1: "1 Kontakt anzeigen",
    text: "%{count} Kontakte anzeigen"
  },
  {
    id: "Support.Contacts.Header",
    text_0: "Ansprechpartner",
    text_1: "Ansprechpartner",
    text: "Ansprechpartner"
  },
  {
    id: "JobList.MoreFilters",
    text: "Weitere Filter"
  },
  {
    id: "JobList.WordTags.Header",
    text: "Schlagworte"
  },
  {
    id: "JobList.WordTags.NoWordTag",
    text: "Kein Schlagwort"
  },
  {
    id: "JobList.WordTags.Delete.Header",
    text: "Schlagwort löschen?"
  },
  {
    id: "JobList.WordTags.Delete.Confirmation",
    text: "Sind Sie sicher, dass Sie das Schlagwort löschen möchten?"
  },
  {
    id: "JobList.WordTags.New",
    text: "Neue Schlagwort"
  },
  {
    id: "JobList.WordTags.Create",
    text: "Schlagwort erstellen"
  },
  {
    id: "JobList.ColorTags.Select",
    text: "Wählen Sie eine Farbe aus"
  },
  {
    id: "JobList.ColorTags.Create",
    text: "Kategorie erstellen"
  },
  {
    id: "JobList.ColorTags.Header",
    text: "Kategorie"
  },
  {
    id: "JobList.ColorTags.Delete.Header",
    text: "Kategorie löschen?"
  },
  {
    id: "JobList.ColorTags.Delete.Confirmation",
    text: "Sind Sie sicher, dass Sie die Kategorie löschen möchten?"
  },
  {
    id: "JobList.ExtraInfo.Popup.Header",
    text: "Zusätzliche Infos"
  },
  {
    id: "JobList.ExtraInfo.Popup.Booking",
    text: "Buchung"
  },
  {
    id: "JobList.ExtraInfo.Popup.Workflow",
    text: "Workflow"
  },
  {
    id: "JobList.ExtraInfo.Popup.Booking.Error.MaxLength",
    text: "Die Bookung darf max. 500 Zeichen lang sein"
  },
  {
    id: "JobList.ExtraInfo.Popup.Workflow.Error.MaxLength",
    text: "Das Workflow darf max. 500 Zeichen lang sein"
  },
  {
    id: "JobList.ExtraInfo.Popup.Button",
    text: "Speichern"
  },
  {
    id: "JobList.Preflight.Header",
    text: "Prüfergebnis"
  },
  {
    id: "JobList.Preflight.All",
    text: "Alle Ergebnisse"
  },
  {
    id: "JobList.Preflight.NotProofed",
    text: "Nicht Geprüft"
  },
  {
    id: "JobList.Preflight.Error",
    text: "Fehler"
  },
  {
    id: "JobList.Preflight.Warning",
    text: "Warnung"
  },
  {
    id: "JobList.Preflight.Okay",
    text: "OK"
  },
  {
    id: "JobList.DateFilter.SenderHeader",
    text: "Erstellungsdatum"
  },
  {
    id: "JobList.DateFilter.ReceiverHeader",
    text: "Empfangsdatum"
  },
  {
    id: "JobList.SenderView.Header",
    text: "Jobliste"
  },
  {
    id: "JobList.ReceiverView.Header",
    text: "Empfang"
  },
  {
    id: "JobList.Actions.Select",
    text: "Aktion auswählen"
  },
  {
    id: "JobList.JobDeleted.Confirmation",
    text: "Der Auftrag mit der ID: %{id} würde erfolgreich gelöscht.s"
  },
  {
    id: "JobList.DeleteJob.Header",
    text: "Job löschen?"
  },
  {
    id: "JobList.DeleteJob.Confirmation",
    text:
      "Möchten Sie diesen Job mit der Id %{id} für '%{name}' wirklich löschen?"
  },
  {
    id: "JobList.ShowJobs",
    text: "Alle Jobs anzeigen"
  },
  {
    id: "JobList.Columns.Select",
    text: "Spalten auswählen"
  },
  {
    id: "JobList.Columns.status",
    text: "Auftragsstatus"
  },
  {
    id: "JobList.Columns.ergebnisId",
    text: "Prüfergebnis"
  },
  {
    id: "JobList.Columns.thumbnail",
    text: "Vorschau"
  },
  {
    id: "JobList.Columns.id",
    text: "DUON-ID"
  },
  {
    id: "JobList.Columns.containerId",
    text: "Versand-ID"
  },
  {
    id: "JobList.Columns.kunde",
    text: "Kunde"
  },
  {
    id: "JobList.Columns.produkt",
    text: "Produkt"
  },
  {
    id: "JobList.Columns.medium",
    text: "Medium"
  },
  {
    id: "JobList.Columns.mediumId",
    text: "Medium ID"
  },
  {
    id: "JobList.Columns.heft",
    text: "Heft"
  },
  {
    id: "JobList.Columns.format",
    text: "Format"
  },
  {
    id: "JobList.Columns.Tag",
    text: "Schlagworte"
  },
  {
    id: "JobList.Columns.colorTag",
    text: "Kategorie"
  },
  {
    id: "JobList.Columns.extraInfo",
    text: "Eigene Notizen"
  },
  {
    id: "JobList.Columns.notes",
    text: "Bemerkung"
  },
  {
    id: "JobList.Columns.versendetZeitpunkt",
    text: "Sendungsdatum"
  },
  {
    id: "JobList.Columns.motiv",
    text: "Motiv"
  },
  {
    id: "JobList.Columns.duSchluss",
    text: "DU-Schluss"
  },
  {
    id: "JobList.Columns.actions",
    text: "Aktion auswählen"
  },
  {
    id: "JobList.Columns.quickView",
    text: "Kurzübersicht"
  },
  {
    id: "JobList.Columns.abholstatus",
    text: "Abholstatus"
  },
  {
    id: "JobList.Columns.jobexchangestatus",
    text: "Austausch"
  },
  {
    id: "JobList.Status.All",
    text: "Alle Jobs"
  },
  {
    id: "JobList.Status.NotSent",
    text: "Entwurf"
  },
  {
    id: "JobList.Status.Sent",
    text: "Gesendet"
  },
  {
    id: "JobList.Status.Draft",
    text: "Entwurf"
  },
  {
    id: "JobList.Status.Error",
    text: "Fehler"
  },
  {
    id: "JobList.Refresh",
    text: "Jobliste aktualisieren"
  },
  {
    id: "MediaDetail.Booking.Header",
    text: "Buchung"
  },
  {
    id: "MediaDetail.CreateJob",
    text: "Job erstellen"
  },
  {
    id: "MediaDetail.HasSplitFormat",
    text: "Dieses Format besteht aus Teilformaten"
  },
  {
    id: "MediaDetail.AllTechnicalInfo",
    text: ""
  },
  {
    id: "MediaDetail.NoIssues",
    text: "Aktuell gibt es keine für die Belieferung freigegebenen Hefte."
  },
  {
    id: "TechnicalInfo.Anschnitt",
    text: "Anschnitt"
  },
  {
    id: "TechnicalInfo.Anschnitt.Tooltip",
    text:
      "Die Beschnittzugabe wird jeweils zur Trimbox hinzugerechnet und ergibt in der Summe die Bleedbox. Der Anschnitt dient der Vermeidung von weißen 'Blitzern' beim Beschneiden des fertigen Magazins."
  },
  {
    id: "TechnicalInfo.Anzeigenformat",
    text: "Anzeigenformat"
  },
  {
    id: "TechnicalInfo.Top",
    text: "oben"
  },
  {
    id: "TechnicalInfo.Bottom",
    text: "unten"
  },
  {
    id: "TechnicalInfo.Left",
    text: "links"
  },
  {
    id: "TechnicalInfo.Right",
    text: "rechts"
  },
  {
    id: "TechnicalInfo.IccProfile",
    text: "ICC-Farbprofil"
  },
  {
    id: "TechnicalInfo.IccProfile.Tooltip",
    text:
      "Bitte verwenden Sie das angegebene Farbprofil für die Farbanpassung. Die Verwendung eines Farbprofils sorgt für eine qualitativ optimale Farbwiedergabe."
  },
  {
    id: "TechnicalInfo.PartialFormat",
    text: "Teilformate"
  },
  {
    id: "TechnicalInfo.Profile",
    text: "Profil"
  },
  {
    id: "TechnicalInfo.Grenzwerte",
    text: "Grenzwerte"
  },
  {
    id: "TechnicalInfo.Others",
    text: "Sonstiges"
  },
  {
    id: "TechnicalInfo.Others.Description",
    text:
      "Weitere technische Vorraussetzungen sind in den DUON-Konventionen erläutert, welche Sie hier herunterladen können"
  },
  {
    id: "TechnicalInfo.Endformat",
    text: "Endformat"
  },
  {
    id: "TechnicalInfo.Endformat.Width",
    text: "Breite"
  },
  {
    id: "TechnicalInfo.Endformat.Height",
    text: "Höhe"
  },
  {
    id: "TechnicalInfo.Endformat.Tooltip",
    text:
      "Das Endformat (Trimbox) bezeichnet das beschnittene Format der fertig produzierten Anzeige."
  },
  {
    id: "TechnicalInfo.Sicherheitsabstand",
    text: "Sicherheitsabstand"
  },
  {
    id: "TechnicalInfo.Sicherheitsabstand.Tooltip",
    text:
      "Bitte platzieren sie bildwichtige Elemente und Textelemente nicht außerhalb des Sicherheitsabstandes, damit bei etwas zu starkem Beschnitt keine wichtigen Elemente verloren gehen."
  },
  {
    id: "TechnicalInfo.Flächendeckung.Max",
    text: "max. Flächendeckung"
  },
  {
    id: "TechnicalInfo.Profile.Hint",
    text:
      "Der Output Intent muss gesetzt sein und dem o.g. Proﬁl entsprechen. DeviceCMYK wird entsprechend dem Output Intent interpretiert. Eingebettete ICC-Proﬁle in Objekten sind zulässig, wenn das ICC-Proﬁl dem Output Intent entspricht."
  },
  {
    id: "TechnicalInfo.PDFInfo.Standards",
    text: "Zulässige Standards"
  },
  {
    id: "TechnicalInfo.PDFInfo.Standards.Tooltip",
    text:
      "Es werden alle Daten akzeptiert, die einem der angegebenen Standards entsprechen."
  },
  {
    id: "TechnicalInfo.PDFInfo.Preference",
    text: "Vom Verlag bevorzugt"
  },
  {
    id: "TechnicalInfo.PDFInfo.Preference.Tooltip",
    text:
      "Der Verlag empfiehlt die Verwendung des angegebenen Standards für ein optimales Ergebnis."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken",
    text: "Strichstärken"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Pos",
    text: "min. Strichstärke positiv"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Neg",
    text: "min. Strichstärke negativ"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Pos.1Cpos.Tooltip",
    text:
      "Effektive Linien/Strichstärke bei positiven einfarbigen Linien/Konturen, z.B. schwarze Linie auf weißem Untergrund oder Cyan-Linie auf weißem Untergrund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Neg.1Cneg.Tooltip",
    text:
      "Effektive Linien/Strichstärke bei negativen einfarbigen Linien/Konturen, z.B. weiße Linie auf schwarzem Untergrund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Pos.4Cpos.Tooltip",
    text:
      "Effektive Linien/Strichstärke bei positiven mehrfarbigen Linien/Konturen, z.B. grüne Linie auf weißem Untergrund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Neg.4Cneg.Tooltip",
    text:
      "Effektive Linien/Strichstärke bei negativen mehrfarbigen Linien/Konturen, z.B. grüne Linie auf braunem Untergrund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Auflösung",
    text: "Auflösung"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen",
    text: "Graustufen- und Farbbilder"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder",
    text: "Bitmap/Strichbilder"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen.Tooltip",
    text:
      "Empfohlene effektive Mindest-Bildauflösung bei schwarzweißen und farbigen Bildern."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder.Tooltip",
    text:
      "Empfohlene effektive Mindest-Bildauflösung bei graphischen Darstellungen."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen",
    text: "Schriftgrössen"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Pos",
    text: "min. Schriftgröße positiv"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Neg",
    text: "min. Schriftgröße negativ"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Pos.1Cpos.Tooltip",
    text:
      "In der Regel gerade noch sicher reproduzierbare Schriftrgröße bei positiver einfarbiger Darstellung, z.B. schwarze Schrift auf weißem Grund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Neg.1Cneg.Tooltip",
    text:
      "In der Regel gerade noch sicher reproduzierbare Schriftrgröße bei negativer einfarbiger Darstellung, z.B. weiße Schrift auf schwarzem Grund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Pos.4Cpos.Tooltip",
    text:
      "In der Regel gerade noch sicher reproduzierbare Schriftrgröße bei positiver mehrfarbiger Darstellung, z.B. dunkelgrünze Schrift auf weißem Grund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Neg.4Cneg.Tooltip",
    text:
      "In der Regel gerade noch sicher reproduzierbare Schriftrgröße bei negativer einfarbiger Darstellung, z.B. rote Schrift auf dunkelbraunem Grund."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Hint",
    text:
      "Eine Unterschreitung der angegebenen Strichstärken und Schriftgrößen kann unter Umständen zu Qualitätseinbußen im Druck führen. Bei einer Unterschreitung dieser Werte kann der Verlag eine optimale Darstellung nicht zusichern. Bitte prüfen Sie im Einzelfall, ob Sie eine Freigabe der diesbezüglichen Warnungen vertreten können. Sie verzichten in diesem Punkt auf eine Reklamationsrecht bezüglich dieser Eigenschaften."
  },
  {
    id: "SendConfirmEmail.Header.MinimaliseText",
    text: "Lassen Sie sich einen neuen Link zuschicken!"
  },
  {
    id: "SendConfirmEmail.Header",
    text: "Konto Aktivierung"
  },
  {
    id: "SendConfirmEmail.Hint",
    text:
      "Sie haben sich schon registriert, aber Ihr Konto noch nicht aktiviert? Wir werden Ihnen einen neuen Link per E-Mail zuschicken, um Ihr Konto zu aktivieren."
  },
  {
    id: "SendConfirmEmail.Button",
    text: "Ja, bitte zuschicken"
  },
  {
    id: "SendConfirmEmail.Success",
    text:
      " Wir haben Ihnen eine E-Mail geschickt. Bitte nutzen Sie den Link in der Nachricht, um Ihr Konto zu aktivieren."
  },
  {
    id: "SendConfirmEmail.Error",
    text: " Oops, ein Fehler ist aufgetreten. Versuche es später erneut. "
  },
  {
    id: "UserProfile.Header",
    text: "Mein Profil"
  },
  {
    id: "UserProfile.UserDetails",
    text: "Benutzerdaten"
  },
  {
    id: "UserProfile.Saved",
    text: "Gespeichert"
  },
  {
    id: "UserProfile.CompanyAdmin.Hint",
    text: "* Nur ein Firmenadmin kann Firmeninfos ändern"
  },
  {
    id: "UserProfile.ChangePassword",
    text: "Passwort ändern"
  },
  {
    id: "UserProfile.ConfirmEmailLink.Header",
    text: "Bitte Ihr Konto aktivieren"
  },
  {
    id: "UserProfile.ConfirmEmailLink.Hint",
    text:
      "Es wurde eine E-Mail zur Verifizierung an Ihre E-Mail %{name} versandt. Falls Sie die E-Mail nicht erhalten haben, können Sie hier klicken, um eine neue Verifierungsemail zu versenden."
  },
  {
    id: "UserProfile.ConfirmEmailLink.Button",
    text: "Neue E-Mail zuschicken"
  },
  {
    id: "UserProfile.Email.Header",
    text: "E-Mail-Adresse"
  },
  {
    id: "UserProfile.Email.Error",
    text: "Das ist keine gültige E-mailadresse"
  },
  {
    id: "UserProfile.Email.Required",
    text: "E-Mail-Adresse ist erforderlich"
  },
  {
    id: "UserProfile.Email.Invalid",
    text: "Das ist keine gültige E-mailadresse"
  },
  {
    id: "UserProfile.Email.MaxLength",
    text: "Die E-mailadresse darf max. 255 Zeichen lang sein"
  },
  {
    id: "UserProfile.Titel.Header",
    text: "Anrede"
  },
  {
    id: "UserProfile.Titel.Required",
    text: "Anrede ist erforderlich"
  },
  {
    id: "UserProfile.Titel.Select",
    text: "Bitte auswählen ..."
  },
  {
    id: "UserProfile.Firstname",
    text: "Vorname"
  },
  {
    id: "UserProfile.Firstname.Required",
    text: "Vorname ist erforderlich"
  },
  {
    id: "UserProfile.Firstname.Invalid",
    text: "Der Vorname darf max. 100 Zeichen lang sein"
  },
  {
    id: "UserProfile.Lastname",
    text: "Nachname"
  },
  {
    id: "UserProfile.Lastname.Required",
    text: "Nachname ist erforderlich"
  },
  {
    id: "UserProfile.Lastname.Invalid",
    text: "Der Nachname darf max. 100 Zeichen lang sein"
  },
  {
    id: "UserProfile.Telephone",
    text: "Telefon"
  },
  {
    id: "UserProfile.Telephone.Invalid",
    text: "Das Telefon darf max. 100 Zeichen lang sein"
  },
  {
    id: "UserProfile.Language",
    text: "Sprache"
  },
  {
    id: "UserProfile.Language.English",
    text: "Englisch"
  },
  {
    id: "UserProfile.Language.German",
    text: "Deutsch"
  },
  {
    id: "UserProfile.Language.Required",
    text: "Sprache ist erforderlich"
  },
  {
    id: "UserProfile.Company",
    text: "Firma"
  },
  {
    id: "UserProfile.Company.Street",
    text: "Straße und Hausnummer"
  },
  {
    id: "UserProfile.Company.Street.Invalid",
    text: "Die Straße darf max. 255 Zeichen lang sein"
  },
  {
    id: "UserProfile.Company.City",
    text: "Ort"
  },
  {
    id: "UserProfile.Company.City.Invalid",
    text: "Der Ort darf max. 255 Zeichen lang sein"
  },
  {
    id: "UserProfile.Company.Required",
    text: "Name ist erforderlich"
  },
  {
    id: "UserProfile.Company.Street.Required",
    text: "Straße und Hausnummer ist erforderlich"
  },
  {
    id: "UserProfile.Company.City.Required",
    text: "Ort ist erforderlich"
  },
  {
    id: "UserProfile.Company.PostalCode",
    text: "PLZ"
  },
  {
    id: "UserProfile.Company.PostalCode.Required",
    text: "Postleitzahl ist erforderlich"
  },
  {
    id: "UserProfile.Company.PostalCode.Invalid",
    text: "Die PLZ darf max. 10 Zeichen lang sein"
  },
  {
    id: "UserProfile.Company.Land.Required",
    text: "Land ist erforderlich"
  },
  {
    id: "UserProfile.Company.Newsletter.Product",
    text:
      "Ich willige ein, per E-Mail über Neuigkeiten rund um das DUON-Portal informiert zu werden."
  },
  {
    id: "UserProfile.Company.Newsletter.Technical",
    text:
      "Ich willige ein, per E-Mail über Änderungen an technischen Vorgaben informiert zu werden."
  },
  {
    id: "UserProfile.JobCopyEmail",
    text: "Ich willige ein, per E-Mail über Versandauftrag..."
  },
  {
    id: "General.Company.URL",
    text: "URL-Webadresse"
  },
  {
    id: "UserProfile.Password.Invalid",
    text: "Passwort ist ungültig"
  },
  {
    id: "MyMedia.Header",
    text: "Meine Medien"
  },
  {
    id: "MyMedia.HintTextChangeAutoSaved",
    text: "Ihre Angaben werden automatisch gespeichert"
  },
  {
    id: "MyMedia.Grid.Column.Publisher",
    text: "Verlag"
  },
  {
    id: "MyMedia.Grid.Column.ReceivePrintMaterial",
    text: "DU-Empfang"
  },
  {
    id: "MyMedia.Grid.Column.RecievalNotication",
    text: "DU-Empfangsbenachrichtung"
  },
  {
    id: "MyMedia.Grid.Column.MediaAdministration",
    text: "Medien Freigabe"
  },
  {
    id: "MyMedia.Grid.Column.RecieveTechnicalChangesNotification",
    text: "Mail bei Technikänderung"
  },
  {
    id: "MyMedia.Error.LoadingData",
    text:
      "Oops, ein Fehler ist aufgetreten. Die Medien konnten nicht geladen werden."
  },
  {
    id: "MyUsers.Grid.Column.FirstName",
    text: "Vorname"
  },
  {
    id: "MyUsers.Grid.Column.Surname",
    text: "Name"
  },
  {
    id: "MyUsers.Grid.Column.Email",
    text: "E-Mail"
  },
  {
    id: "MyUsers.Grid.Column.RecievalNotication",
    text: "DU-Empfangsbenachrichtung"
  },
  {
    id: "MyUsers.Grid.Column.MediaAdministration",
    text: "Medien Freigabe"
  },
  {
    id: "MyUsers.Grid.Column.RecieveTechnicalChangesNotification",
    text: "Mail bei Technikänderung"
  },
  {
    id: "ResetPassword.Success",
    text: "Das neue Passwort wurde erfolgreich speichert"
  },
  {
    id: "ResetPassword.Reset.Success",
    text: "Passwort erfolgreich zurückgesetzt"
  },
  {
    id: "ResetPassword.Password.Actual",
    text: "Aktuelles Passwort"
  },
  {
    id: "ResetPassword.Password.Invalid",
    text: "Passwort ist ungültig"
  },
  {
    id: "ResetPassword.Change",
    text: "Passwort ändern"
  },
  {
    id: "ResetPassword.Reset",
    text: "Passwort zurücksetzen"
  },
  {
    id: "ResetPassword.NewPassword",
    text: "Neues Passwort"
  },
  {
    id: "ResetPassword.NewPassword.Confirm",
    text: "Neues Passwort bestätigen"
  },
  {
    id: "ResetPassword.ToMyProfile",
    text: "Zu meinem Profil"
  },
  {
    id: "General.Login",
    text: "Anmelden"
  },
  {
    id: "ForgotPassword.Success",
    text:
      "Wir haben Ihnen eine Email geschickt. Bitte nutzen Sie den Link in der Nachricht, um Ihre Passwort zurückzusetzen."
  },
  {
    id: "ForgotPassword.Error",
    text: "Oops, ein Fehler ist aufgetreten. Versuche es später erneut."
  },
  {
    id: "ForgotPassword",
    text: "Passwort vergessen"
  },
  {
    id: "ForgotPassword.YourEmail",
    text: "Ihre E-Mail"
  },
  {
    id: "ForgotPassword.Button.Send",
    text: "Passwortreset-Link zuschicken"
  },
  {
    id: "CompanyUser.Error.Invitation",
    text:
      "Oops, ein Fehler ist aufgetreten. Die Einladung konnte nicht verschickt werden."
  },
  {
    id: "CompanyUser.Error.Invitation.InvalidEmail",
    text: "Das ist keine gültige E-Mail-Adresse"
  },
  {
    id: "CompanyUser.Error.Invitation.InvalidEmail.MaxLength",
    text: "Die E-Mail-Adresse darf max. 255 Zeichen lang sein"
  },
  {
    id: "CompanyUser.Error.Invitation.EmailAlreadyFromCompany",
    text: "Der Benutzer ist bereits in ihrer Firma"
  },
  {
    id: "CompanyUser.Error.Invitation.UsersOwnEmail",
    text: "Die E-Mail-Adresse gehört Ihnen"
  },
  {
    id: "CompanyUser.Error.Invitation.Message.Required",
    text: "Nachricht ist erforderlich"
  },
  {
    id: "CompanyUser.Error.Invitation.Message.MaxLength",
    text: "Die Nachricht darf max. 2000 Zeichen lang sein"
  },
  {
    id: "CompanyUser.Error.User",
    text:
      "Oops, ein Fehler ist aufgetreten. Die Benutzer konnten nicht geladen werden."
  },
  {
    id: "CompanyUser.Change",
    text: "Benutzer verwalten"
  },
  {
    id: "CompanyUser",
    text: "Firmenbenutzer"
  },
  {
    id: "CompanyUser.Header",
    text: "Benutzer verwalten"
  },
  {
    id: "CompanyUser.HintTextChangeAutoSaved",
    text: "Ihre Angaben werden automatisch gespeichert"
  },
  {
    id: "CompanyUser.Invite.Header",
    text: "Neue Person einladen"
  },
  {
    id: "CompanyUser.Invite.Email",
    text: "E-Mail Adresse"
  },
  {
    id: "CompanyUser.Invite.AddCustomMessage",
    text:
      "Falls die Person noch nicht registriert ist, können Sie hier eine eigene Nachricht angeben, die in der Einladungsmail benutzt wird"
  },
  {
    id: "CompanyUser.Invite.CustomMessagePlaceholder",
    text: "Hallo Herr Max Mustermann,"
  },
  {
    id: "CompanyUser.Invite.Text.Invalid",
    text: "Die Nachricht darf max. 2000 Zeichen lang sein"
  },
  {
    id: "CompanyUser.Invite.EmailPlaceholder",
    text: "max@mustermann.de"
  },
  {
    id: "CompanyUser.Invite.Send",
    text: "Einladung senden"
  },
  {
    id: "CompanyUser.Invite.Sending",
    text: "wird gesendt"
  },
  {
    id: "CompanyUser.Success.Invitation",
    text: "Eine Einladung wurde an '%{name}' erfolgreich geschickt"
  },
  {
    id: "CompanyUser.Grid.Column.Email",
    text: "E-Mail"
  },
  {
    id: "CompanyUser.Grid.Column.FirstName",
    text: "Vorname"
  },
  {
    id: "CompanyUser.Grid.Column.Surname",
    text: "Name"
  },
  {
    id: "CompanyUser.Grid.Column.Telephone",
    text: "Telefon"
  },
  {
    id: "CompanyUser.Grid.Column.CompanyAdmin",
    text: "Firmenadmin"
  },
  {
    id: "CompanyUser.Grid.Column.MediaPermissions",
    text: "Medienberechtigungen"
  },
  {
    id: "CompanyUser.Grid.Column.Button.Yes",
    text: "Medien anzeigen"
  },
  {
    id: "CompanyUser.Grid.Column.Button.No",
    text: "Medien hinzufügen"
  },
  {
    id: "CompanyChange.Error",
    text: "Oops, diese Einladung ist entweder abgelaufen oder ungültig."
  },
  {
    id: "CompanyChange.ToLogin",
    text: "Zur Anmeldung"
  },
  {
    id: "CompanyChange.Success",
    text: "Erfolgreich"
  },
  {
    id: "CompanyChange.MemberOf",
    text: "Sie sind nun Mitglied von"
  },
  {
    id: "CompanyChange.Invite",
    text: "Einladung"
  },
  {
    id: "CompanyChange.Invitation.Message1",
    text: "hat Sie eingeladen, dem DUON-Portal-Account von %{name} beizutreten."
  },
  {
    id: "CompanyChange.Invitation.Message2",
    text:
      "Bei dem automatischen Check Ihrer E-Mail-Adresse wurde durch das System festgestellt, dass Sie mit dieser E-Mail-Adresse bereits dem Account %{name} beigetreten sind."
  },
  {
    id: "CompanyChange.Invitation.Message3",
    text:
      "Wenn Sie auf EINLADUNG ANNEHMEN drücken wird Ihr persönlicher Account %{id} dem Account %{name} zugeordnet. "
  },
  {
    id: "CompanyChange.Invitation.Message4",
    text:
      "Sie verlieren den Zugriff auf Daten und Jobs %{name}. Wenn Sie der einzige User %{name} sind, bedenken Sie, dass keine andere Person mehr auf die Daten %{name} zugreifen kann."
  },
  {
    id: "CompanyChange.AcceptInvitation",
    text: "Einladung annehmen"
  },
  {
    id: "PasswordFields.Password",
    text: "Passwort"
  },
  {
    id: "PasswordFields.Invalid",
    text: "Passwort ist ungültig"
  },
  {
    id: "PasswordFields.Validation",
    text: "Das Passwort muss mindestens 6 und höchstens 100 Zeichen lang sein."
  },
  {
    id: "PasswordFields.Validation.ContainsLowercaseChars",
    text: "Das Passwort muss mindestens einen kleinen Buchstaben (a-z) haben."
  },
  {
    id: "PasswordFields.Validation.ContainsUppercaseChars",
    text: "Das Passwort muss mindestens einen großen Buchstaben (A-Z) haben."
  },
  {
    id: "PasswordFields.Validation.ContainsNumber",
    text: "Das Passwort muss mindestens eine Zahl (1-9) haben."
  },
  {
    id: "PasswordFields.Confirm",
    text: "Passwort bestätigen"
  },
  {
    id: "PasswordFields.Compare",
    text:
      "Das neue Passwort und die Passwort-Wiederholung stimmen nicht überein."
  },
  {
    id: "PasswordPopup.Header",
    text: "Passwort-Tipps"
  },
  {
    id: "PasswordPopup.Header.Minimum",
    text: "Mindestens"
  },
  {
    id: "PasswordPopup.Header.Hint",
    text: "Tipps"
  },
  {
    id: "PasswordPopup.Minimum.1",
    text: "6 Zeichen"
  },
  {
    id: "PasswordPopup.Minimum.2",
    text: "ein Großbuchstabe (A-Z)"
  },
  {
    id: "PasswordPopup.Minimum.3",
    text: "ein Kleinbuchstabe (a-z)"
  },
  {
    id: "PasswordPopup.Minimum.4",
    text: "eine Zahl (0-9)"
  },
  {
    id: "PasswordPopup.Minimum.5",
    text: "Sonderzeichen sind erlaubt (Leerzeichen, ?!%+…)"
  },
  {
    id: "PasswordPopup.Hint.1",
    text: "Je länger, desto besser"
  },
  {
    id: "PasswordPopup.Hint.2",
    text: "Wörterbucheinträge vermeiden"
  },
  {
    id: "PasswordPopup.Hint.3",
    text: "Sonderzeichen verwenden"
  },
  {
    id: "Privacy.General.Description",
    text: "Allgemeine Geschäftsbedingungen Duon-Portal"
  },
  {
    id: "Privacy.General.Description.Read",
    text: "lesen"
  },
  {
    id: "Privacy.General.Description.Hint",
    text: "Um dieses Portal nutzen zu können, müssen Sie den AGBs zustimmen."
  },
  {
    id: "Data.General.Description",
    text: "Allgemeine Datenschutzerklärung Duon-Portal "
  },
  {
    id: "Data.General.Description.Read",
    text: "lesen"
  },
  {
    id: "Data.General.Description.Hint",
    text:
      "Um dieses Portal nutzen zu können, müssen Sie die Datenschutzerklärung bestätigen."
  },
  {
    id: "RegisterSuccess.AlmostDone",
    text: "Fast geschafft"
  },
  {
    id: "RegisterSuccess.AlmostDone.Step1",
    text: "1. Schritt zur Registrierung abgeschlossen"
  },
  {
    id: "RegisterSuccess.AlmostDone.Step2",
    text: "2. Bitte Ihre E-Mail-Adresse bestätigen"
  },
  {
    id: "RegisterSuccess.Hint",
    text:
      "Sie bekommen in den folgenden Minuten eine E-Mail zur Verifizierung an die angegebene Adresse zugesendet. Bitte überprüfen Sie Ihr E-Mail-Postfach, gegebenenfalls auch den Spam-Ordner und folgen Sie den Anweisungen in dieser E-Mail."
  },
  {
    id: "Registration.LoginDetails",
    text: "Zugangsdaten"
  },
  {
    id: "RegistrationConfirmation.Success.Header",
    text: "E-Mail-Adresse bestätigt"
  },
  {
    id: "RegistrationConfirmation.Success",
    text: "Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse."
  },
  {
    id: "RegistrationConfirmation.Success.Button",
    text: "Login"
  },
  {
    id: "RegistrationConfirmation.Error",
    text:
      "Der Aktivierungslink ist nicht mehr gültig. Dies kann verschiedene Gründe haben. Haben Sie sich vor mehr als 72 Stunden registriert, jedoch erst jetzt auf den Aktivieren-Link gedrückt? Haben Sie bereits zuvor den Link verwendet, indem Sie auf den Button 'Aktivieren' gedrückt haben?"
  },
  {
    id: "Auth.Login",
    text: "Login"
  },
  {
    id: "Auth.Login.Error.Email",
    text: "Dies ist keine gültige E-Mail-Adresse"
  },
  {
    id: "Auth.Login.Error.Email.Empty",
    text: "Bitte geben Sie eine E-Mail-Adresse ein"
  },
  {
    id: "Auth.Login.Error.Passwort",
    text: "Dies ist kein gültiges Passwort"
  },
  {
    id: "Auth.Login.Error.Passwort.Empty",
    text: "Bitte geben Sie ein Passwort ein"
  },
  {
    id: "Auth.Register",
    text: "Registrieren"
  },
  {
    id: "Auth.NotWorking",
    text: "Funktioniert nicht?"
  },
  {
    id: "Auth.ForgotPassword",
    text: "Passwort vergessen?"
  },
  {
    id: "Auth.Inactive",
    text: "Registriert, aber nicht aktiviert?"
  },
  {
    id: "Auth.UnsuccessfulLogin",
    text: "Ungültiger Anmeldeversuch"
  },
  {
    id: "Registration.Header",
    text: "Konto anlegen"
  },
  {
    id: "Registration.Finish",
    text: "Registrierung abschliessen"
  },
  {
    id: "Registration.AlreadyRegistered",
    text: "Schon registriert? Zur Anmeldung"
  },
  {
    id: "Registration.CorrectErrors",
    text: "Bitte korrigieren Sie Ihre Daten"
  },
  {
    id: "Registration.PrivacyStatement",
    text: "Datenschutzhinweises entsprechend Art. 13 DSGVO"
  },
  {
    id: "Registration.TermsAndConditions",
    text: "Allgemeinen Geschäfts- und Nutzungsbedingungen"
  },
  {
    id: "Registration.AgreeToPrivacyAndTerms1",
    text: "Durch 'Registrierung abschliessen' bestätigen Sie, dass Sie die "
  },
  {
    id: "Registration.AgreeToPrivacyAndTerms2",
    text: "akzeptieren und dass Sie mittels des "
  },
  {
    id: "Registration.AgreeToPrivacyAndTerms3",
    text: " über die Verarbeitung personenbezogener Daten informiert wurden."
  },
  {
    id: "Registration.CancelNewslettersConsent",
    text:
      "Sie können diese Einwilligungen durch Änderungen der Einstellungen in Ihrem Profil widerrufen. "
  },
  {
    id: "Registration.Invitation.Info1",
    text: " hat Sie eingeladen, dem DUON-Portal-Account von "
  },
  {
    id: "Registration.Invitation.Info2",
    text: " beizutreten. Registrieren Sie sich, um die Einladung anzunehmen."
  },
  {
    id: "Registration.Invitation.Success",
    text: "Registrierung Erfolgreich"
  },
  {
    id: "Registration.Invitation.Success.Welcome",
    text: "Wilkommen "
  },
  {
    id: "Registration.Invitation.Success.NowLogin",
    text: "Sie können sich jetzt einloggen."
  },
  {
    id: "Registration.Invitation.Success.LoginButton",
    text: "Zur Anmeldung"
  },
  {
    id: "Registration.Invitation.Invalid",
    text: "Der Link ist leider nicht gültig oder abgelaufen."
  },
  {
    id: "Registration.Invitation.Invalid.Message1",
    text: "Kontaktieren Sie die Person, die Ihnen die Einladung geschickt hat "
  },
  {
    id: "Registration.Invitation.Invalid.Message2",
    text: "oder"
  },
  {
    id: "Registration.Invitation.Invalid.Message3",
    text:
      "Registrieren Sie sich mit einem neuen Firmenaccount. (Sie können in dem Fall jedoch nicht mit KollegInnen der einladenden Firma zusammenarbeiten)"
  },
  {
    id: "Registration.Invitation.Invalid.Button.ToRegistration",
    text: "Registrierung mit einem neuen Firmenaccount"
  },
  {
    id: "Footer.Contact",
    text: "Kontakt"
  },
  {
    id: "Footer.Certified",
    text: "Nutzungsbedingungen CERTIFIED"
  },
  {
    id: "TechnicalInfo.Heftverarbeitung",
    text: "Heftverarbeitung"
  },
  {
    id: "TechnicalInfo.Heftplatzierung",
    text: "Heftplatzierung"
  },
  {
    id: "FreeFormat.Accept",
    text: "Übernehmen"
  },
  {
    id: "FreeFormat.Width",
    text: "Breite (mm)*:"
  },
  {
    id: "FreeFormat.Width.Required",
    text: "Breite ist erforderlich"
  },
  {
    id: "FreeFormat.Height",
    text: "Höhe (mm)*:"
  },
  {
    id: "FreeFormat.Height.Required",
    text: "Höhe ist erforderlich"
  },
  {
    id: "FreeFormat.Anschnitt/Satzspiegel.Required",
    text: "Auswahl ist erforderlich"
  },
  {
    id: "FreeFormat.Format.Header",
    text: "neues Freies Format"
  },
  {
    id: "FreeFormat.Anschnitt",
    text: "Anschnitt"
  },
  {
    id: "FreeFormat.Satzspiegel",
    text: "Satzspiegel"
  },
  {
    id: "FreeFormat.Ueberbund",
    text: "Überbund"
  },
  {
    id: "FreeFormat.Platzierung",
    text: "Platzierung"
  },
  {
    id: "FreeFormat.Platzierung.Select.Required",
    text: "Platzierung ist erforderlich"
  },
  {
    id: "FreeFormat.Platzierung.Select.Innenteil",
    text: "Innenteil"
  },
  {
    id: "FreeFormat.Platzierung.Select.Umschlagseite",
    text: "Umschlagseite"
  },
  {
    id: "FreeFormat.Platzierung.Umschlag",
    text: "Umschlag"
  },
  {
    id: "FreeFormat.CreateJob",
    text: "Job mit freiem Format erstellen"
  },
  {
    id: "General.FreeFormat",
    text: "Freies Format"
  },
  {
    id: "FileUpload.File.Attached",
    text: "Datei angehängt"
  },
  {
    id: "FileUpload.Druckunterlage.Download",
    text: "Druckunterlage herunterladen"
  },
  {
    id: "FileUpload.Button.ReCheck",
    text: "Datei erneut prüfen"
  },
  {
    id: "FreeFormat.AcceptNew.Success",
    text: "Freies Format wurde erforderlich übernommen!"
  },
  {
    id: "ReceiverList.NotReceived",
    text: "Neu"
  },
  {
    id: "ReceiverList.Received",
    text: "Abgeholt"
  },
  {
    id: "ReceiverList.MarkAsReceived",
    text: "Als abgeholt markieren"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen.Empfehlung.Tooltip",
    text: "Empfohlene Mindestauflösung für Farb- und Graustufenbilder"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen.Grenzwert.Tooltip",
    text: "Warnschwelle für Farb- und Graustufenbilder"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder.Empfehlung.Tooltip",
    text:
      "Empfohlene Mindestauflösung für Linework und graphische Elemente und Darstellungen"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder.Grenzwert.Tooltip",
    text: "Warnschwelle für Linework und graphische Elemente und Darstellungen"
  },
  {
    id: "Home.Statistics.Anzeigen",
    text: "Anzeigen"
  },
  {
    id: "Home.Statistics.Firmen",
    text: "Firmen"
  },
  {
    id: "Home.Statistics.Medien",
    text: "Titel"
  },
  {
    id: "Home.Statistics.Verlage",
    text: "Verlage"
  },
  {
    id: "Home.Vorteile",
    text: "Vorteile"
  },
  {
    id: "Home.Preise",
    text: "Preise"
  },
  {
    id: "Home.Teilnehmen",
    text: "Teilnehmen"
  },
  {
    id: "Home.Logos.Hint",
    text: "Ein Kooperationsprojekt von"
  },
  {
    id: "Headers.Freigabe",
    text: "Freigabe"
  },
  {
    id: "MediaGrid.Actions",
    text: "Aktionen"
  },
  {
    id: "Freigabe.Zeitraum.Tooltip",
    text: ""
  },
  {
    id: "Freigabe.Zeitraum.Header",
    text: "Zeitraum wählen"
  },
  {
    id: "Freigabe.Verarbeitung",
    text: "Verarbeitung"
  },
  {
    id: "Freigabe.DateiFormat",
    text: "Empfohlenes Dateiformat"
  },
  {
    id: "Freigabe.Hefte.WidthAndHeight",
    text: "Heft Breite x Höhe [%{name}]"
  },
  {
    id: "Freigabe.Satzspiegel.WidthAndHeight",
    text: "Satzspiegel Breite x Höhe [%{name}]"
  },
  {
    id: "Freigabe.BeschnittZugaben",
    text: "BeschnittZugabe(n)"
  },
  {
    id: "Freigabe.Zertifizierung.Erfolgt",
    text: "Zertifizierung erfolgt"
  },
  {
    id: "Freigabe.Hefte.Header",
    text: "Hefte"
  },
  {
    id: "Freigabe.AnzeigenFormat.Header",
    text: "AnzeigenFormat"
  },
  {
    id: "Freigabe.PlatzierungruckVerfahren.Header",
    text: "Platzierung - Druckverfahren - Farbprofil"
  },
  {
    id: "Freigabe.Grid.Hefte.Nummer",
    text: "Nummer"
  },
  {
    id: "Freigabe.Grid.Hefte.EVT",
    text: "EVT"
  },
  {
    id: "Freigabe.Grid.Hefte.DuSchluss",
    text: "DU-Schluss"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.BezeichnungDE",
    text: "Bezeichnung DE"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Seitenteil",
    text: "Seitenteil"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Anschnitt",
    text: "Anschnitt"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Überbund",
    text: "Überbund"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Ausrichtung",
    text: "Ausrichtung"
  },
  {
    id: "Freigabe.Grid.Platzierung",
    text: "Platzierung im Heft"
  },
  {
    id: "Freigabe.Grid.Druckverfahren",
    text: "Druckverfahren"
  },
  {
    id: "Freigabe.Grid.FarbProfil",
    text: "Farbprofil"
  },
  {
    id: "Freigabe.ContactForm.Header",
    text: "Änderungsanfrage"
  },
  {
    id: "Freigabe.ContactForm.Message.Required",
    text: "Message ist erforderlich"
  },
  {
    id: "Freigabe.ContactForm.Button",
    text: "Senden"
  },
  {
    id: "Freigabe.Approved",
    text: "zertifiziert"
  },
  {
    id: "Freigabe.NotApproved",
    text: "zertifizieren"
  },
  {
    id: "Freigabe.PendingApproval",
    text: "Zertifizierung Austehend"
  },
  {
    id: "Freigabe.DownloadPDF",
    text: "Prüf-Dokument Herunterladen"
  },
  {
    id: "Freigabe.ChangeRequest",
    text: "Änderungsanfrage"
  },
  {
    id: "Freigabe.Approve",
    text: "Zertifizieren"
  },
  {
    id: "JobFormular.MediumSelector.Hint",
    text: "Geben Sie einen Medium-Namen oder eine ID ein"
  },
  {
    id: "TechnicalInfo.Sonstiges.Konventionen.Download",
    text: "Konventionen herunterladen"
  },
  {
    id: "JobFormular.SendContainer.Warning.Hint",
    text:
      "Im Preflight wurden Eigenschaften erkannt, die nicht den Empfehlungen entsprechen. Bitte prüfen Sie die Reports. Sie können die Druckunterlagen freigeben und dennoch versenden. Sie verzichten jedoch bezüglich der gewarnten Eigenschaften auf das Reklamationsrecht."
  },
  {
    id: "JobFormular.SendContainer.Warning.Cancel",
    text: "Abbrechen"
  },
  {
    id: "JobFormular.SendContainer.Warning.ApproveAndSend",
    text: "Freigeben und Versenden"
  },
  {
    id: "JobFormular.Format.Delete",
    text: "Anzeige löschen?"
  },
  {
    id: "Format.Display.Placeholder",
    text: "Leider keine Vorschau"
  },
  {
    id: "MagazineInfo.Seitengröße",
    text: "Seitengröße"
  },
  {
    id: "MagazineInfo.Seitengröße.Tooltip",
    text:
      "Breite und Höhe des geschlossenen und beschnittenen Endformates des Mediums in mm."
  },
  {
    id: "MagazineInfo.Satzspiegelgröße",
    text: "Satzspiegelgröße"
  },
  {
    id: "MagazineInfo.Satzspiegelgröße.Tooltip",
    text:
      "Breite und Höhe des Satzspiegels der einfachen Seite in mm. Der Satzspiegel ist die typographische Nutzfläche der Zeitschrift."
  },
  {
    id: "TechnicalInfo.Druckverfahren",
    text: "Druckverfahren"
  },
  {
    id: "JobList.JobHistory",
    text: "Historie"
  },
  {
    id: "JobDownloadAnonymously.Product",
    text: "Produkt"
  },
  {
    id: "JobDownloadAnonymously.Motive",
    text: "Motiv"
  },
  {
    id: "JobDownloadAnonymously.Issue",
    text: "Heft"
  },
  {
    id: "JobDownloadAnonymously.PublicationDate",
    text: "ET"
  },
  {
    id: "JobDownloadAnonymously.Format",
    text: "Format"
  },
  {
    id: "JobDownloadAnonymously.From",
    text: "Von"
  },
  {
    id: "JobDownloadAnonymously.Download",
    text: "Herunterladen"
  },
  {
    id: "JobDownloadAnonymously.Error",
    text: "Oops, der Link ist entweder abgelaufen oder ungültig."
  },
  {
    id: "Support.Sender.Header",
    text: "Versender Info"
  },
  {
    id: "Support.Sender",
    text: "Versender anzeigen"
  },
  {
    id: "Footer.Konventionen",
    text: "Konventionen"
  },
  {
    id: "General.AllBooking",
    text: "Gesamtes Heft"
  },
  {
    id: "General.Booking",
    text: "Belegung"
  },
  {
    id: "JobFormular.SelectFormat",
    text: "Bitte Format auswählen..."
  },
  {
    id: "General.Okay",
    text: "OK"
  },
  {
    id: "General.ToJobList",
    text: "ZUR JOBLISTE"
  },
  {
    id: "General.StayOnPage",
    text: "NEIN, HIER BLEIBEN"
  },
  {
    id: "JobFormular.DeleteJobSuccess",
    text: "Format/Heft wurde erfolgreich gelöscht. Bitte neues Format wählen."
  },
  {
    id: "JobFormular.SelectMedia.Duplicate",
    text:
      "Das Medium '%{name}' wurde bereits zur Seite hinzugefügt. Bitte fügen Sie eine weitere Job-Section innerhalb des Medium-Rahmens für das Medium '%{name}' hinzu, indem Sie eine weitere Ausgabe oder ein weiters Format für das Medium wählen."
  },
  {
    id: "JobList.CopyJob",
    text: "Kopiere Job"
  },
  {
    id: "JobList.CopyContainer",
    text: "Kopiere Versandauftrag"
  },
  {
    id: "JobList.ExchangeJob",
    text: "Austauschen / Ersetzen"
  },
  {
    id: "JobList.CopyJob.Choose",
    text: "Bitte wählen Sie ein Aktion"
  },
  {
    id: "JobList.CopyJob.Choose.WithPDF",
    text: "mit PDF"
  },
  {
    id: "JobList.CopyJob.Choose.WithoutPDF",
    text: "ohne PDF"
  },
  {
    id: "JobFormular.CopyJob.Message",
    text:
      "Dies ist eine Kopie von Job %{id}. Bitte überprüfen Sie die gewählten Hefte/Ausgaben und Formate"
  },
  {
    id: "JobFormular.CopyContainer.Message",
    text:
      "Dies ist eine Kopie von Versand %{id}. Bitte überprüfen Sie die gewählten Hefte/Ausgaben und Formate"
  },
  {
    id: "JobFormular.ExchangeJob.Message",
    text: "Dies ist ein Austausch-Motiv für DUON-ID %{id}"
  },
  {
    id: "JobFormular.ExchangeJob.Comment",
    text:
      "Dies ist ein Austausch-Motiv für DUON-ID %{id}. Bitte verwenden Sie diese PDF für die gebuchte Anzeige."
  },
  {
    id: "JobList.ExchangeJob.Message.Normal.FirstParagraph",
    text:
      "Mit „Austauschen / Ersetzen“ können Sie Druckunterlagen von bereits gesendeten Jobs austauschen. "
  },
  {
    id: "JobList.ExchangeJob.Message.Normal.SecondParagraph",
    text:
      "Der alte Job wird als „deprecated“ gekennzeichnet und der neue Job mit einem „update“-Kennzeichnung versehen. Ein entsprechender Hinweis wird für den Verlag angebracht."
  },
  {
    id: "JobList.ExchangeJob.Message.Normal.ThirdParagraph",
    text:
      "Falls Sie die Druckunterlagen auch in anderen Magazinen / Heftterminen einsetzen, denken Sie bitte daran, diese Daten ebenfalls zu tauschen!"
  },
  {
    id: "JobList.ExchangeJob.Message.DUReached",
    text:
      "Bitte beachten Sie: Der offizielle Druckunterlagenschlusstermin ist bereits überschritten. Ein Austausch der Druckunterlagen ist je nach Produktion-Timings eventuell auch kurzfristig noch nach individueller Abstimmung notwendig. Bitte nehmen Sie vor oder unmittelbar nach Versand Kontakt mit ihrem Ansprechpartner auf."
  },
  {
    id: "General.Continue",
    text: "Fortfahren"
  },
  {
    id: "General.Proof",
    text: "Prüfen"
  },
  {
    id: "JobFormular.JobStatus.Saved",
    text: "gespeichert"
  },
  {
    id: "JobFormular.JobStatus.Unsaved",
    text: "nicht gespeichert"
  },
  {
    id: "JobFormular.JobExchange.OldJob",
    text: "veraltet"
  },
  {
    id: "JobFormular.JobExchange.NewJob",
    text: "aktualisiert"
  },
  {
    id: "JobList.JobExchange.Filter.All",
    text: "Jeder Status"
  },
  {
    id: "JobList.JobExchange.Filter.OldJobs",
    text: "veralterte Jobs"
  },
  {
    id: "JobList.JobExchange.Filter.NewJobs",
    text: "aktualisierte Jobs"
  },
  {
    id: "JobList.PublicationStatus.Filter.All",
    text: "Alle"
  },
  {
    id: "JobList.PublicationStatus.Filter.Published",
    text: "veröffentlicht"
  },
  {
    id: "JobList.PublicationStatus.Filter.Pending",
    text: "ausstehend"
  },
  {
    id: "General.New",
    text: "neu"
  },
  {
    id: "Media.MediaNotFound",
    text:
      "Keine entsprechenden Medien gefunden, die am Duon-Portal teilnehmen. Informationen zu technischen Angaben finden Sie für diese Titel unter "
  },
  {
    id: "MediaDetail.TechnicalInfo",
    text: "Technische Informationen"
  },
  {
    id: "MediaDetail.TechnicalInfo.Tooltip",
    text: "Technische Informationen zum gewählten Hefte"
  },
  {
    id: "Verlage.PublisherNotFound",
    text:
      "Keine entsprechenden Verlage gefunden, die am Duon-Portal teilnehmen. Informationen zu technischen Angaben finden Sie für diese Titel unter "
  },
  {
    id: "JobList.NotificationsMessage",
    text: "Aktualisierungen in Liste vorhanden!"
  },
  {
    id: "JobList.Ignore",
    text: "Ignorieren"
  },
  {
    id: "General.Address",
    text: "Adresse"
  },
  {
    id: "DuonCertified.InfoText.Header",
    text:
      "Seit vielen Jahren anerkanntes Qualitätssiegel in der Prepress- und Publishingszene!"
  },
  {
    id: "DuonCertified.InfoText.Body",
    text:
      "Am DUON-certified-Siegel erkennen Sie ausgezeichnete Unternehmen, die Ihren Ansprüchen in Sachen qualitativ hochwertiger Druckdatenerstellung in jeder Hinsicht gerecht werden. Jedes hier gelistete Unternehmen wurde geprüft und erfüllt die strengen Zertifizierungskriterien."
  },
  {
    id: "DuonCertified.InfoText.Footer",
    text: "DUON certified – verlässliche Partner für Ihre Kreationen"
  },
  {
    id: "DuonCertified.InfoText.LearnMore",
    text: "Erfahren Sie mehr..."
  },
  {
    id: "TechnicalInfo.NoInfo",
    text: "Keine Angabe"
  },
  {
    id: "TechnicalInfo.Bunddoppelung",
    text: "Bunddoppelung"
  },
  {
    id: "TechnicalInfo.BunddoppelungZugabe",
    text: "Bdd-Zugabe"
  },
  {
    id: "General.Titelanzahl",
    text: "Titelanzahl"
  },
  {
    id: "General.Ansicht",
    text: "Ansicht"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Auflösung.Strichbilder",
    text: "AUFLÖSUNG STRICHBILDER/BITMAP"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Empfehlung",
    text: "Empfehlung"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Grenzwert",
    text: "Grenzwert"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Auflösung.Graustufen",
    text: "AUFLÖSUNG FARB- & GRAUSTUFENBILDER"
  },
  {
    id: "Freigabe.Certify.Success",
    text: "Fertig! Das Medium ist zertifiziert."
  },
  {
    id: "JobList.Notes.Popup.ContainerNote",
    text: "Versand Bemerkung"
  },
  {
    id: "JobList.Notes.Popup.JobNote",
    text: "Job Bemerkung"
  },
  {
    id: "JobList.Notes.Popup.ContainerNotes",
    text: "Versand"
  },
  {
    id: "JobList.Notes.Popup.JobNotes",
    text: "Job"
  },
  {
    id: "JobFormular.AddMediumFormat.Hint1",
    text:
      "Jobs innerhalb eines Versandauftrages können nur gleichzeitig versendet werden. Jeder Job muss dazu eine PDF enthalten, die mit Warnung oder OK geprüft wurde."
  },
  {
    id: "JobFormular.AddMediumFormat.Hint2",
    text:
      "Tipp: Wenn Sie Jobs zu unterschiedlichen Zeiten versenden möchten, legen Sie die Job jeweils als eigene Versandcontainer an."
  },
  {
    id: "JobFormular.AddMediumFormat.OptOut",
    text:
      "Ich habe verstanden und möchte diesen Hinweis zukünftig nicht mehr sehen."
  },
  {
    id: "General.Year",
    text: "Jahr"
  },
  {
    id: "General.Month",
    text: "Monat"
  },
  {
    id: "General.Monthes",
    text: "Monate"
  },
  {
    id: "General.AllDates",
    text: "Jedes Datum"
  },
  {
    id: "Home.Description.MediaAgentur.Text",
    text:
      "Sie möchten die Informationsprozesse verschlanken oder die Aufwände in der Produktionsplanerstellung senken? Das DUON-Portal bietet beste Voraussetzungen, um Ihre Prozesse in der Kundenbetreuung zu optimieren. Erfahren Sie mehr..."
  },
  {
    id: "Home.Description.CreationAndProduction.Text",
    text:
      "Das DUON-Portal ist die QUelle für teschnische Spezifikationen im Zeitschriftenbereich. Der integrierte Preflight prüft Ihre Anzeigen auf technische Produktionsrisiken. Unser Support hilft Ihnen, die optimale Qualität zu erzielen. Erfahren Sie mehr..."
  },
  {
    id: "Home.Description.VerlagePrepress.Text",
    text:
      "Unterstützen Sie Ihre Kunden im Adworkflow und bieten Sie Ihnen einen optimalen Service, um die beste Qualität für ihre Anzeigen zu erzielen. Mit dem DUON-Portal-Support bieten Sie ihren Kunden einen kostenlosen Support. Erfahren Sie mehr..."
  },
  {
    id: "Home.Description.MediaAgentur.TitleIntro",
    text: "Workflow für"
  },
  {
    id: "Home.Description.MediaAgentur.Title",
    text: "Media-Agenturen"
  },
  {
    id: "Home.Description.CreationAndProduction.TitleIntro",
    text: "Effizienter Arbeiten in der"
  },
  {
    id: "Home.Description.CreationAndProduction.Title",
    text: "Kreation & Produktion"
  },
  {
    id: "Home.Description.VerlagePrepress.TitleIntro",
    text: "Qualitätssicherung für Anzeigen"
  },
  {
    id: "Home.Description.VerlagePrepress.Title",
    text: "Verlage & Prepress"
  },
  {
    id: "JobFormular.SingleJobView.Hint",
    text:
      "Bitte beachten sie, dass dieser Job im Kontext eines Versandauftrages mit mehren Jobs steht und sich aus dem Kontext Informationen ergeben, die hier nicht abgebildet sind. So ergibt sich z.B. die Reihenfolge für Streckenanzeigen ausschließlich aus der Abfolge im Versandauftrag. Insbesondere bei bundüberlaufenden Anzeigen mit Einzelseitenanlieferung ist eine jeweils gegenüberliegende Seite in einem anderen Job enthalten."
  },
  {
    id: "Navigation.Settings",
    text: "Einstellungen"
  },
  {
    id: "Settings.JobCopyEmail.Title",
    text: "Versandbestätigung per E-Mail"
  },
  {
    id: "Settings.JobCopyEmail.Text",
    text:
      "Optional können Sie nach jedem erfolgreichen Versand eines Auftrages eine Versandbestätigung per E-Mail erhalten."
  },
  {
    id: "Settings.JobCopyEmail.Action",
    text: "Ich möchte eine Versandbestätigung per E-Mail erhalten"
  },
  {
    id: "Settings.JobCopyEmail.Success",
    text: "Die Änderungen wurden erfolgreich gespeichert."
  },
  {
    id: "Settings.GeneralNewsletter.Title",
    text: "Newsletter"
  },
  {
    id: "Settings.GeneralNewsletter.Text",
    text:
      "Optional können Sie einen Newsletter mit Neuigkeiten rund um das DUON-Portal erhalten."
  },
  {
    id: "Settings.GeneralNewsletter.Action",
    text:
      "Ich möchte per E-Mail über Neuigkeiten rund um das DUON-Portal informiert zu werden."
  },
  {
    id: "Settings.GeneralNewsletter.Success",
    text: "Die Änderungen wurden erfolgreich gespeichert."
  },
  {
    id: "Settings.TechnicalNewsletter.Title",
    text: "Technische Änderungen"
  },
  {
    id: "Settings.TechnicalNewsletter.Action",
    text:
      "Ich möchte per E-Mail über Änderungen an technischen Vorgaben informiert zu werden."
  },
  {
    id: "Settings.TechnicalNewsletter.Success",
    text: "Die Änderungen wurden erfolgreich gespeichert."
  },
  {
    id: "Certification.Apply",
    text: "Jetzt Zertifikat beantragen"
  },
  {
    id: "Certification.IAccept",
    text: "Ich akzeptiere die"
  },
  {
    id: "Certification.TermsOfUse",
    text: "Nutzungsbedingungen"
  },
  {
    id: "Certification.BillingAddress",
    text: "Abweichende Rechnungsadresse / Nachricht"
  },
  {
    id: "Certification.DisregardMessage",
    text: "Möchten Sie diese Nachricht einen Monat ausblenden?"
  },
  {
    id: "JobList.Filters.Search",
    text: "Suche"
  },
  {
    id: "JobList.Filters.WordTag",
    text: "Schlagwort"
  },
  {
    id: "JobList.Filters.PreflightStatusTag",
    text: "PRÜFERGEBNIS"
  },
  {
    id: "JobList.Filters.MediumTag",
    text: "Medium"
  },
  {
    id: "JobList.Filters.IssueTag",
    text: "Heft"
  },
  {
    id: "JobList.QuickView.Popup.Header",
    text: "Kurzübersicht"
  },
  {
    id: "JobList.Views.Default",
    text: "Alle Spalten"
  },
  {
    id: "JobList.Views.Compact",
    text: "Kompakt"
  },
  {
    id: "JobList.Views.Custom",
    text: "Meine Ansicht"
  },
  {
    id: "JobList.Views.System",
    text: "System"
  },
  {
    id: "JobList.Views.Private",
    text: "Privat"
  },
  {
    id: "FileUpload.SplitFormatWarning",
    text:
      "Achtung: Der Upload ist erst möglich, wenn die Motiv Bezeichnungen für die linke und die rechte Seite eingetragen sind."
  },
  {
    id: "Home.ReleaseBanner.Button",
    text: "Jetzt Entdecken"
  },
  {
    id: "AGBOverlay.AGB.IAccept.FirstPart",
    text: "Ich habe die"
  },
  {
    id: "AGBOverlay.AGB.IAccept.SecondPart",
    text:
      "in Version 2.1 erhalten und bin mit den Anpassungender einverstanden."
  },
  {
    id: "AGBOverlay.DataPrivacy.IAccept.FirstPart",
    text: "Ich habe die Information nach Art 13 DSGVO"
  },
  {
    id: "AGBOverlay.DataPrivacy.IAccept.SecondPart",
    text: "erhalten und zur Kenntnis genommen."
  },
  {
    id: "AGBOverlay.AGB.Term",
    text: "Allgemeinen Geschäftsbedingungen"
  },
  {
    id: "AGBOverlay.DataPrivacy.Term",
    text: "(Link)"
  },
  {
    id: "AGBOverlay.Header",
    text: "WIR BENÖTIGEN IHRE ZUSTIMMUNG"
  },
  {
    id: "TechnicalURL.CreateJob",
    text: "Job erstellen"
  },
  {
    id: "TechnicalURL.MediumPage",
    text: "Zur Medienseite"
  },
  {
    id: "TechnicalURL.CopyURL",
    text: "Kopieren"
  },
  {
    id: "TechnicalURL.MediaSearch",
    text: "Zur Mediensuche"
  },
  {
    id: "TechnicalURL.SplitFormatHint",
    text:
      "Das gewählte Werbeformat besteht aus mehreren separaten Teilen. Dieses Technischen Vorgaben beschreiben nur einen dieser Teile."
  },
  {
    id: "TechnicalURL.URLCopiedSuccess",
    text: "Link in Zwischenablage kopiert"
  }
];
