export const enLocale = [
  {
    id: "Home.FirstParagraph.Header",
    text: "DUON-Portal."
  },
  {
    id: "Home.FirstParagraph.SubHeader",
    text: "Check In & Preflight for print magazine ads"
  },
  {
    id: "Home.FirstParagraph.FirstLine",
    text:
      "The DUON portal is the print copy delivery tool for advertisements in German print media. "
  },
  {
    id: "Home.FirstParagraph.SecondLine",
    text:
      "Here you will find all technical information as well as the possibility to upload advertisements, "
  },
  {
    id: "Home.FirstParagraph.ThirdLine",
    text:
      "to have it checked and sent to the publishers. Fast, uncomplicated and safe."
  },
  {
    id: "Home.Buttons.Register",
    text: "Sign up now for free"
  },
  {
    id: "Home.Login.Hint",
    text: "If you´ve signed up already please go to…"
  },
  {
    id: "Home.SecondParagraph.Header",
    text: "Efficient ad-workflow for publishers"
  },
  {
    id: "Home.SecondParagraph.FirstLine",
    text:
      "The DUON portal is the digital print material solution for print media publishers. The system gives publishers the opportunity to communicate their technical "
  },
  {
    id: "Home.SecondParagraph.SecondLine",
    text:
      "specifications for the delivery of digital print documents to customers, media, creative and production agencies. The created advertising motifs can be uploaded directly "
  },
  {
    id: "Home.SecondParagraph.ThirdLine",
    text:
      "to the portal. Every advertisement is put through its paces from a technical perspective, so that the best possible quality is ensured."
  },
  {
    id: "Home.SecondParagraph.FourthLine",
    text: ""
  },
  {
    id: "Home.Verlag.Column1.Header.Line1",
    text: "Complete"
  },
  {
    id: "Home.Verlag.Column1.Header.Line2",
    text: "technical information"
  },
  {
    id: "Home.Verlag.Column1.Text",
    text:
      "Advertiser, media agency, lead agency, creative agency, production agency ... the advertising production line is long. Information is quickly lost. Lost, incorrect or outdated information are cost drivers in print production. By participating in the DUON portal, publishers ensure that all market participants have access to current, reliable and complete information at all times."
  },
  {
    id: "Home.Verlag.Column2.Header.Line1",
    text: "Bulletproof"
  },
  {
    id: "Home.Verlag.Column2.Header.Line2",
    text: "print quality"
  },
  {
    id: "Home.Verlag.Column2.Text",
    text:
      "Print advertising belongs to the advertising genres with comparatively complex production. At the end of the production line, the campaign must be rolled out within tight time limits. Mistakes happen quickly. Using the DUON preflight, each advertisement is automatically checked against around 300 criteria before it is sent. Customers, agencies and publishers benefit equally from this."
  },
  {
    id: "Home.Verlag.Column3.Header.Line1",
    text: "Optimal"
  },
  {
    id: "Home.Verlag.Column3.Header.Line2",
    text: "customer support"
  },
  {
    id: "Home.Verlag.Column3.Text",
    text:
      "With DUON support, your advertising customers have extensive and professional support at their disposal. The experts of the support team advise on all questions relating to the production of print documents and give specific instructions and tips for the operation of the graphics programs based on the respective advertisement."
  },
  {
    id: "Home.Verlag.Column4.Header.Line1",
    text: "Flexible"
  },
  {
    id: "Home.Verlag.Column4.Header.Line2",
    text: "reception options"
  },
  {
    id: "Home.Verlag.Column4.Text",
    text:
      "As the publisher, you decide how you receive the incoming data. In addition to the special reception area in the web portal, there is also the option of integrating the API into your own application. On request, the data can also be delivered via FTP."
  },
  {
    id: "Home.Verlag.Column5.Header.Line1",
    text: "E-mail notification"
  },
  {
    id: "Home.Verlag.Column5.Header.Line2",
    text: "of receipt of print documents"
  },
  {
    id: "Home.Verlag.Column5.Text",
    text:
      "As an option, your employees can be informed by email when new printing documents are received. It is possible to configure exactly who is responsible for which media and who would like to receive a message if necessary. In this way, the responsible persons are always informed quickly."
  },
  {
    id: "Home.Verlag.Column6.Header.Line1",
    text: "Cooperation with prepress "
  },
  {
    id: "Home.Verlag.Column6.Header.Line2",
    text: "service providers"
  },
  {
    id: "Home.Verlag.Column6.Text",
    text:
      "The access rights, reception and notification settings for your employees can be precisely controlled. You can also include service providers on a magazine-specific basis. The workflow and communication with the service providers can take place via the portal."
  },
  {
    id: "Home.Verlag.Column7.Header.Line1",
    text: "Automate with"
  },
  {
    id: "Home.Verlag.Column7.Header.Line2",
    text: "the DUON API"
  },
  {
    id: "Home.Verlag.Column7.Text",
    text:
      "The extensive and well-documented API (Application Programming Interface) makes it possible to integrate the DUON portal as a service into your own application. You can not only receive print documents but also create dispatch orders, upload print documents, have them checked and send."
  },
  {
    id: "Home.Verlag.Column8.Header.Line1",
    text: "Scalable"
  },
  {
    id: "Home.Verlag.Column8.Header.Line2",
    text: "cloud app"
  },
  {
    id: "Home.Verlag.Column8.Text",
    text:
      "The DUON portal runs entirely in the cloud. The excellent scalability ensures very high availability at all times. This is ensured, among other things, by the automatic scaling of the system: if the workload increases temporarily, resources are automatically added."
  },
  {
    id: "Home.Verlag.Column9.Header.Line1",
    text: "High level"
  },
  {
    id: "Home.Verlag.Column9.Header.Line2",
    text: "of security"
  },
  {
    id: "Home.Verlag.Column9.Text",
    text:
      "Strict security requirements apply in the run-up to the campaign. Channels such as e-mail or download links for various sharing solutions are not suitable here. The data is transmitted securely via the DUON portal. The transmission is encrypted. Only the necessary people get access. Each publisher can control this itself."
  },
  {
    id: "Home.Pricing.Header",
    text: "Price"
  },
  {
    id: "Home.Pricing.Column1.Header",
    text: "6,- EUR / Job*"
  },
  {
    id: "Home.Pricing.Column1.Text",
    text:
      "For each print document received (- 'Job'), the above flat fee charged. All services of the DUON portal are included. There will be no additional costs. A termination is possible at any time at the end of the quarter."
  },
  {
    id: "Home.Pricing.Column1.SubText",
    text:
      "* This offer is aimed at publishers with a low advertising volume. As an alternative to this offer, there is a separate billing model for publishers with a high advertising volume. Please ask!"
  },
  {
    id: "Home.Verlag.Participation.Header",
    text: "Participate"
  },
  {
    id: "Home.Verlag.Participation.Text",
    text:
      "By participating in the DUON portal, you offer your customers a professional interface for processing print documents and ensure optimal quality for your customers. At the same time, you reduce your costs in scheduling as well as prepress, repro / litho. Participation is very easy and the changeover to the DUON workflow usually takes only a few days. Just talk to us."
  },
  {
    id: "Home.Verlag.Participation.Button",
    text: "Request Participation"
  },
  {
    id: "Home.Brochure.Header",
    text: "Learn more"
  },
  {
    id: "Home.Brochure.Text",
    text:
      "You can find more information about the DUON portal in our PDF brochure,´which you can download below"
  },
  {
    id: "Home.Brochure.Button",
    text: "Download brochure"
  },
  {
    id: "Home.Search.Label",
    text: "Type in a publication name..."
  },
  {
    id: "Site.Search.Label",
    text: "Search here..."
  },
  {
    id: "Footer.Impressum",
    text: "Imprint"
  },
  {
    id: "Footer.Jobs",
    text: "Jobs"
  },
  {
    id: "Footer.AGB",
    text: "Terms and Conditions"
  },
  {
    id: "Footer.Datenschutz",
    text: "Privacy Statement"
  },
  {
    id: "Footer.Newsletter",
    text: "Newsletter"
  },
  {
    id: "Navigation.Media",
    text: "Publications"
  },
  {
    id: "Navigation.Jobs",
    text: "Jobs"
  },
  {
    id: "Navigation.Joblist",
    text: "Joblist"
  },
  {
    id: "Navigation.CreateJob",
    text: "Create Job"
  },
  {
    id: "Navigation.Blog",
    text: "Blog"
  },
  {
    id: "Navigation.API",
    text: "API"
  },
  {
    id: "Navigation.Login",
    text: "Login"
  },
  {
    id: "Navigation.Verlage",
    text: "Publishers"
  },
  {
    id: "Navigation.Certified",
    text: "Certified"
  },
  {
    id: "Navigation.Produkt",
    text: "Product"
  },
  {
    id: "Navigation.Empfang",
    text: "Reception"
  },
  {
    id: "Navigation.Freigabe",
    text: "Approval"
  },
  {
    id: "Navigation.Help",
    text: "Help"
  },
  {
    id: "Navigation.Profile",
    text: "My Profile"
  },
  {
    id: "Navigation.Settings",
    text: "Settings"
  },
  {
    id: "Navigation.MyMedia",
    text: "My Media"
  },
  {
    id: "Navigation.ManageCompanyUsers",
    text: "Manage Users"
  },
  {
    id: "Navigation.ChangePassword",
    text: "Change password"
  },
  {
    id: "Navigation.Logout",
    text: "Logout"
  },
  {
    id: "Headers.Media",
    text: "Publications"
  },
  {
    id: "MediaGrid.Name",
    text: "Name"
  },
  {
    id: "MediaGrid.SelectIssue",
    text: "Choose date/issue"
  },
  {
    id: "MediaGrid.Publisher",
    text: "Publisher"
  },
  {
    id: "MediaGrid.ExistingIssues",
    text_0: "No current issues",
    text_1: "1 current issue",
    text: "%{count} current issues"
  },
  {
    id: "Issues.Status",
    text: "Status"
  },
  {
    id: "Issues.Heft",
    text: "Publication"
  },
  {
    id: "Issues.Date",
    text: "Date"
  },
  {
    id: "Issues.ET",
    text: "Publication date"
  },
  {
    id: "Issues.DuSchluss",
    text: "Copy deadline"
  },
  {
    id: "Issues.MagazineHint",
    text: "Please contact our support team if you are missing a publication:"
  },
  {
    id: "Issues.NewspaperHint",
    text: "Please contact our support team if you are missing a newspaper:"
  },
  {
    id: "Issues.MoreIssues",
    text: "More issues..."
  },
  {
    id: "TechnicalInfo.Header",
    text: "Technical specs"
  },
  {
    id: "FilePreview.Header",
    text: "PDF preview"
  },
  {
    id: "FilePreview.Download",
    text: "Download PDF"
  },
  {
    id: "JobFormular.ChooseMedia",
    text: "Choose publication"
  },
  {
    id: "JobFormular.DeleteMediaMessage",
    text: "Delete publication including all ads?"
  },
  {
    id: "JobFormular.DeleteMediaConfirmation",
    text:
      "Do you really want to delete all ads for the publication with Id %{id} and name '%{name}'?"
  },
  {
    id: "JobFormular.Header",
    text: "Dispatch Order"
  },
  {
    id: "JobFormular.ChooseAnotherMedia.Title",
    text: "Choose a further publication"
  },
  {
    id: "JobFormular.ChooseAnotherMedia.Description",
    text:
      "If you wish, you can send multiple ads for different publications at once. Ad another publication and choose the desired ad-size(s)."
  },
  {
    id: "JobFormular.Client",
    text: "Advertising client"
  },
  {
    id: "JobFormular.Client.Tooltip",
    text:
      "Which company advertises with this/these ad/ads? Please type in the client`s name to identify the classification on the reveiver`s side. For example „Big B clothing Inc.“"
  },
  {
    id: "JobFormular.Client.Required",
    text: "Please fill in the field 'advertising client'."
  },
  {
    id: "JobFormular.Product",
    text: "Advertised product"
  },
  {
    id: "JobFormular.Product.Tooltip",
    text:
      "Which product or service shall be advertised? Please use the exact product-name (for example „Hair & Care“), at least the product-category („Hair care products“)"
  },
  {
    id: "JobFormular.Product.Required",
    text: "Please fill in the field 'advertised product'."
  },
  {
    id: "JobFormular.Notes",
    text: "Comments"
  },
  {
    id: "JobFormular.Notes.Tooltip",
    text:
      "Do you have a general comment for the publisher which should be included in all jobs?"
  },
  {
    id: "JobFormular.Notes.MaximumReached",
    text: "Please use a maximum of a 1.000 characters."
  },
  {
    id: "JobFormular.DeleteJobMessage",
    text: "Do you really want to delete the job for '%{name}'?"
  },
  {
    id: "JobFormular.ChooseAnotherFormat.Title",
    text: "Choose an additional size"
  },
  {
    id: "JobFormular.ChooseAnotherFormat.Description",
    text:
      "If you wish you can choose and add a further size for this publication."
  },
  {
    id: "JobFormular.IssueStatus",
    text: "Copy deadline"
  },
  {
    id: "JobFormular.IssueStatus.Tooltip",
    text:
      "This date is the offical copy deadline for delivering ad material for the selected issue. In case of any questions to the copy deadline please contact the publisher."
  },
  {
    id: "JobFormular.ET",
    text: "Issue - Publication date"
  },
  {
    id: "JobFormular.Newspaper.ET",
    text: "Publication date"
  },
  {
    id: "JobFormular.ET.Tooltip",
    text:
      "Please choose one of the issues from the list. If a publication or issue is missing, please contact the support-team."
  },
  {
    id: "JobFormular.Format.Tooltip",
    text:
      "Please choose the ad-size from the list. Please consider the booking (for example 'cover-page'), the orientation (for example 'across') and even bleed or non-bleed."
  },
  {
    id: "JobFormular.Motive.Tooltip",
    text:
      "Type in the motif name. Please use a distinct name for better classification."
  },
  {
    id: "JobFormular.Motive.Required",
    text: "Please fill out the field 'motif'."
  },
  {
    id: "JobFormular.PartialBooking.Tooltip",
    text: "This is only necessary if split run is booked."
  },
  {
    id: "JobFormular.JobNotes.Tooltip",
    text:
      "Do you have a special notification for the publisher you want to transmit with the ad?"
  },
  {
    id: "JobFormular.Steps.Start",
    text: "Create"
  },
  {
    id: "JobFormular.Steps.Proof",
    text: "Check"
  },
  {
    id: "JobFormular.Steps.Send",
    text: "Send"
  },
  {
    id: "JobFormular.Steps.Finish",
    text: "Ready"
  },
  {
    id: "FileUpload.Okay",
    text: "OK"
  },
  {
    id: "FileUpload.Warning",
    text: "Show all warnings"
  },
  {
    id: "FileUpload.PreflightError",
    text: "Show all errors"
  },
  {
    id: "FileUpload.CancelUpload",
    text: "Cancel upload"
  },
  {
    id: "FileUpload.UploadNewFile",
    text: "Upload new file"
  },
  {
    id: "FileUpload.FirstHint",
    text: "Drag a PDF onto this area"
  },
  {
    id: "FileUpload.SecondHint",
    text: "The job will be saved simultaneously to the upload"
  },
  {
    id: "FileUpload.Required",
    text: "Please complete the mandatory fields first"
  },
  {
    id: "FileUpload.Error",
    text:
      "The process couldn`t be completed succesfully. The uploaded file may be corrupted. Please contact the support-team or upload another file."
  },
  {
    id: "FileUpload.Timeout",
    text: "The process ended with a time-out. Please try again later."
  },
  {
    id: "FileUpload.FileNotAccepted",
    text:
      "The file size exceeds 100MB or is not pdf format. Please try uploading another file."
  },
  {
    id: "General.TechnicalInfo",
    text: "Technical specs"
  },
  {
    id: "General.Page",
    text: "Page"
  },
  {
    id: "General.Left",
    text: "Left"
  },
  {
    id: "General.Right",
    text: "Right"
  },
  {
    id: "General.Upload",
    text: "Upload"
  },
  {
    id: "General.Format",
    text: "Size"
  },
  {
    id: "General.Motive",
    text: "Motif"
  },
  {
    id: "General.PartialBooking",
    text: "Split run"
  },
  {
    id: "General.Notes",
    text: "Notifications"
  },
  {
    id: "General.Delete",
    text: "Delete"
  },
  {
    id: "General.Cancel",
    text: "Cancel"
  },
  {
    id: "General.Save",
    text: "Save"
  },
  {
    id: "General.Send",
    text: "Send"
  },
  {
    id: "General.MaxInput",
    text: "Please use a maximum of a 250 characters."
  },
  {
    id: "General.Media",
    text: "Publication"
  },
  {
    id: "General.Ad",
    text: "Ad"
  },
  {
    id: "General.Yes",
    text: "Yes"
  },
  {
    id: "General.No",
    text: "No"
  },
  {
    id: "General.Action",
    text: "Process"
  },
  {
    id: "General.View",
    text: "View"
  },
  {
    id: "General.Edit",
    text: "Edit"
  },
  {
    id: "General.Download",
    text: "Download"
  },
  {
    id: "General.All",
    text: "All"
  },
  {
    id: "General.Mr",
    text: "Mr."
  },
  {
    id: "General.Mrs",
    text: "Mrs."
  },
  {
    id: "General.PleaseNote",
    text: "Please note"
  },
  {
    id: "General.Grid.NoResults",
    text: "No data available"
  },
  {
    id: "Jobs.Saving.Success",
    text: "Job/s saved succesfully!"
  },
  {
    id: "CreateJob.Sending.Success",
    text: "Done! The data was received by the publisher. You can now ..."
  },
  {
    id: "Joblist.Sending.Success",
    text: "Done! The data was received by the publisher."
  },
  {
    id: "Jobs.Sending.Waiting",
    text: "Job/s waiting to be sent. Please do not close or refresh the page."
  },
  {
    id: "Jobs.Sending.IncompleteContainer",
    text: "Sending is not possible as there is/are one or more invalid job/s."
  },
  {
    id: "Support.Days",
    text: "Monday to Friday"
  },
  {
    id: "Support.Time",
    text: "9am  to 5pm (CET)"
  },
  {
    id: "Footer.SupportTime",
    text: "Mon-Fri 9am - 5pm (CET)"
  },
  {
    id: "Support.Contacts",
    text_0: "no contacts available",
    text_1: "Show 1 contact",
    text: "Show %{count} contacts"
  },
  {
    id: "Support.Contacts.Header",
    text_0: "Contact",
    text_1: "Contact",
    text: "Contacts"
  },
  {
    id: "JobList.MoreFilters",
    text: "Further filters"
  },
  {
    id: "JobList.WordTags.Header",
    text: "Tags"
  },
  {
    id: "JobList.WordTags.NoWordTag",
    text: "No tag"
  },
  {
    id: "JobList.WordTags.Delete.Header",
    text: "Delete tag?"
  },
  {
    id: "JobList.WordTags.Delete.Confirmation",
    text: "Are you sure you want to delete the tag?"
  },
  {
    id: "JobList.WordTags.New",
    text: "New tag"
  },
  {
    id: "JobList.WordTags.Create",
    text: "Create tag"
  },
  {
    id: "JobList.ColorTags.Select",
    text: "Choose a color"
  },
  {
    id: "JobList.ColorTags.Create",
    text: "Create a category"
  },
  {
    id: "JobList.ColorTags.Header",
    text: "Category"
  },
  {
    id: "JobList.ColorTags.Delete.Header",
    text: "Delete category?"
  },
  {
    id: "JobList.ColorTags.Delete.Confirmation",
    text: "Are you sure you want to delete the category?"
  },
  {
    id: "JobList.ExtraInfo.Popup.Header",
    text: "Additional Info"
  },
  {
    id: "JobList.ExtraInfo.Popup.Booking",
    text: "Booking"
  },
  {
    id: "JobList.ExtraInfo.Popup.Workflow",
    text: "Workflow"
  },
  {
    id: "JobList.ExtraInfo.Popup.Booking.Error.MaxLength",
    text: "Booking may contain a maximum of 500 characters"
  },
  {
    id: "JobList.ExtraInfo.Popup.Workflow.Error.MaxLength",
    text: "Workflow may contain a maximum of 500 characters"
  },
  {
    id: "JobList.ExtraInfo.Popup.Button",
    text: "Save"
  },
  {
    id: "JobList.Preflight.Header",
    text: "Preflight-results"
  },
  {
    id: "JobList.Preflight.All",
    text: "All results"
  },
  {
    id: "JobList.Preflight.NotProofed",
    text: "Not checked"
  },
  {
    id: "JobList.Preflight.Error",
    text: "Disruption"
  },
  {
    id: "JobList.Preflight.Warning",
    text: "Warning"
  },
  {
    id: "JobList.Preflight.Okay",
    text: "OK"
  },
  {
    id: "JobList.DateFilter.SenderHeader",
    text: "Date created"
  },
  {
    id: "JobList.DateFilter.ReceiverHeader",
    text: "Date received"
  },
  {
    id: "JobList.SenderView.Header",
    text: "Joblist"
  },
  {
    id: "JobList.ReceiverView.Header",
    text: "Reception"
  },
  {
    id: "JobList.Actions.Select",
    text: "Choose an action"
  },
  {
    id: "JobList.JobDeleted.Confirmation",
    text: "The job (ID: %{id}) was deleted succesfully"
  },
  {
    id: "JobList.DeleteJob.Header",
    text: "Delete job?"
  },
  {
    id: "JobList.DeleteJob.Confirmation",
    text: "Are you sure you want to delete the job (Id %{id}) for '%{name}'?"
  },
  {
    id: "JobList.ShowJobs",
    text: "Show all jobs"
  },
  {
    id: "JobList.Columns.Select",
    text: "Choose columns"
  },
  {
    id: "JobList.Columns.status",
    text: "Job-status"
  },
  {
    id: "JobList.Columns.ergebnisId",
    text: "Preflight-result"
  },
  {
    id: "JobList.Columns.thumbnail",
    text: "Thumbnail"
  },
  {
    id: "JobList.Columns.id",
    text: "DUON-ID"
  },
  {
    id: "JobList.Columns.containerId",
    text: "Dispatch-ID"
  },
  {
    id: "JobList.Columns.kunde",
    text: "Client"
  },
  {
    id: "JobList.Columns.produkt",
    text: "Product"
  },
  {
    id: "JobList.Columns.medium",
    text: "Publication"
  },
  {
    id: "JobList.Columns.mediumId",
    text: "Publication ID"
  },
  {
    id: "JobList.Columns.heft",
    text: "Issue"
  },
  {
    id: "JobList.Columns.format",
    text: "Size"
  },
  {
    id: "JobList.Columns.Tag",
    text: "Tags"
  },
  {
    id: "JobList.Columns.colorTag",
    text: "Category"
  },
  {
    id: "JobList.Columns.extraInfo",
    text: "Own Notes"
  },
  {
    id: "JobList.Columns.notes",
    text: "Comments"
  },
  {
    id: "JobList.Columns.versendetZeitpunkt",
    text: "Sent Date"
  },
  {
    id: "JobList.Columns.motiv",
    text: "Motif"
  },
  {
    id: "JobList.Columns.duSchluss",
    text: "Deadline"
  },
  {
    id: "JobList.Columns.actions",
    text: "Choose an action"
  },
  {
    id: "JobList.Columns.quickView",
    text: "Brief View"
  },
  {
    id: "JobList.Columns.abholstatus",
    text: "Received Status"
  },
  {
    id: "JobList.Columns.jobexchangestatus",
    text: "Exchange"
  },
  {
    id: "JobList.Status.All",
    text: "All Jobs"
  },
  {
    id: "JobList.Status.NotSent",
    text: "Draft"
  },
  {
    id: "JobList.Status.Sent",
    text: "Sent"
  },
  {
    id: "JobList.Status.Draft",
    text: "Draft"
  },
  {
    id: "JobList.Status.Error",
    text: "Disruption"
  },
  {
    id: "JobList.Refresh",
    text: "Refresh Joblist"
  },
  {
    id: "MediaDetail.Booking.Header",
    text: "Booking"
  },
  {
    id: "MediaDetail.CreateJob",
    text: "Create job"
  },
  {
    id: "MediaDetail.HasSplitFormat",
    text:
      "This size consists of parts (for example: a douple-page should be delivered in single-pages)"
  },
  {
    id: "MediaDetail.AllTechnicalInfo",
    text: "All technical specs"
  },
  {
    id: "MediaDetail.NoIssues",
    text: "There are currently no issues approved for delivery."
  },
  {
    id: "TechnicalInfo.Anschnitt",
    text: "Bleed"
  },
  {
    id: "TechnicalInfo.Anschnitt.Tooltip",
    text:
      "The bleed should be added to the trimbox-dimensions and the sum makes the bleedbox. The bleed avoids problems concerning the cutting-tolerance of the printed publication."
  },
  {
    id: "TechnicalInfo.Anzeigenformat",
    text: "Ad size"
  },
  {
    id: "TechnicalInfo.Top",
    text: "top"
  },
  {
    id: "TechnicalInfo.Bottom",
    text: "bottom"
  },
  {
    id: "TechnicalInfo.Left",
    text: "left"
  },
  {
    id: "TechnicalInfo.Right",
    text: "right"
  },
  {
    id: "TechnicalInfo.IccProfile",
    text: "ICC color-profile"
  },
  {
    id: "TechnicalInfo.IccProfile.Tooltip",
    text:
      "Please use the recommended color-profile for the color-adaption. Using the color-profile provides a high quality color-reproduction."
  },
  {
    id: "TechnicalInfo.PartialFormat",
    text: "Partial sizes"
  },
  {
    id: "TechnicalInfo.Profile",
    text: "Color-profile"
  },
  {
    id: "TechnicalInfo.Grenzwerte",
    text: "Treshold value"
  },
  {
    id: "TechnicalInfo.Others",
    text: "Others"
  },
  {
    id: "TechnicalInfo.Others.Description",
    text:
      "Further technical guidelines are part of the DUON-conventions, which can be downloaded here"
  },
  {
    id: "TechnicalInfo.Endformat",
    text: "Trim-size"
  },
  {
    id: "TechnicalInfo.Endformat.Width",
    text: "Width"
  },
  {
    id: "TechnicalInfo.Endformat.Height",
    text: "Height"
  },
  {
    id: "TechnicalInfo.Endformat.Tooltip",
    text:
      "The trim-size (trimbox) signifies the size of the final ad in the publication."
  },
  {
    id: "TechnicalInfo.Sicherheitsabstand",
    text: "Safe distance"
  },
  {
    id: "TechnicalInfo.Sicherheitsabstand.Tooltip",
    text:
      "Please do not place important elements outside of the safe distance as they might get cut during cutting-tolerances."
  },
  {
    id: "TechnicalInfo.Flächendeckung.Max",
    text: "Max. area-coverage"
  },
  {
    id: "TechnicalInfo.Profile.Hint",
    text:
      "The output intent must be set, according to the required color-profile. DeviceCMYK will be interprated according to the output intent. Embedded ICC-color-profiles are allowed according to the output-intent."
  },
  {
    id: "TechnicalInfo.PDFInfo.Standards",
    text: "Permissible standards"
  },
  {
    id: "TechnicalInfo.PDFInfo.Standards.Tooltip",
    text: "All files will be accepted according to the required standards."
  },
  {
    id: "TechnicalInfo.PDFInfo.Preference",
    text: "Preferred by the publisher"
  },
  {
    id: "TechnicalInfo.PDFInfo.Preference.Tooltip",
    text: "The publisher recommends the use of the stated standard."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken",
    text: "Line strenghts"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Pos",
    text: "min. line strenght positive"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Neg",
    text: "min. line strenght negative"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Pos.1Cpos.Tooltip",
    text:
      "Effective line strength for postive 1C lines/contures (for example: black line on white ground or cyan line on white ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Neg.1Cneg.Tooltip",
    text:
      "Effective line strength for negative 1C lines/contures (for example: white line on black)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Pos.4Cpos.Tooltip",
    text:
      "Effective line strength for postive 2C (and above) lines/contures (for example: green line on white ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichstärken.Min.Neg.4Cneg.Tooltip",
    text:
      "Effective line strength for negative 2C (and above) lines/contures (for example: green line on brown ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Auflösung",
    text: "Resolution"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen",
    text: "Greyscale and color pictures"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder",
    text: "Bitmap/Lineworks"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen.Tooltip",
    text:
      "Recommended effective minimum resolution for black/white and color pictures."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Auflösung.Graustufen",
    text: "Resolution Greyscale and color pictures"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Empfehlung",
    text: "Recommendation"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Grenzwert",
    text: "Limit"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder.Tooltip",
    text: "Recommended effective minimum resolution for grafic elements."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Auflösung.Strichbilder",
    text: "Resolution for grafic elements"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen",
    text: "Font sizes"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Pos",
    text: "min. font size positive"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Neg",
    text: "min. font size negative"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Pos.1Cpos.Tooltip",
    text:
      "Barely safe to reproduce font size for 1C positive (for example: black font on white ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Neg.1Cneg.Tooltip",
    text:
      "Barely safe to reproduce font size for 1C negative (for example: white font on black ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Pos.4Cpos.Tooltip",
    text:
      "Barely safe to reproduce font size for 2C (and above) positive (for example: dark green font on white ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Schriftgrössen.Min.Neg.4Cneg.Tooltip",
    text:
      "Barely safe to reproduce font size for 1C (or no color) negative (for example: cyan font on dark brown ground)."
  },
  {
    id: "TechnicalInfo.Grenzwerte.Hint",
    text:
      "Falling below the required line strength and font size values can cause loss of quality. The publisher cannot be held responsible for an optimum print result. Please check if you can give an approval under this circumstances. In this case you will resign on your correspondent complaint-rights."
  },
  {
    id: "SendConfirmEmail.Header",
    text: "Activate Account"
  },
  {
    id: "SendConfirmEmail.Header.MinimaliseText",
    text: "Send yourself a new link!"
  },
  {
    id: "SendConfirmEmail.Hint",
    text:
      "You have already registered, but haven't activated your account? We can send you an email with an activation link."
  },
  {
    id: "SendConfirmEmail.Button",
    text: "Yes, send me the email"
  },
  {
    id: "SendConfirmEmail.Success",
    text:
      "An email has been sent. Please use the link to activate your account."
  },
  {
    id: "SendConfirmEmail.Error",
    text: " Oops, an error has occurred. Please try again later."
  },
  {
    id: "SendConfirmEmail.YourEmail",
    text: "Ihre E-Mail"
  },
  {
    id: "UserProfile.Header",
    text: "My Profile"
  },
  {
    id: "UserProfile.UserDetails",
    text: "User Details"
  },
  {
    id: "UserProfile.Saved",
    text: "Saved"
  },
  {
    id: "UserProfile.CompanyAdmin.Hint",
    text: "* Only a company-admin may change company-informations"
  },
  {
    id: "UserProfile.ChangePassword",
    text: "Change password"
  },
  {
    id: "UserProfile.ConfirmEmailLink.Header",
    text: "Please activate your account"
  },
  {
    id: "UserProfile.ConfirmEmailLink.Hint",
    text:
      "A verification email was sent to %{name}. If you didn´t receive the verification email click here to generate a new one."
  },
  {
    id: "UserProfile.ConfirmEmailLink.Button",
    text: "Send new email"
  },
  {
    id: "UserProfile.Email.Header",
    text: "Email"
  },
  {
    id: "UserProfile.Email.Error",
    text: "This is not a valid email"
  },
  {
    id: "UserProfile.Email.Required",
    text: "Email is required"
  },
  {
    id: "UserProfile.Email.Invalid",
    text: "This is not a valid email"
  },
  {
    id: "UserProfile.Email.MaxLength",
    text: "Email may contain a maximum of 255 characters"
  },
  {
    id: "UserProfile.Titel.Header",
    text: "Title"
  },
  {
    id: "UserProfile.Titel.Required",
    text: "Title is required"
  },
  {
    id: "UserProfile.Titel.Select",
    text: "Please choose ..."
  },
  {
    id: "UserProfile.Firstname",
    text: "First name"
  },
  {
    id: "UserProfile.Firstname.Required",
    text: "First name is required"
  },
  {
    id: "UserProfile.Firstname.Invalid",
    text: "The first name may contain a maximum of 100 characters"
  },
  {
    id: "UserProfile.Lastname",
    text: "Last name"
  },
  {
    id: "UserProfile.Lastname.Required",
    text: "Last name is required"
  },
  {
    id: "UserProfile.Lastname.Invalid",
    text: "The last name may contain a maximum of 100 characters"
  },
  {
    id: "UserProfile.Telephone",
    text: "Telephone"
  },
  {
    id: "UserProfile.Telephone.Invalid",
    text: "The telephone number may contain a maximum of 100 characters"
  },
  {
    id: "UserProfile.Language",
    text: "Language"
  },
  {
    id: "UserProfile.Language.English",
    text: "English"
  },
  {
    id: "UserProfile.Language.German",
    text: "German"
  },
  {
    id: "UserProfile.Language.Required",
    text: "Language is required"
  },
  {
    id: "UserProfile.Company.Street",
    text: "Street and house number"
  },
  {
    id: "UserProfile.Company.Street.Invalid",
    text: "The street name may contain a maximum of 255 characters"
  },
  {
    id: "UserProfile.Company.City",
    text: "City"
  },
  {
    id: "UserProfile.Company.City.Invalid",
    text: "The city name may contain a maximum of 255 characters"
  },
  {
    id: "UserProfile.Company",
    text: "Company"
  },
  {
    id: "UserProfile.Company.Required",
    text: "Company name is required"
  },
  {
    id: "UserProfile.Company.Street.Required",
    text: "Street and house number are required"
  },
  {
    id: "UserProfile.Company.City.Required",
    text: "City is required"
  },
  {
    id: "UserProfile.Company.PostalCode",
    text: "Postcode"
  },
  {
    id: "UserProfile.Company.PostalCode.Required",
    text: "Postcode is required"
  },
  {
    id: "UserProfile.Company.PostalCode.Invalid",
    text: "Postcode may contain a maximum of 10 characters"
  },
  {
    id: "UserProfile.Company.Land.Required",
    text: "Country name is required"
  },
  {
    id: "UserProfile.Company.Newsletter.Product",
    text:
      "I want to receive informations about DUON-product-news and changes by email."
  },
  {
    id: "UserProfile.Company.Newsletter.Technical",
    text:
      "I want to receive the notifications of all technical changes in DUON-publications by email."
  },
  {
    id: "UserProfile.JobCopyEmail",
    text:
      "I want to receive the notifications of jobs I send in DUON-publications by email."
  },
  {
    id: "General.Company.URL",
    text: "URL-Web Address"
  },
  {
    id: "UserProfile.Password.Invalid",
    text: "Password is invalid"
  },
  {
    id: "MyMedia.Header",
    text: "My Media"
  },
  {
    id: "MyMedia.HintTextChangeAutoSaved",
    text: "Any changes will be automatically saved"
  },
  {
    id: "MyMedia.Grid.Column.Publisher",
    text: "Publisher"
  },
  {
    id: "MyMedia.Grid.Column.ReceivePrintMaterial",
    text: "Recieve Print Material"
  },
  {
    id: "MyMedia.Grid.Column.RecievalNotication",
    text: "Print Material Recieval Notication"
  },
  {
    id: "MyMedia.Grid.Column.MediaAdministration",
    text: "Media Administration"
  },
  {
    id: "MyMedia.Grid.Column.RecieveTechnicalChangesNotification",
    text: "Recieve Technical Changes Notification"
  },
  {
    id: "MyMedia.Error.LoadingData",
    text: "Oops, this shouldn't happen. The media info couldn't be loaded."
  },
  {
    id: "MyUsers.Grid.Column.FirstName",
    text: "First Name"
  },
  {
    id: "MyUsers.Grid.Column.Surname",
    text: "Surname"
  },
  {
    id: "MyUsers.Grid.Column.Email",
    text: "Email"
  },
  {
    id: "MyUsers.Grid.Column.ReceivePrintMaterial",
    text: "Recieve Print Material"
  },
  {
    id: "MyUsers.Grid.Column.RecievalNotication",
    text: "Print Material Recieval Notication"
  },
  {
    id: "MyUsers.Grid.Column.MediaAdministration",
    text: "Media Administration"
  },
  {
    id: "MyUsers.Grid.Column.RecieveTechnicalChangesNotification",
    text: "Recieve Technical Changes Notification"
  },
  {
    id: "ResetPassword.Success",
    text: "The new password was saved succesfully"
  },
  {
    id: "ResetPassword.Reset.Success",
    text: "Password resetted succesfully"
  },
  {
    id: "ResetPassword.Password.Actual",
    text: "Current password"
  },
  {
    id: "ResetPassword.Password.Invalid",
    text: "Password is invalid"
  },
  {
    id: "ResetPassword.Change",
    text: "Change password"
  },
  {
    id: "ResetPassword.Reset",
    text: "Reset password"
  },
  {
    id: "ResetPassword.NewPassword",
    text: "New password"
  },
  {
    id: "ResetPassword.NewPassword.Confirm",
    text: "Confirm new password"
  },
  {
    id: "ResetPassword.ToMyProfile",
    text: "View my profile"
  },
  {
    id: "General.Login",
    text: "Login"
  },
  {
    id: "ForgotPassword.Success",
    text:
      "We have sent you an email. Please use the link in the email to reset your password."
  },
  {
    id: "ForgotPassword.Button.Send",
    text: "Yes, send me the password reset link"
  },
  {
    id: "ForgotPassword.Error",
    text: "Oops, an error occurred. Please try again later."
  },
  {
    id: "ForgotPassword",
    text: "Password forgotten"
  },
  {
    id: "ForgotPassword.YourEmail",
    text: "Your Email"
  },
  {
    id: "CompanyUser.Error.Invitation",
    text: "Oops, an error occurred. Invitation could not be sent."
  },
  {
    id: "CompanyUser.Error.Invitation.InvalidEmail",
    text: "Email is invalid"
  },
  {
    id: "CompanyUser.Error.Invitation.InvalidEmail.MaxLength",
    text: "Email may contain a maximum of 255 characters"
  },
  {
    id: "CompanyUser.Error.Invitation.EmailAlreadyFromCompany",
    text: "The user is already in your company"
  },
  {
    id: "CompanyUser.Error.Invitation.Message.Required",
    text: "Didn't you want to add a message? If not, simply close the box"
  },
  {
    id: "CompanyUser.Error.Invitation.Message.MaxLength",
    text: "The message can only be max 2000 characters"
  },
  {
    id: "CompanyUser.Error.User",
    text: "Oops, an error occurred. The user-list couldn`t be loaded."
  },
  {
    id: "CompanyUser.Header",
    text: "Manage Users"
  },
  {
    id: "CompanyUser.Invite.Email",
    text: "Email"
  },
  {
    id: "CompanyUser.HintTextChangeAutoSaved",
    text: "Any changes will be automatically saved"
  },
  {
    id: "CompanyUser.Invite.Header",
    text: "Invite New User"
  },
  {
    id: "CompanyUser.Invite.AddCustomMessage",
    text: "Add a custom message that will be display in the invitation email"
  },
  {
    id: "CompanyUser.Invite.CustomMessagePlaceholder",
    text: "Hello Mr. Maxwell Smart,"
  },
  {
    id: "CompanyUser.Invite.Text.Invalid",
    text: "The custom message allows for a max. 2000 characters"
  },
  {
    id: "CompanyUser.Invite.EmailPlaceholder",
    text: "maxwell@mini-max.co.uk"
  },
  {
    id: "CompanyUser.Invite.Send",
    text: "Send Invite"
  },
  {
    id: "CompanyUser.Invite.Sending",
    text: "Sending Invite"
  },
  {
    id: "CompanyUser.Success.Invitation",
    text: "An invitation was successfully sent to '%{name}'"
  },
  {
    id: "CompanyUser",
    text: "Company Users"
  },
  {
    id: "CompanyUser.Grid.Column.Email",
    text: "Email"
  },
  {
    id: "CompanyUser.Grid.Column.FirstName",
    text: "First Name"
  },
  {
    id: "CompanyUser.Grid.Column.Surname",
    text: "Surname"
  },
  {
    id: "CompanyUser.Grid.Column.Telephone",
    text: "Telephone"
  },
  {
    id: "CompanyUser.Grid.Column.CompanyAdmin",
    text: "Company Admin"
  },
  {
    id: "CompanyUser.Grid.Column.MediaPermissions",
    text: "Media Permissions"
  },
  {
    id: "CompanyUser.Grid.Column.Button.Yes",
    text: "Yes"
  },
  {
    id: "CompanyUser.Grid.Column.Button.No",
    text: "No"
  },
  {
    id: "CompanyChange.Error",
    text: "Oops, this invitation expired or is invalid."
  },
  {
    id: "CompanyChange.ToLogin",
    text: "To login"
  },
  {
    id: "CompanyChange.Success",
    text: "Success"
  },
  {
    id: "CompanyChange.MemberOf",
    text: "Now you are a member of"
  },
  {
    id: "CompanyChange.Invite",
    text: "Invitation"
  },
  {
    id: "CompanyChange.Invitation.Message1",
    text: "invited you, to take part at the DUON-portal-account of %{name}."
  },
  {
    id: "CompanyChange.Invitation.Message2",
    text:
      "During the automatic check of your e-mail address, the system detected that you had already joined account %{name} with this e-mail address."
  },
  {
    id: "CompanyChange.Invitation.Message3",
    text:
      "By clicking onto ACCEPT INVITATION, your duon account under the email '%{id}' will be assigned to account %{name}."
  },
  {
    id: "CompanyChange.Invitation.Message4",
    text:
      "You lose access to data and jobs %{name}. If you are the only user for %{name}, no-one else will be able access the data %{name}."
  },
  {
    id: "CompanyChange.AcceptInvitation",
    text: "Accept invitation"
  },
  {
    id: "PasswordFields.Password",
    text: "Password"
  },
  {
    id: "PasswordFields.Invalid",
    text: "Password is invalid"
  },
  {
    id: "PasswordFields.Validation",
    text:
      "The password must contain a minimum of 6 and a maximum of 100 characters."
  },
  {
    id: "PasswordFields.Validation.ContainsLowercaseChars",
    text: "Password must contains at least one lowercase character (a-z)."
  },
  {
    id: "PasswordFields.Validation.ContainsUppercaseChars",
    text: "Password must contains at least one uppercase character (A-Z)."
  },
  {
    id: "PasswordFields.Validation.ContainsNumber",
    text: "Password must contains at least one number (0-9)."
  },
  {
    id: "PasswordFields.Confirm",
    text: "Confirm password"
  },
  {
    id: "PasswordFields.Compare",
    text: "The new password and the password repetition do not match."
  },
  {
    id: "PasswordPopup.Header",
    text: "Password Tips"
  },
  {
    id: "PasswordPopup.Hint.1",
    text: "The longer the better (at least 6 characters)"
  },
  {
    id: "PasswordPopup.Hint.2",
    text: "Uppercase (A-Z) and lowercase (a-z) characters and numbers (0-9)"
  },
  {
    id: "PasswordPopup.Hint.3",
    text: "Special characters are recommended (spaces,?!% + ...)"
  },
  {
    id: "PasswordPopup.Hint.4",
    text: "Preferably words not from the dictionary"
  },
  {
    id: "PasswordPopup.Header.Minimum",
    text: "Minimum"
  },
  {
    id: "PasswordPopup.Header.Hint",
    text: "Hint"
  },
  {
    id: "PasswordPopup.Minimum.1",
    text: "6 characters"
  },
  {
    id: "PasswordPopup.Minimum.2",
    text: "one uppercase character (A-Z)"
  },
  {
    id: "PasswordPopup.Minimum.3",
    text: "one lowercase character (a-z)"
  },
  {
    id: "PasswordPopup.Minimum.4",
    text: "one number (0-9)"
  },
  {
    id: "PasswordPopup.Minimum.5",
    text: "Special characters are allowed (Leerzeichen, ?!%+…)"
  },
  {
    id: "PasswordPopup.Hint.1",
    text: "The longer the better"
  },
  {
    id: "PasswordPopup.Hint.2",
    text: "Preferably words not from the dictionary"
  },
  {
    id: "PasswordPopup.Hint.3",
    text: "Special characters are recommended"
  },
  {
    id: "Privacy.General.Description",
    text: "Terms and conditions Duon-portal"
  },
  {
    id: "Privacy.General.Description.Read",
    text: "read"
  },
  {
    id: "Privacy.General.Description.Hint",
    text:
      "In order to use this portal you must agree to the terms and conditions."
  },
  {
    id: "Data.General.Description",
    text: "General privacy policy Duon-portal"
  },
  {
    id: "Data.General.Description.Read",
    text: "read"
  },
  {
    id: "Data.General.Description.Hint",
    text: "In order to use this portal you must confirm the privacy policy."
  },
  {
    id: "Registration.LoginDetails",
    text: "Login Details"
  },
  {
    id: "RegisterSuccess.AlmostDone",
    text: "Almost done"
  },
  {
    id: "RegisterSuccess.AlmostDone.Step1",
    text: "1. Registration completed"
  },
  {
    id: "RegisterSuccess.AlmostDone.Step2",
    text: "2. Please confirm your email"
  },
  {
    id: "RegisterSuccess.Hint",
    text:
      "You will receive a verification email shortly. Please check your email account (the spam folder if necessary) and follow the instructions in the email."
  },
  {
    id: "RegistrationConfirmation.Success.Header",
    text: "Email confirmed"
  },
  {
    id: "RegistrationConfirmation.Success",
    text: "Thanks for confirming your email."
  },
  {
    id: "RegistrationConfirmation.Success.Button",
    text: "Login"
  },
  {
    id: "RegistrationConfirmation.Error",
    text:
      "The activation link is no longer valid. This can be for several reasons. Did you register longer than 72 hours ago? Did you already use the link? To send another email please use the form below."
  },
  {
    id: "Auth.Login",
    text: "Login"
  },
  {
    id: "Auth.Login.Error.Email",
    text: "Please enter a valid email address"
  },
  {
    id: "Auth.Login.Error.Email.Empty",
    text: "Please enter an email address"
  },
  {
    id: "Auth.Login.Error.Passwort",
    text: "This is not a valid password"
  },
  {
    id: "Auth.Login.Error.Passwort.Empty",
    text: "Please enter a password"
  },
  {
    id: "Auth.Register",
    text: "Register"
  },
  {
    id: "Auth.NotWorking",
    text: "Does not work?"
  },
  {
    id: "Auth.ForgotPassword",
    text: "Forgot your password?"
  },
  {
    id: "Auth.Inactive",
    text: "Registered but not activated?"
  },
  {
    id: "Auth.UnsuccessfulLogin",
    text: "Invalid login attempt"
  },
  {
    id: "Registration.Header",
    text: "Create account"
  },
  {
    id: "Registration.Finish",
    text: "Finish registration"
  },
  {
    id: "Registration.AlreadyRegistered",
    text: "Already registered? To login"
  },
  {
    id: "Registration.CorrectErrors",
    text: "Please correct your data"
  },
  {
    id: "Registration.PrivacyStatement",
    text: "Privacy Policy"
  },
  {
    id: "Registration.TermsAndConditions",
    text: "Terms"
  },
  {
    id: "Registration.AgreeToPrivacyAndTerms1",
    text: "By registering, you agree to our "
  },
  {
    id: "Registration.AgreeToPrivacyAndTerms2",
    text: " and "
  },
  {
    id: "Registration.AgreeToPrivacyAndTerms3",
    text: "."
  },
  {
    id: "Registration.CancelNewslettersConsent",
    text: "You can cancel the newsletters at any time on your profile page. "
  },
  {
    id: "Registration.Invitation.Info1",
    text: " invited you to join the company "
  },
  {
    id: "Registration.Invitation.Info2",
    text:
      " on Duon-Portal. Please register using the form below to accept this invitation."
  },
  {
    id: "Registration.Invitation.Success",
    text: "Registration Successful"
  },
  {
    id: "Registration.Invitation.Success.Welcome",
    text: "Welcome "
  },
  {
    id: "Registration.Invitation.Success.NowLogin",
    text: "You can now login."
  },
  {
    id: "Registration.Invitation.Success.LoginButton",
    text: "Login"
  },
  {
    id: "Registration.Invitation.Invalid",
    text: "The link is invalid or has expired."
  },
  {
    id: "Registration.Invitation.Invalid.Message1",
    text: "Contact the person who sent you the invitation "
  },
  {
    id: "Registration.Invitation.Invalid.Message2",
    text: "or"
  },
  {
    id: "Registration.Invitation.Invalid.Message3",
    text:
      "Register with a new company account (however you won't be able to work together with the company who sent you the invitation)"
  },
  {
    id: "Registration.Invitation.Invalid.Button.ToRegistration",
    text: "Register with a new company account"
  },
  {
    id: "TechnicalInfo.Heftverarbeitung",
    text: "Binding"
  },
  {
    id: "TechnicalInfo.Heftplatzierung",
    text: "Placement"
  },
  {
    id: "Footer.Contact",
    text: "Contact Us"
  },
  {
    id: "Footer.Certified",
    text: "Terms of Use CERTIFIED"
  },
  {
    id: "FreeFormat.Accept",
    text: "Apply"
  },
  {
    id: "FreeFormat.Width",
    text: "Width (mm)*:"
  },
  {
    id: "FreeFormat.Width.Required",
    text: "Width is required"
  },
  {
    id: "FreeFormat.Height",
    text: "Height (mm)*:"
  },
  {
    id: "FreeFormat.Height.Required",
    text: "Height is required"
  },
  {
    id: "FreeFormat.Anschnitt/Satzspiegel.Required",
    text: "Selection is required"
  },
  {
    id: "FreeFormat.Format.Header",
    text: "new Free Format"
  },
  {
    id: "FreeFormat.Anschnitt",
    text: "Bleed"
  },
  {
    id: "FreeFormat.Satzspiegel",
    text: "Non-Bleed"
  },
  {
    id: "FreeFormat.Ueberbund",
    text: "Over spread"
  },
  {
    id: "FreeFormat.Platzierung",
    text: "Placement"
  },
  {
    id: "FreeFormat.Platzierung.Select.Required",
    text: "Placement is required"
  },
  {
    id: "FreeFormat.Platzierung.Select.Innenteil",
    text: "Text"
  },
  {
    id: "FreeFormat.Platzierung.Select.Umschlagseite",
    text: "Cover page"
  },
  {
    id: "FreeFormat.Platzierung.Umschlag",
    text: "cover"
  },
  {
    id: "FreeFormat.CreateJob",
    text: "Create job with free format"
  },
  {
    id: "General.FreeFormat",
    text: "Free Format"
  },
  {
    id: "FileUpload.File.Attached",
    text: "File attached"
  },
  {
    id: "FileUpload.Druckunterlage.Download",
    text: "Download file"
  },
  {
    id: "FileUpload.Button.ReCheck",
    text: "Recheck the file"
  },
  {
    id: "FreeFormat.AcceptNew.Success",
    text: "Free format was successfully applied!"
  },
  {
    id: "ReceiverList.NotReceived",
    text: "New"
  },
  {
    id: "ReceiverList.Received",
    text: "Claimed"
  },
  {
    id: "ReceiverList.MarkAsReceived",
    text: "Mark as claimed"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen.Empfehlung.Tooltip",
    text: "Required minimum resolution for color or black and white images"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Graustufen.Grenzwert.Tooltip",
    text: "Warning threshold for color or black and white images"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder.Empfehlung.Tooltip",
    text: "Required minimum resolution for line work and graphic elements"
  },
  {
    id: "TechnicalInfo.Grenzwerte.Strichbilder.Grenzwert.Tooltip",
    text: "Warning threshold for line work and graphic elements"
  },
  {
    id: "Home.Statistics.Anzeigen",
    text: "Ads"
  },
  {
    id: "Home.Statistics.Firmen",
    text: "Companies"
  },
  {
    id: "Home.Statistics.Medien",
    text: "Publications"
  },
  {
    id: "Home.Statistics.Verlage",
    text: "Publishers"
  },
  {
    id: "Home.Logos.Hint",
    text: "A cooperation project by..."
  },
  {
    id: "Home.Vorteile",
    text: "Benefits"
  },
  {
    id: "Home.Preise",
    text: "Price"
  },
  {
    id: "Home.Teilnehmen",
    text: "Participate"
  },
  {
    id: "Headers.Freigabe",
    text: "Approval"
  },
  {
    id: "MediaGrid.Actions",
    text: "Actions"
  },
  {
    id: "Freigabe.Zeitraum.Tooltip",
    text: ""
  },
  {
    id: "Freigabe.Zeitraum.Header",
    text: "Select period"
  },
  {
    id: "Freigabe.Verarbeitung",
    text: "Binding"
  },
  {
    id: "Freigabe.DateiFormat",
    text: "Recommended format"
  },
  {
    id: "Freigabe.Hefte.WidthAndHeight",
    text: "Issue width x height [%{name}]"
  },
  {
    id: "Freigabe.Satzspiegel.WidthAndHeight",
    text: "Fullpage Type  width x height [%{name}]"
  },
  {
    id: "Freigabe.BeschnittZugaben",
    text: "Spine overlap"
  },
  {
    id: "Freigabe.Zertifizierung.Erfolgt",
    text: "Approval taking placee"
  },
  {
    id: "Freigabe.Hefte.Header",
    text: "Issues"
  },
  {
    id: "Freigabe.AnzeigenFormat.Header",
    text: "Ad size"
  },
  {
    id: "Freigabe.PlatzierungruckVerfahren.Header",
    text: "Placement - Printing process - Color-profile"
  },
  {
    id: "Freigabe.Grid.Hefte.Nummer",
    text: "Number"
  },
  {
    id: "Freigabe.Grid.Hefte.EVT",
    text: "Publication date"
  },
  {
    id: "Freigabe.Grid.Hefte.DuSchluss",
    text: "Copy deadline"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.BezeichnungDE",
    text: "Description DE"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Seitenteil",
    text: "Side panel"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Anschnitt",
    text: "Bleed"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Überbund",
    text: "Over spread"
  },
  {
    id: "Freigabe.Grid.AnzeigenFormate.Ausrichtung",
    text: "Alignment"
  },
  {
    id: "Freigabe.Grid.Platzierung",
    text: "Placement in issue"
  },
  {
    id: "Freigabe.Grid.Druckverfahren",
    text: "Printing process"
  },
  {
    id: "Freigabe.Grid.FarbProfil",
    text: "Color-profile"
  },
  {
    id: "Freigabe.ContactForm.Header",
    text: "Change Request"
  },
  {
    id: "Freigabe.ContactForm.Message.Required",
    text: "Message is required"
  },
  {
    id: "Freigabe.ContactForm.Button",
    text: "Send"
  },
  {
    id: "Freigabe.Approved",
    text: "approved"
  },
  {
    id: "Freigabe.NotApproved",
    text: "approve"
  },
  {
    id: "Freigabe.PendingApproval",
    text: "Pending Approval"
  },
  {
    id: "Freigabe.DownloadPDF",
    text: "Download Test Document"
  },
  {
    id: "Freigabe.ChangeRequest",
    text: "Change Request"
  },
  {
    id: "Freigabe.Approve",
    text: "Approve"
  },
  {
    id: "JobFormular.MediumSelector.Hint",
    text: "Enter a publication name or ID"
  },
  {
    id: "TechnicalInfo.Sonstiges.Konventionen.Download",
    text: "Download conventions"
  },
  {
    id: "JobFormular.SendContainer.Warning.Hint",
    text:
      "The preflight detected features that did not meet the recommendations. Please check the reports. You can approve the print documents and still send them. However, they refrain from the complaints regarding the warned properties."
  },
  {
    id: "JobFormular.SendContainer.Warning.Cancel",
    text: "Abort"
  },
  {
    id: "JobFormular.SendContainer.Warning.ApproveAndSend",
    text: "Approve and send"
  },
  {
    id: "JobFormular.Format.Delete",
    text: "Delete AD?"
  },
  {
    id: "Format.Display.Placeholder",
    text: "No preview available"
  },
  {
    id: "MagazineInfo.Seitengröße",
    text: "Page Size"
  },
  {
    id: "MagazineInfo.Seitengröße.Tooltip",
    text: "Width and Height of final trimmed fullpage size in mm."
  },
  {
    id: "MagazineInfo.Satzspiegelgröße",
    text: "Type Area"
  },
  {
    id: "MagazineInfo.Satzspiegelgröße.Tooltip",
    text:
      "Width and Height of the fullpage type area. The type area is the print area in terms of macro-typography."
  },
  {
    id: "TechnicalInfo.Druckverfahren",
    text: "Printing Process"
  },
  {
    id: "JobList.JobHistory",
    text: "History"
  },
  {
    id: "JobDownloadAnonymously.Product",
    text: "Product"
  },
  {
    id: "JobDownloadAnonymously.Motive",
    text: "Motif"
  },
  {
    id: "JobDownloadAnonymously.Issue",
    text: "Issue"
  },
  {
    id: "JobDownloadAnonymously.PublicationDate",
    text: "Publication Date"
  },
  {
    id: "JobDownloadAnonymously.Format",
    text: "Format"
  },
  {
    id: "JobDownloadAnonymously.From",
    text: "From"
  },
  {
    id: "JobDownloadAnonymously.Download",
    text: "Download"
  },
  {
    id: "JobDownloadAnonymously.Error",
    text: "Oops, this link has expired or is invalid."
  },
  {
    id: "Support.Sender.Header",
    text: "Sender Info"
  },
  {
    id: "Support.Sender",
    text: "Show sender"
  },
  {
    id: "Footer.Konventionen",
    text: "Conventions"
  },
  {
    id: "General.AllBooking",
    text: "Whole issue"
  },
  {
    id: "General.Booking",
    text: "Allocation"
  },
  {
    id: "JobFormular.SelectFormat",
    text: "Please select format..."
  },
  {
    id: "General.Okay",
    text: "OK"
  },
  {
    id: "General.ToJobList",
    text: "TO JOBLIST"
  },
  {
    id: "General.StayOnPage",
    text: "NO, STAY HERE"
  },
  {
    id: "JobFormular.DeleteJobSuccess",
    text: "Size has been deleted sucessfully. Please choose a new size."
  },
  {
    id: "JobFormular.SelectMedia.Duplicate",
    text:
      "The medium '%{name}' is already added to page. Please add an additional job section within the medium frame by choosing another issue or size to create another job for this medium or choose another medium."
  },
  {
    id: "JobList.CopyJob",
    text: "Copy Job"
  },
  {
    id: "JobList.CopyContainer",
    text: "Copy Delivery"
  },
  {
    id: "JobList.ExchangeJob",
    text: "Exchange / Replace"
  },
  {
    id: "JobList.CopyJob.Choose",
    text: "Please choose an action"
  },
  {
    id: "JobList.CopyJob.Choose.WithPDF",
    text: "with PDF"
  },
  {
    id: "JobList.CopyJob.Choose.WithoutPDF",
    text: "without PDF"
  },
  {
    id: "JobFormular.CopyJob.Message",
    text:
      "This is a copy of job %{id}. Please check the selected booklets / issues and formats"
  },
  {
    id: "JobFormular.CopyContainer.Message",
    text:
      "This is a copy of delivery %{id}. Please check the selected booklets / issues and formats"
  },
  {
    id: "JobFormular.ExchangeJob.Message",
    text: "This is an exchange motif for DUON-ID %{id}"
  },
  {
    id: "JobFormular.ExchangeJob.Comment",
    text:
      "This is an exchange motif for DUON-ID %{id}. Please use this PDF for the booked advertisement."
  },
  {
    id: "JobList.ExchangeJob.Message.Normal.FirstParagraph",
    text:
      "With „Exchange / Replace“ you can exchange print documents from jobs that have already been sent. "
  },
  {
    id: "JobList.ExchangeJob.Message.Normal.SecondParagraph",
    text:
      "The old job is marked as „deprecated“ and the new job is marked as „updated“. A corresponding note will be attached to the publisher."
  },
  {
    id: "JobList.ExchangeJob.Message.Normal.ThirdParagraph",
    text:
      "If you also use the printing documents in other magazines / issue dates, please remember to exchange this data as well!"
  },
  {
    id: "JobList.ExchangeJob.Message.DUReached",
    text:
      "The official copy deadline for this advertisement has already been exceeded. Before shipping, make sure that it can still be exchanged before production. Please contact the publisher's contact specified in the job."
  },
  {
    id: "General.Continue",
    text: "Continue"
  },
  {
    id: "General.Proof",
    text: "Recheck"
  },
  {
    id: "JobFormular.JobStatus.Saved",
    text: "saved"
  },
  {
    id: "JobFormular.JobStatus.Unsaved",
    text: "unsaved"
  },
  {
    id: "JobFormular.JobExchange.OldJob",
    text: "deprecated"
  },
  {
    id: "JobFormular.JobExchange.NewJob",
    text: "updated"
  },
  {
    id: "JobList.JobExchange.Filter.All",
    text: "Any State"
  },
  {
    id: "JobList.JobExchange.Filter.OldJobs",
    text: "deprecated jobs"
  },
  {
    id: "JobList.JobExchange.Filter.NewJobs",
    text: "updated jobs"
  },
  {
    id: "JobList.PublicationStatus.Filter.All",
    text: "Publication Status"
  },
  {
    id: "JobList.PublicationStatus.Filter.Published",
    text: "published"
  },
  {
    id: "JobList.PublicationStatus.Filter.Pending",
    text: "pending"
  },
  {
    id: "General.New",
    text: "new"
  },
  {
    id: "Media.MediaNotFound",
    text:
      "No corresponding media found participating in the DUON-Portal. Information on technical information can be found for these titles at "
  },
  {
    id: "MediaDetail.TechnicalInfo",
    text: "Technical Info"
  },
  {
    id: "MediaDetail.TechnicalInfo.Tooltip",
    text: "Technical information for selected issue"
  },
  {
    id: "Verlage.PublisherNotFound",
    text:
      "No corresponding publishers found participating in the DUON-Portal. Information on technical information can be found for these titles at "
  },
  {
    id: "JobList.NotificationsMessage",
    text: "Updates in list available!"
  },
  {
    id: "JobList.Ignore",
    text: "Ignore"
  },
  {
    id: "General.Address",
    text: "Address"
  },
  {
    id: "DuonCertified.InfoText.Header",
    text:
      "An established sign of quality within the prepress and publishing business for many years!"
  },
  {
    id: "DuonCertified.InfoText.Body",
    text:
      "The DUON-certified seal distinguishes companies that produce high quality printing material. Every company has been checked in order to comply with the strict certification criteria."
  },
  {
    id: "DuonCertified.InfoText.Footer",
    text: "DUON certified – reliable partners for your creation."
  },
  {
    id: "DuonCertified.InfoText.LearnMore",
    text: "Learn more..."
  },
  {
    id: "TechnicalInfo.NoInfo",
    text: "No Information"
  },
  {
    id: "TechnicalInfo.Bunddoppelung",
    text: "Crossover for perfect bound inner cover spreads"
  },
  {
    id: "TechnicalInfo.BunddoppelungZugabe",
    text: "Spine overlap"
  },
  {
    id: "General.Titelanzahl",
    text: "# of Titles"
  },
  {
    id: "General.Ansicht",
    text: "View"
  },
  {
    id: "Freigabe.Certify.Success",
    text: "Done! The medium is now certified."
  },
  {
    id: "JobList.Notes.Popup.ContainerNote",
    text: "Delivery notes"
  },
  {
    id: "JobList.Notes.Popup.JobNote",
    text: "Job notes"
  },
  {
    id: "JobList.Notes.Popup.ContainerNotes",
    text: "Delivery"
  },
  {
    id: "JobList.Notes.Popup.JobNotes",
    text: "Job"
  },
  {
    id: "JobFormular.AddMediumFormat.Hint1",
    text:
      "Jobs within a delivery folder can only be sent at the once. Each job must have a PDF file and has to have the preflight result 'Warning' or 'OK'."
  },
  {
    id: "JobFormular.AddMediumFormat.Hint2",
    text:
      "Tip: If you want to send jobs at different times, please create single delivery folders."
  },
  {
    id: "JobFormular.AddMediumFormat.OptOut",
    text: "Ok, i got it. Don´t show this message again."
  },
  {
    id: "General.Year",
    text: "year"
  },
  {
    id: "General.Month",
    text: "month"
  },
  {
    id: "General.Monthes",
    text: "months"
  },
  {
    id: "General.AllDates",
    text: "All dates"
  },
  {
    id: "Home.Description.MediaAgentur.Text",
    text:
      "Do you want to streamline the information process or reduce the expenses in the production plan creation? The DUON portal offers the best prerequisites to optimize your customer care processes. Learn more..."
  },
  {
    id: "Home.Description.CreationAndProduction.Text",
    text:
      "The DUON portal is the source for technical specifications in the magazine area. The integrated preflight checks your ads for technical production risks. Our support helps you to achieve the best quality. Learn more ..."
  },
  {
    id: "Home.Description.VerlagePrepress.Text",
    text:
      "Support your customers in their ad workflow and provide an optimal service to achieve the best quality for their ads. With the DUON Portal we offer your customers a free support."
  },
  {
    id: "Home.Description.MediaAgentur.TitleIntro",
    text: "Workflow for"
  },
  {
    id: "Home.Description.MediaAgentur.Title",
    text: "Media Agencies"
  },
  {
    id: "Home.Description.CreationAndProduction.TitleIntro",
    text: "Efficient work in the"
  },
  {
    id: "Home.Description.CreationAndProduction.Title",
    text: "Creation & Production"
  },
  {
    id: "Home.Description.VerlagePrepress.TitleIntro",
    text: "Quality assurance for ads"
  },
  {
    id: "Home.Description.VerlagePrepress.Title",
    text: "Publishers & Prepress"
  },
  {
    id: "JobFormular.SingleJobView.Hint",
    text:
      "Please note that this job is in the context of a dispatch order with several jobs and contextual information results, which are not shown here. Thus, e.g. the order for railroad ads can be derived only from the order in the shipping order. Especially for double page ads with single-page delivery, a respective opposite page is contained in another job."
  },
  {
    id: "Settings.JobCopyEmail.Title",
    text: "Delivery confirmation by e-mail"
  },
  {
    id: "Navigation.Settings",
    text: "Settings"
  },
  {
    id: "Settings.JobCopyEmail.Text",
    text:
      "Optinally, you can receive a delivery confirmation by e-mail after every successful shipment."
  },
  {
    id: "Settings.JobCopyEmail.Action",
    text: "I want to receive delivery confirmation receipt by e-mail"
  },
  {
    id: "Settings.JobCopyEmail.Success",
    text: "Changes have been saved sucessfully"
  },
  {
    id: "Settings.GeneralNewsletter.Title",
    text: "Newsletter (german)"
  },
  {
    id: "Settings.GeneralNewsletter.Text",
    text:
      "Optionally, you can receive a newsletter with news about the DUON portal."
  },
  {
    id: "Settings.GeneralNewsletter.Action",
    text: "Keep me informed by e-mail about news about the DUON portal."
  },
  {
    id: "Settings.GeneralNewsletter.Success",
    text: "Changes have been saved sucessfully"
  },
  {
    id: "Settings.TechnicalNewsletter.Title",
    text: "Specs Changes (german)"
  },
  {
    id: "Settings.TechnicalNewsletter.Action",
    text:
      "Keep me informed by e-mail about changes to technical specifications."
  },
  {
    id: "Settings.TechnicalNewsletter.Success",
    text: "Changes have been saved sucessfully"
  },
  {
    id: "Certification.Apply",
    text: "APPLY FOR CERTIFICATION"
  },
  {
    id: "Certification.IAccept",
    text: "I accept the"
  },
  {
    id: "Certification.TermsOfUse",
    text: "terms of use"
  },
  {
    id: "Certification.BillingAddress",
    text: "Different billing address / message"
  },
  {
    id: "Certification.DisregardMessage",
    text: "Are you sure you want to hide this message for one month?"
  },
  {
    id: "JobList.Filters.Search",
    text: "Search"
  },
  {
    id: "JobList.Filters.WordTag",
    text: "Tag"
  },
  {
    id: "JobList.Filters.PreflightStatusTag",
    text: "PREFLIGHT-RESULTS"
  },
  {
    id: "JobList.Filters.MediumTag",
    text: "Medium"
  },
  {
    id: "JobList.Filters.IssueTag",
    text: "Issue"
  },
  {
    id: "JobList.QuickView.Popup.Header",
    text: "Brief View"
  },
  {
    id: "JobList.Views.Default",
    text: "All Columns"
  },
  {
    id: "JobList.Views.Compact",
    text: "Compact"
  },
  {
    id: "JobList.Views.Custom",
    text: "My View"
  },
  {
    id: "JobList.Views.System",
    text: "System"
  },
  {
    id: "JobList.Views.Private",
    text: "Private"
  },
  {
    id: "FileUpload.SplitFormatWarning",
    text:
      "Attention: The upload is only possible when the motifs for the left and right side have been filled out."
  },
  {
    id: "Home.ReleaseBanner.Button",
    text: "Discover now"
  },
  {
    id: "AGBOverlay.AGB.IAccept.FirstPart",
    text: "I have received version 2.1 of"
  },
  {
    id: "AGBOverlay.AGB.IAccept.SecondPart",
    text: "and I agree to the change."
  },
  {
    id: "AGBOverlay.DataPrivacy.IAccept.FirstPart",
    text:
      "I have received and acknowledged the information in accordance with Art. 13 GDPR"
  },
  {
    id: "AGBOverlay.DataPrivacy.IAccept.SecondPart",
    text: ""
  },
  {
    id: "AGBOverlay.AGB.Term",
    text: "the General Terms and Conditions"
  },
  {
    id: "AGBOverlay.DataPrivacy.Term",
    text: "(Link)."
  },
  {
    id: "AGBOverlay.Header",
    text: "WE NEED YOUR CONSENT"
  },
  {
    id: "TechnicalURL.CreateJob",
    text: "Create Job"
  },
  {
    id: "TechnicalURL.MediumPage",
    text: "To Publishers"
  },
  {
    id: "TechnicalURL.CopyURL",
    text: "Copy"
  },
  {
    id: "TechnicalURL.MediaSearch",
    text: "To Publisher Search"
  },
  {
    id: "TechnicalURL.SplitFormatHint",
    text:
      "The selected advertising format consists of several separate parts. These technical specifications describe only one of these parts."
  },
  {
    id: "TechnicalURL.URLCopiedSuccess",
    text: "Link copied to clipboard"
  }
];
