import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import FontAwesome from "react-fontawesome";
import Logo1 from "../../../assets/images/featuresOverlay/3_4_7/version-3.4.7-DE-1.png";
import Logo2 from "../../../assets/images/featuresOverlay/3_4_7/version-3.4.7-DE-2.png";
import Logo3 from "../../../assets/images/featuresOverlay/3_4_7/version-3.4.7-DE-3.png";
import Logo1EN from "../../../assets/images/featuresOverlay/3_4_7/version-3.4.7-EN-1.png";
import Logo2EN from "../../../assets/images/featuresOverlay/3_4_7/version-3.4.7-EN-2.png";
import Logo3EN from "../../../assets/images/featuresOverlay/3_4_7/version-3.4.7-EN-3.png";
import { Modal } from "../../UI";

class ReleaseOverlay extends Component {
  hideOverlay = () => {
    this.props.onHideOverlay();
  };

  render() {
    const { appLanguage, isVisible } = this.props;

    const arrowStyles = {
      position: "absolute",
      zIndex: 2,
      top: "calc(50% - 15px)",
      width: 30,
      height: 30,
      cursor: "pointer",
      border: "none",
      outline: "none"
    };

    const indicatorStyles = {
      background: "#fff",
      width: 8,
      height: 8,
      display: "inline-block",
      margin: "0 8px"
    };

    return (
      <Modal
        size="lg"
        onHideModal={this.hideOverlay}
        isOpen={isVisible}
        modalHeader={<div></div>}
        modalBodyStyle={{ align: "center" }}
        modalBody={
          <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles, right: 15 }}
                >
                  <FontAwesome name="chevron-right"></FontAwesome>
                </button>
              )
            }
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles, left: 15 }}
                >
                  <FontAwesome name="chevron-left"></FontAwesome>
                </button>
              )
            }
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, background: "#000" }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                );
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >
            <div>
              <img src={appLanguage === "en" ? Logo1EN : Logo1} />
            </div>
            <div>
              <img src={appLanguage === "en" ? Logo2EN : Logo2} />
            </div>
            <div>
              <img src={appLanguage === "en" ? Logo3EN : Logo3} />
            </div>
          </Carousel>
        }
        modalFooter={<></>}
      />
    );
  }
}

export default ReleaseOverlay;
