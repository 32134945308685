import React, { Component } from "react";
import { withRouter } from "../../helpers/utility";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Search from "./HomeSearch/HomeSearch";
import Translate from "../../hoc/Locale/Translate";
import classes from "./Home.module.css";
import * as actions from "../../store/actions";
import Intro from "./Intro/Intro";
import Actions from "./Actions/Actions";
import Statistics from "./Statistics/Statistics";
import Logos from "./Logos/Logos";
import Publishers from "./Publishers/Publishers";
import PublishersFeatures from "./PublishersFeatures/PublishersFeatures";
import Pricing from "./Pricing/Pricing";
import PricingDetails from "./PricingDetails/PricingDetails";
import PublisherParticipation from "./PublisherParticipation/PublisherParticipation";
import BrochureDownload from "./BrochureDownload/BrochureDownload";
import FontAwesome from "react-fontawesome";

// import HomeDescription from "./HomeDescription/HomeDescription";
// import PublishersCarousel from "./PublishersCarousel/PublishersCarousel";

class Home extends Component {
  componentDidMount() {
    this.props.onFetchPortalStatus();
  }

  render() {
    const {
      appLanguage,
      isAuthenticated,
      portalStatus,
      portalMessageSpecial
    } = this.props;
    return (
      <div>
        <div className={classes.Main}>
          <div className={classes.TextSection}>
            {portalMessageSpecial && portalMessageSpecial.id !== 0 && (
              <div className={classes.CampaignContainer}>
                <span className={classes.SpecialCampaign}>
                  {portalMessageSpecial.nachricht}
                </span>
              </div>
            )}
            <Intro />
            <Actions
              isAuthenticated={isAuthenticated}
              appLanguage={appLanguage}
              redirectToJobBuilder={this.redirectToJobBuilder}
              redirectToLogin={this.redirectToLogin}
              redirectToRegistration={this.redirectToRegistration}
            />
                </div>
          <div>
            <div className={classes.Search}>
              <Container>
                <Row>
                  <Col sm="1" md="3"></Col>
                  <Col sm="10" md="6">
                    <Search
                      appLanguage={appLanguage}
                      handleSubmit={this.searchHandler}
                    />
                  </Col>
                  <Col sm="1" md="3"></Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Statistics
                      portalStatus={portalStatus}
                      appLanguage={appLanguage}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">&nbsp;</Col>
                </Row>
                <Row>
                  <Col sm="12">&nbsp;</Col>
                </Row>
                <Row>
                  <Col sm="12">&nbsp;</Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div>
                      <div className={classes.LogosHint}>
                        <Translate id="Home.Logos.Hint" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className={classes.LogosSection}>
            <Logos />
            <div></div>
          </div>
        </div>
        <div
          id="vorteile"
          className={[classes.TextSection, classes.VorteileSection].join(" ")}
        >
          <div>
            <h3 className={classes.Header}>
              <FontAwesome name="plus-circle" /> &nbsp;
              <Translate id="Home.Vorteile" />
            </h3>
          </div>
        </div>
        <div className={[classes.TextSection, classes.VerlagSection].join(" ")}>
          <Publishers />
        </div>
        <div>
          <PublishersFeatures />
        </div>
        <div
          className={[classes.TextSection, classes.PricingSection].join(" ")}
        >
          <Pricing />
        </div>
        <div>
          <div className={classes.TriangleDiv}>
            <div className={[classes.TriangleDown].join(" ")}></div>
          </div>
        </div>
        <div>
          <PricingDetails />
        </div>
        <div
          className={[classes.TextSection, classes.PricingSection].join(" ")}
        >
          <PublisherParticipation />
        </div>
        <div
          className={[classes.TextSection, classes.BrochureSection].join(" ")}
        >
          <BrochureDownload />
        </div>
        {/*         <div>
          <HomeDescription />
        </div>
        <div>
          <PublishersCarousel />
        </div> */}
      </div>
    );
  }

  searchHandler = mediaTitle => {
    const queryString = encodeURIComponent(mediaTitle);

    this.props.navigation({
      pathname: "medien",
      search: mediaTitle ? "?name=" + queryString : null,
      state: {
        Suchbegriff: queryString
      }
    });
  };

  redirectToLogin = () => {
    this.props.navigation("/auth");
  };

  redirectToRegistration = () => {
    this.props.navigation("/register");
  };

  redirectToJobBuilder = () => {
    this.props.navigation("/jobs/neu-job");
  };
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    appLanguage: state.auth.appLanguage,
    portalStatus: state.auth.portalStatus,
    portalMessageSpecial: state.auth.portalMessageSpecial
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPortalStatus: () => dispatch(actions.fetchPortalStatus())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
