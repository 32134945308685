import React from "react";
import PropTypes from "prop-types";
import Spinner from "../../Spinner/Spinner";
import classes from "./CheckboxCell.module.css";

class CheckboxCell extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      idColumnName: props.idColumnName ? props.idColumnName : "id"
    };
  }

  handleChange = e => {
    const { disabled, dataItem, field, onChange } = this.props;

    if (disabled) {
      e.preventDefault();
      return;
    }
    this.setState({ loading: true });
    onChange({
      dataItem: dataItem,
      field: field,
      value: !dataItem[field]
    });
  };

  render() {
    const { dataItem, field, disabled } = this.props;
    const { loading, idColumnName } = this.state;

    const cellId = field + dataItem[idColumnName];

    return loading ? (
      <td className="text-center">
        <Spinner size="lg" surpressPadding={true} isInline={true} />
      </td>
    ) : (
      <td className={[classes.CheckboxCell, "text-center"].join(" ")}>
        <input
          id={cellId}
          type="checkbox"
          onChange={this.handleChange}
          className={classes.Checkbox}
          disabled={disabled === true}
          checked={dataItem[field] === true}
        ></input>
        <label
          className={[classes.CheckboxLabel, "k-checkbox-label"].join(" ")}
          htmlFor={cellId}
        ></label>
      </td>
    );
  }
}

CheckboxCell.propTypes = {
  disabled: PropTypes.bool
};

export default CheckboxCell;
