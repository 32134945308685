import React, { Component, lazy, Suspense } from "react";
import {    
  BrowserRouter,
  Routes,
  Route,
  Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions";
import Layout from "./hoc/Layout/Layout";
import Homepage from "./components/Home/Home";
import classes from "./App.module.css";
import Auth from "./containers/Auth/Auth";
import Spinner from "./components/UI/Spinner/Spinner";
import { withRouter } from "./helpers/utility";


const AsyncJobList = lazy(() =>
  import("./containers/SenderJobListContainer/SenderJobListContainer")
);

const AsyncReceiverJobs = lazy(() =>
  import("./containers/ReceiverJobListContainer/ReceiverJobListContainer")
);

const AsyncJobBuilder = lazy(() =>
  import("./containers/JobBuilderContainer/JobBuilderContainer")
);

const AsyncMedia = lazy(() =>
  import("./containers/MediaContainer/MediaContainer")
);

const AsyncMediaAzure = lazy(() =>
  import("./containers/MediaContainer/MediaAzureContainer")
);

const AsyncMediaDetail = lazy(() =>
  import("./containers/MediaDetailContainer/MediaDetailContainer")
);

const AsyncRegister = lazy(() =>
  import("./containers/RegistrationContainer/RegistrationContainer")
);

const AsyncRegisterWithInvitation = lazy(() =>
  import(
    "./containers/RegistrationContainer/RegistrationWithInvitationContainer/RegistrationWithInvitationContainer"
  )
);

const AsyncRegistrationConfirmation = lazy(() =>
  import(
    "./containers/RegistrationContainer/RegistrationConfirmationContainer/RegistrationConfirmationContainer"
  )
);

const AsyncResetPasswordLinkRequest = lazy(() =>
  import("./containers/User/ForgotPasswordContainer/ForgotPasswordContainer")
);

const AsyncResetPassword = lazy(() =>
  import("./containers/User/ResetPasswordContainer/ResetPasswordContainer")
);

const AsyncUserProfile = lazy(() =>
  import("./containers/User/UserProfileContainer/UserProfileContainer")
);

const AsyncCompanyUsersProfile = lazy(() =>
  import("./containers/User/CompanyUsersContainer/CompanyUsersContainer")
);

const AsyncCompanyChange = lazy(() =>
  import(
    "./containers/User/CompanyChangeInvitationContainer/CompanyChangeInvitationContainer"
  )
);

const AsyncAssignedMedia = lazy(() =>
  import("./containers/User/AssignedMediaContainer/AssignedMediaContainer")
);

const AsyncImpressum = lazy(() => import("./components/Imprint/Impressum"));

const AsyncImprint = lazy(() => import("./components/Imprint/Imprint"));

const AsyncKontakt = lazy(() => import("./components/ContactUs/Kontakt"));

const AsyncContactUs = lazy(() => import("./components/ContactUs/ContactUs"));

const AsyncDataPrivacyDE = lazy(() =>
  import("./components/DataPrivacy/Datenschutz")
);

const AsyncDataPrivacyEN = lazy(() =>
  import("./components/DataPrivacy/DataPrivacy")
);

const AsyncFreigabe = lazy(() =>
  import("./containers/FreigabeContainer/FreigabeContainer")
);

const AsyncFreigabeDetail = lazy(() =>
  import("./containers/FreigabeDetailContainer/FreigabeDetailContainer")
);

const AsyncAnonymousJobDownloadContainer = lazy(() =>
  import(
    "./containers/AnonymousJobDownloadContainer/AnonymousJobDownloadContainer"
  )
);

const AsyncKonventionen = lazy(() =>
  import("./components/Conventions/Konventionen")
);

const AsyncConventions = lazy(() =>
  import("./components/Conventions/Conventions")
);

const AsyncCertified = lazy(() =>
  import("./containers/CertifiedCompaniesContainer/CertifiedCompaniesContainer")
);

const AsyncPublishers = lazy(() =>
  import("./containers/PublisherContainer/PublisherContainer")
);

const AsyncPublisherDetail = lazy(() =>
  import("./containers/PublisherDetailContainer/PublisherDetailContainer")
);

const AsyncJobsAzure = lazy(() =>
  import("./components/JobList/AzureList/AzureList")
);

const AsyncSettings = lazy(() =>
  import("./containers/SettingsContainer/SettingsContainer")
);

const AsyncTechnicalURL = lazy(() =>
  import("./containers/TechnicalURLContainer/TechnicalURLContainer")
);

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Suspense fallback={<Spinner />}>

        <Routes>
          <Route path="/" element={<Homepage />} exact />
          <Route path="/medien/azure" element={<AsyncMediaAzure />} exact />
          <Route path="/medien/:id/" element={<AsyncMediaDetail />} exact />
          <Route path="/medien/:id/Erscheinungstermine/:ercheinungstermin" element={<AsyncMediaDetail />} />
          <Route path="/jobs/neu-job" element={<AsyncJobBuilder key="1" />} exact />
          <Route path="/jobs/:id/neu-job" element={<AsyncJobBuilder key="2" />} exact />
          <Route path="/jobs/:id/:heftid/neu-job" element={<AsyncJobBuilder key="3" />} />
          <Route path="/jobs/:id/:heftid/:md5hash/neu-job" element={<AsyncJobBuilder key="4" />} />
          <Route path="/jobs/:containerId/:jobId" element={<AsyncJobBuilder key="5" />} />
          <Route path="/downloads/jobs/:code" element={<AsyncAnonymousJobDownloadContainer />} />
          <Route path="/medien" element={<AsyncMedia />} exact />
          <Route path="/empfang" element={<AsyncReceiverJobs />} />
          <Route path="/jobs/azure" element={<AsyncJobsAzure />} exact />
          <Route path="/jobs" element={<AsyncJobList />} exact />
          <Route path="/auth" element={<Auth />} />
          <Route path="/register" element={<AsyncRegister />} exact />
          <Route path="/register/firmeneinladung/:companyTicket" element={<AsyncRegisterWithInvitation />} exact />
          <Route path="/register/organisationEinladung/:companyTicket" element={<AsyncRegisterWithInvitation />} exact />
          <Route path="/register/:id/confirmEmail/:code" element={<AsyncRegistrationConfirmation />} />
          <Route path="/benutzer/passwort-vergessen" element={<AsyncResetPasswordLinkRequest />} />
          <Route path="/benutzer/neues-passwort" element={<AsyncResetPassword />} exact />
          <Route path="/benutzer/neues-passwort/:code" element={<AsyncResetPassword />} />
          <Route path="/benutzer/firmenwechsel/:companyTicket" element={<AsyncCompanyChange />} exact />
          <Route path="/benutzer/organisationWechsel/:companyTicket" element={<AsyncCompanyChange />} exact />
          <Route path="/benutzer/mein-profil" element={<AsyncUserProfile />} />
          <Route path="/benutzer/meine-firma" element={<AsyncCompanyUsersProfile />} />
          <Route path="/benutzer/zugewiesene-medien" element={<AsyncAssignedMedia />} />
          <Route path="/benutzer/settings" element={<AsyncSettings />} exact />
          <Route path="/impressum" element={<AsyncImpressum />} />
          <Route path="/imprint" element={<AsyncImprint />} />
          <Route path="/datenschutz" element={<AsyncDataPrivacyDE />} />
          <Route path="/dataprivacy" element={<AsyncDataPrivacyEN />} />
          <Route path="/kontakt" element={<AsyncKontakt />} />
          <Route path="/contactUs" element={<AsyncContactUs />} />
          <Route path="/freigabe/:id/" element={<AsyncFreigabeDetail />} exact />
          <Route path="/freigabe" element={<AsyncFreigabe />} />
          <Route path="/Konventionen" element={<AsyncKonventionen />} />
          <Route path="/Conventions" element={<AsyncConventions />} />
          <Route path="/certified" element={<AsyncCertified />} />
          <Route path="/verlage/:id/" element={<AsyncPublisherDetail />} />
          <Route path="/publisher/:id/" element={<AsyncPublisherDetail />} />
          <Route path="/verlage" element={<AsyncPublishers />} />
          <Route path="/publishers" element={<AsyncPublishers />} />
          <Route path="/technische-vorgaben/:mediumid/:releasedate/:md5hash/:partialbooking/:language/:parentMD5Hash" element={<AsyncTechnicalURL />} />
          <Route path="/technische-vorgaben/:mediumid/:releasedate/:md5hash/:partialbooking/:language" element={<AsyncTechnicalURL />} />
        </Routes>
        
      </Suspense>
    );

    return (
      <div className={classes.App}>
        <Layout>{routes}</Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isReceiver: state.auth.isReceiver
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
