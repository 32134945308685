import React from "react";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./PricingDetails.module.css";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

const PricingDetails = () => {
  return (
    <>
      <div>
        <Container>
          <Row>
            <Col
              sm="12"
              md={{ size: 6, offset: 3 }}
              className={classes.PricingColumn}
            >
              <h4 className={classes.Header}>
                <br />
                <FontAwesomeIcon
                  icon={faCoins}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Pricing.Column1.Header" />
              </h4>
              <div className={classes.PricingText}>
                <Translate id="Home.Pricing.Column1.Text" />
              </div>
              <div className={classes.PricingSubText}>
                <Translate id="Home.Pricing.Column1.SubText" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PricingDetails;
